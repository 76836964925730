import cn from 'classnames';
import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { OverlayState } from '../../../overlays/state';
import { OverlayType } from '../../../overlays/types';

import { Icon } from '~/client/core/ui/components/icon';
import { Interactive } from '~/client/core/ui/components/interactive';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';
import { useUserData } from '~/client/relay/ui/hooks/use-user-data';

import styles from './styles.module.scss';

type Props = {
  label: string;
  description: string;
  icon: SVGIcon;
  authorizedOnly?: boolean;
  accent?: boolean;
  onClick: VoidFunction;
};

export const Kind: React.FC<Props> = ({
  label,
  description,
  icon,
  authorizedOnly,
  accent,
  onClick,
}) => {
  const authorized = useUserData('authorized');

  const [, setOverlay] = useRecoilState(OverlayState);

  const handleClick = useCallback(() => {
    if (!authorizedOnly || authorized) {
      onClick();
    } else {
      setOverlay(OverlayType.Auth);
    }
  }, [authorized]);

  return (
    <Interactive
      onClick={handleClick}
      withAudio
      className={cn(styles.container, {
        [styles.accent]: accent,
      })}
    >
      <Icon type={icon} />
      <Section direction='vertical' gap={4}>
        <Text size='2xl' bold uppercase>
          {label}
        </Text>
        <Text view='secondary' size='s' uppercase>
          {description}
        </Text>
      </Section>
    </Interactive>
  );
};
