import { InputKeyboard } from './keyboard';
import { InputMouse } from './mouse';
import { InputTouch } from './touch';
import { SCENE_CANVAS_ID } from '../scene/const';

export class Input {
  public static addListeners() {
    InputKeyboard.addListeners();
    InputMouse.addListeners();
    InputTouch.addListeners();
  }

  public static isWorldTarget(event: Event) {
    const element = event.target as HTMLElement;
    return element.id === SCENE_CANVAS_ID;
  }

  public static isNativeTarget(event: TouchEvent | MouseEvent) {
    return (event.target as HTMLElement).nodeName === 'INPUT';
  }
}
