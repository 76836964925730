import { Builder } from './builder';
import { BATTLE_PING_LISTEN_RATE } from './const';
import { BuildingFactory } from './entity/building/factory';
import { DroidFactory } from './entity/unit/npc/droid/factory';
import { MobFactory } from './entity/unit/npc/mob/factory';
import { Player } from './entity/unit/player';
import { Minimap } from './minimap';
import { BattleScene } from './scene';
import { Terrain } from './terrain';
import { BattleScreen } from './ui';
import { Wave } from './wave';

import type { Entity } from './entity';
import type { EntityUI } from './entity/types';
import type { BattleEvents } from './types';
import type { Room as OriginRoom } from 'colyseus.js';
import type { PlayerSchema } from '~/shared/battle/entity/unit/player/types';
import type { BattleMessagePayload, BattleSchema } from '~/shared/battle/types';

import { AudioType } from '~/client/core/audio/types';
import { Client } from '~/client/core/client';
import { Device } from '~/client/core/device';
import { Messages } from '~/client/core/messages';
import { MessagesBuffer } from '~/client/core/messages/buffer';
import { Room } from '~/client/core/room';
import { SDK } from '~/client/core/sdk';
import { Interface } from '~/client/core/ui';
import { BattleMessage, BattleMode, BattleStage } from '~/shared/battle/types';
import { EventStream } from '~/shared/core/event-stream';
import { Timeouts } from '~/shared/core/time/timeouts';

import './resources';

export class Battle extends Room<BattleSchema> {
  public static allowReconnection: boolean = true;

  declare public readonly scene: BattleScene;

  public readonly minimap: Minimap;

  public readonly builder: Builder;

  public readonly terrain: Terrain;

  public readonly messagesBuffer: MessagesBuffer;

  public readonly messages: Messages<BattleMessagePayload>;

  public readonly timeouts: Timeouts = new Timeouts();

  public wave: Nullable<Wave> = null;

  public ping: number = 0;
  private pingListener: Timer;

  public readonly entitiesUI: Map<Entity, EntityUI> = new Map();
  public readonly entities: Map<string, Entity> = new Map();

  public readonly events: BattleEvents = {
    onPreparing: new EventStream(),
    onStart: new EventStream(),
    onFinish: new EventStream(),
    onEntityChangeUI: new EventStream(),
  };

  constructor(room: OriginRoom) {
    super(room);

    this.messagesBuffer = new MessagesBuffer();
    this.messages = new Messages(this.origin, this.messagesBuffer);

    this.scene = new BattleScene(this);
    this.builder = new Builder(this);
    this.terrain = new Terrain(this);
    this.minimap = new Minimap(this);

    this.bindSchemaWithEntities();

    this.listenSchemaPing();
    this.listenSchemaStage();
    this.listenSchemaWave();

    Interface.mount(BattleScreen, this);

    this.origin.onLeave(() => {
      SDK.togglePlaying(false);
    });

    Client.unmarkNewbie();

    this.onScreenRelease = this.onScreenRelease.bind(this);
    Device.events.onScreenRelease.on(this.onScreenRelease);

    this.onSceneUpdate = this.onSceneUpdate.bind(this);
    this.scene.events.onUpdate.on(this.onSceneUpdate);
  }

  override destroy(): void {
    clearInterval(this.pingListener);

    this.removeEntities();

    this.timeouts.clear();
    this.messagesBuffer.clear();

    this.scene.destroy();
    this.terrain.destroy();
    this.builder.destroy();

    Device.events.onScreenRelease.off(this.onScreenRelease);
    this.scene.events.onUpdate.off(this.onSceneUpdate);

    super.destroy();
  }

  private bindSchemaWithEntities() {
    this.state.players.onAdd((player) => {
      if (!player.bot) {
        new Player(this, player);
      }
    });

    this.state.droids.onAdd((droid) => {
      DroidFactory.create(this, droid);
    });

    this.state.mobs.onAdd((mob) => {
      MobFactory.create(this, mob);
    });

    this.state.buildings.onAdd((building) => {
      BuildingFactory.create(this, building);
    });

    const offChange = this.state.onChange(() => {
      offChange();
      if (this.state.paused) {
        setTimeout(() => {
          this.scene.camera.update();
          this.scene.render();
        });
      }
    });
  }

  private removeEntities() {
    this.entities.forEach((entity) => {
      entity.destroy();
    });
    this.entities.clear();
  }

  public getEntity<T extends Entity>(id: string): Nullable<T> {
    return (this.entities.get(id) ?? null) as Nullable<T>;
  }

  public getSelfPlayer(): Player {
    const schema = this.getSelfPlayerSchema();
    const player = this.getEntity<Player>(schema.id);
    if (!player) {
      throw Error('Unknown self player');
    }

    return player;
  }

  public getSelfPlayerSchema(): PlayerSchema {
    const player = this.state.players.get(this.sessionId);
    if (!player) {
      throw Error('Unknown self player schema');
    }

    return player;
  }

  public getOpponentPlayerSchema(): PlayerSchema {
    const player = Array.from(this.state.players.values()).find((player) => (
      player.id !== this.sessionId
    ));
    if (!player) {
      throw Error('Unknown opponent player');
    }

    return player;
  }

  private listenSchemaPing() {
    this.pingListener = setInterval(() => {
      this.messages.send(BattleMessage.Ping, {
        stamp: performance.now(),
      });
    }, BATTLE_PING_LISTEN_RATE);

    this.messages.on(BattleMessage.Ping, ({ stamp }) => {
      const now = performance.now();
      this.ping = now - stamp;
    });
  }

  private listenSchemaStage() {
    this.state.listen('stage', (stage) => {
      switch (stage) {
        case BattleStage.Preparing: {
          this.preparing();
          break;
        }
        case BattleStage.Started: {
          this.start();
          Client.hideLoading();
          break;
        }
        case BattleStage.Finished: {
          this.finish();
          Client.hideLoading();
          break;
        }
      }
    });
  }

  private listenSchemaWave() {
    this.state.listen('wave', (wave) => {
      if (wave) {
        this.wave = new Wave(this);
      }
    });
  }

  public restart(loadSave: boolean) {
    this.messages.send(BattleMessage.Restart, {
      loadSave,
    });
  }

  private preparing() {
    this.events.onPreparing.invoke();
  }

  private start() {
    SDK.togglePlaying(true);

    this.scene.toggleControls(true);

    this.events.onStart.invoke();
  }

  public togglePause(paused: boolean) {
    if (
      this.state.mode === BattleMode.Online ||
      SDK.isPlaying() !== paused
    ) {
      return;
    }

    SDK.togglePlaying(!paused);

    this.messages.send(BattleMessage.TogglePause, { paused });
  }

  private finish() {
    SDK.togglePlaying(false);

    this.scene.toggleControls(false);

    const audio = this.state.winnerId === this.sessionId
      ? AudioType.Win
      : AudioType.GameOver;
    this.scene.audio.play2D(audio);

    this.events.onFinish.invoke();
  }

  public setEntityUI(entity: Entity, ui: Nullable<EntityUI>) {
    if (ui) {
      this.entitiesUI.set(entity, ui);
    } else {
      this.entitiesUI.delete(entity);
    }
    this.events.onEntityChangeUI.invoke();
  }

  private onSceneUpdate() {
    this.timeouts.update();
  }

  private onScreenRelease() {
    // To apply actual schema
    setTimeout(() => {
      this.entities.forEach((entity) => {
        entity.synchronize();
      });
    });
  }
}
