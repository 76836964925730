import React from 'react';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Score } from '~/client/core/ui/components/amount/score';
import { USER_ONLINE_PLAY_SCORE_RESTRICTION } from '~/shared/core/user/const';

import styles from './styles.module.scss';

import IconLock from './icons/lock.svg';

export const PlayRestriction: React.FC = () => {
  const language = useLanguage();

  return (
    <div className={styles.container}>
      <IconLock />
      <div className={styles.text}>
        <div className={styles.large}>
          <span>{language('Earn')}</span>
          <Score value={USER_ONLINE_PLAY_SCORE_RESTRICTION} size='m' />
        </div>
        <div className={styles.small}>
          {language('UnlockOnlineModes')}
        </div>
      </div>
    </div>
  );
};
