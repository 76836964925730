import { Bullet } from '..';

import type { Battle } from '~/client/battle';
import type { ShotSchema } from '~/shared/battle/entity/shot/types';

import { MaterialType } from '~/client/core/assets/materials/types';
import { AudioType } from '~/client/core/audio/types';

import './resources';

export class BulletFire extends Bullet {
  constructor(battle: Battle, schema: ShotSchema) {
    super(battle, {
      material: MaterialType.BulletFire,
      audioEffect: AudioType.ShotFire,
    }, schema);
  }
}

