import cn from 'classnames';
import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';

import { useBuilding } from '../../../hooks/use-building';

import styles from './styles.module.scss';

export const LevelBar: React.FC = () => {
  const building = useBuilding();

  const level = useSchemaValue(building.schema, 'level');

  const stages = Array(building.schema.maxLevel).fill(0);

  return (
    <div className={styles.container}>
      {stages.map((_, i) => (
        <div
          key={i}
          className={cn(styles.stage, {
            [styles.active]: level > i,
          })}
        />
      ))}
    </div>
  );
};
