import { SceneLayer } from '../../scene/types';

import type { Entity } from '../../entity';

import { MaterialType } from '~/client/core/assets/materials/types';
import { Plane } from '~/client/core/render-item/shape/plane';

import './resources';

export class Marker extends Plane {
  private entity: Entity;

  constructor(entity: Entity, scale: number = 1.0) {
    super(entity.battle.scene, {
      position: { x: 0, y: 0.05, z: 0 },
      material: entity.selfOwn
        ? MaterialType.MarkerSelf
        : MaterialType.MarkerOpponent,
    });

    this.object.layers.set(SceneLayer.Marker);

    this.setScale({ x: scale, y: scale, z: 1.0 });

    this.entity = entity;
    this.entity.renderItem.object.add(this.object);
  }
}
