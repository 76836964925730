import { INVENTORY_ITEMS } from '../const';
import { InventoryItemType } from '../item/types';

import type { InventoryItemVariant } from '../item/types';
import type { InventorySchema } from '../types';
import type { DroidVariant } from '~/shared/battle/entity/unit/npc/droid/types';
import type { SkillVariant } from '~/shared/battle/entity/unit/player/skill/types';

import { DROIDS_SHARED_CONFIG } from '~/shared/battle/entity/unit/npc/droid/const';
import { SKILLS_SHARED_CONFIG } from '~/shared/battle/entity/unit/player/skill/const';

export class InventoryUtils {
  public static getItems<T extends InventoryItemType>(
    inventory: InventorySchema,
    targetType: T,
  ) {
    const result = new Map<number, InventoryItemVariant[T]>();
    inventory.slots.forEach((item, key) => {
      const [type, slot] = key.split(':');
      if (type === targetType) {
        result.set(Number(slot), item as InventoryItemVariant[T]);
      }
    });

    return result;
  }

  public static getItem<T extends InventoryItemType>(
    inventory: InventorySchema,
    targetType: T,
    slot: number = 0,
  ) {
    return this.getItems(inventory, targetType).get(slot) ?? null;
  }

  public static getItemSlot(inventory: InventorySchema, targetItem: string) {
    const data = Array.from(inventory.slots.entries()).find(([, item]) => item === targetItem);
    if (!data) {
      return null;
    }

    const [, slot] = data[0].split(':');
    return Number(slot);
  }

  public static getItemType(name: string) {
    const type = INVENTORY_ITEMS[name];
    if (!type) {
      throw Error('Unknown item name');
    }

    return type;
  }

  public static getItemCost(item: string) {
    switch (this.getItemType(item)) {
      case InventoryItemType.Droid: {
        const droid = item as DroidVariant;
        return DROIDS_SHARED_CONFIG[droid].cost;
      }
      case InventoryItemType.Skill: {
        const skill = item as SkillVariant;
        return SKILLS_SHARED_CONFIG[skill].cost;
      }
      default: {
        return 0;
      }
    }
  }
}
