import { Assets } from '~/client/core/assets';
import { ModelType } from '~/client/core/assets/types';

import bin from './eye.bin';
import gltf from './eye.gltf';

Assets.addModel(ModelType.MobEye, {
  gltf,
  bin: {
    origin: 'eye.bin',
    path: bin,
  },
});
