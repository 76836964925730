import { Vector3, Box3, Quaternion, Euler, Mesh, AnimationMixer, Vector2 } from 'three';

import { RENDER_CAMERA_Y_ANGLE_MULTIPLIER, RENDER_ITEM_OBJECT_PREFIX } from './const';

import type { RenderItemConfig, RenderItemMesh } from './types';
import type { Object3D, Vector3Like, Material } from 'three';
import type { MaterialType } from '~/client/core/assets/materials/types';
import type { Scene } from '~/client/core/scene';

import { Assets } from '~/client/core/assets';
import { Device } from '~/client/core/device';
import { VectorUtils } from '~/shared/core/vector-utils';

export abstract class RenderItem {
  public readonly scene: Scene;

  public object: Object3D;

  public readonly size: Vector3 = new Vector3();

  public get id() { return this.object.uuid; }

  public get position() { return this.object.position; }

  public get visible() { return this.object.visible; }

  constructor(scene: Scene, {
    position,
    rotation,
    scale,
    object,
    visible = true,
  }: RenderItemConfig) {
    this.scene = scene;

    this.object = object;
    this.object.name = `${RENDER_ITEM_OBJECT_PREFIX}${this.constructor.name}`;
    this.object.visible = visible;

    if (position) {
      this.setPosition(position);
    }
    if (rotation) {
      this.setRotation(rotation);
    }
    if (scale) {
      this.setScale(scale);
    }

    this.updateSize();

    this.scene.add(this.object);

    this.onSceneUpdate = this.onSceneUpdate.bind(this);
    this.scene.events.onUpdate.on(this.onSceneUpdate);
  }

  public destroy(): void {
    this.object.removeFromParent();

    this.scene.events.onUpdate.off(this.onSceneUpdate);
  }

  private onSceneUpdate(): void {
    this.update();
  }

  protected update(): void {
  }

  public setVisible(visible: boolean) {
    this.object.visible = visible;
  }

  public setPosition(vector: Vector3Like) {
    this.object.position.copy(vector);
  }

  public setRotation(vector: Vector3Like) {
    this.object.rotation.setFromVector3(
      VectorUtils.reuse(vector),
    );
  }

  public rotate(angle: number, lerp: number = 1.0) {
    this.object.quaternion.slerp(
      new Quaternion().setFromEuler(new Euler(0, angle, 0)),
      lerp,
    );
  }

  public setScale(vector: Vector3Like) {
    this.object.scale.copy(vector);
    this.updateSize();
  }

  private updateSize() {
    const boundingBox = new Box3().setFromObject(this.object);
    boundingBox.getSize(this.size);
  }

  public getPositionOnScreen(): Vector3 {
    const screenSize = Device.getScreenSize();
    const halfSize = new Vector2().copy(screenSize).divideScalar(2);
    const position = this.object.position.clone();

    position.project(this.scene.camera);
    position.x = (position.x * halfSize.x) + halfSize.x;
    position.y = -(position.y * halfSize.y) + halfSize.y;

    const shift = this.scene.camera.getShift(this.object.position);
    position.x += shift.x;
    position.y += shift.z;

    return position;
  }

  public getSizeOnScreen() {
    const distance = this.scene.camera.position.distanceTo(this.object.position);
    const fov = (this.scene.camera.fov * Math.PI / 180) * RENDER_CAMERA_Y_ANGLE_MULTIPLIER;
    const screenHeight = 2 * Math.tan(fov / 2) * distance;
    const screenWidth = screenHeight * this.scene.camera.aspect;
    const screenSize = Device.getScreenSize();

    return {
      x: (this.size.x / screenWidth) * screenSize.x,
      y: (this.size.y / screenHeight) * screenSize.y,
    };
  }

  public getMaterial(): Material {
    const meshes: RenderItemMesh[] = [];
    this.eachMeshes((mesh) => {
      meshes.push(mesh);
    });

    return meshes[0].material;
  }

  public setMaterial(type: MaterialType, withChildren: boolean = false) {
    const material = Assets.getMaterial(type);
    this.eachMeshes((mesh) => {
      if (
        withChildren ||
        mesh.name.indexOf(RENDER_ITEM_OBJECT_PREFIX) !== 0
      ) {
        mesh.material = material;
      }
    });
  }

  public changeMaterial(meshName: string, type: MaterialType) {
    const material = Assets.getMaterial(type);
    this.eachMeshes((mesh) => {
      if (mesh.name === meshName) {
        mesh.material = material;
      }
    });
  }

  public eachMeshes(callback: (mesh: RenderItemMesh) => void) {
    this.object.traverse((mesh) => {
      if (mesh instanceof Mesh) {
        callback(mesh);
      }
    });
  }

  public disableAutoUpdate() {
    this.object.matrixAutoUpdate = false;
  }

  public clone() {
    return this.object.clone();
  }

  public createAnimationMixer() {
    return new AnimationMixer(this.object);
  }
}
