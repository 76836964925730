import type { AttackAreaParams } from './attack-area/types';
import type { EntityMessagePayload, EntitySchema } from '../types';

export interface UnitSchema extends EntitySchema {}

export enum UnitMessage {
  DisplayAttackArea = 'Unit:DisplayAttackArea',
}

export type UnitMessagePayload = EntityMessagePayload & {
  [UnitMessage.DisplayAttackArea]: AttackAreaParams;
};
