import type { Tween } from '@tweenjs/tween.js';

export type AttackAreaTween = Tween<{ radius: number }>;

export type AttackAreaParams = {
  type: AttackAreaType;
  duration: number;
  radius: number;
};

export enum AttackAreaType {
  Default = 'Default',
  Frost = 'Frost',
}
