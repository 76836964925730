import cn from 'classnames';
import React from 'react';

import { SwipeDirection } from './types';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';

import styles from './styles.module.scss';

import IconSwipeLeft from './icons/swipe-left.svg';
import IconSwipeUp from './icons/swipe-up.svg';

type Props = {
  children: React.ReactNode;
  direction: SwipeDirection;
};

export const Swipe: React.FC<Props> = ({ direction, children }) => {
  const language = useLanguage();

  return (
    <div className={cn(styles.wrapper, styles[direction.toLowerCase()])}>
      {direction === SwipeDirection.Left ? (
        <IconSwipeLeft className={styles.icon} />
      ) : (
        <IconSwipeUp className={styles.icon} />
      )}
      <div className={styles.text}>
        <p className={styles.large}>{language(`Swipe${direction}`)}</p>
        <p>{language('To')} {children}</p>
      </div>
    </div>
  );
};
