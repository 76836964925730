import { Audio as OriginAudio } from 'three';

import { AudioTrack } from '..';
import { AUDIO_VOLUME } from '../../const';

import type { AudioTrack2DConfig } from './types';
import type { AudioType } from '../../types';
import type { AudioListener } from 'three';

export class AudioTrack2D extends AudioTrack {
  private readonly audio: OriginAudio;

  private readonly volume: number;

  private paused: boolean = false;

  constructor(type: AudioType, listener: AudioListener, {
    buffer,
    volume = 1.0,
    loop = false,
  }: AudioTrack2DConfig) {
    super(type, listener);

    this.audio = new OriginAudio(listener);

    this.audio.setBuffer(buffer);
    this.audio.loop = loop ?? false;

    this.volume = AUDIO_VOLUME * volume;
    this.audio.setVolume(this.volume);
  }

  public destroy() {
    this.audio.stop();
    this.audio.removeFromParent();
  }

  public play() {
    if (this.audio.isPlaying) {
      this.audio.stop();
    }

    this.audio.play();
  }

  public stop() {
    if (
      !this.audio.isPlaying
      && !this.paused
    ) {
      return;
    }

    this.audio.stop();
    this.paused = false;
  }

  public disable() {
    this.audio.setVolume(0);
  }

  public enable() {
    this.audio.setVolume(this.volume);
  }
}
