import cn from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

type Props = {
  direction: 'vertical' | 'horizontal';
  align?: 'left' | 'center' | 'right';
  gap?: number;
  children: React.ReactNode;
  stretch?: boolean
  className?: string,
};

export const Section: React.FC<Props> = ({
  children,
  direction,
  align,
  className,
  stretch,
  gap = 0,
}) => (
  <div
    className={cn(
      styles.wrapper,
      styles[direction],
      align && styles[align],
      stretch && styles.stretch,
      className,
    )}
    style={{ gap }}
  >
    {children}
  </div>
);
