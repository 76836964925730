import { MathUtils } from 'three';

export class ArrayUtils {
  public static getRandom<T>(array: T[]): Nullable<T> {
    if (array.length === 0) {
      return null;
    }

    const index = MathUtils.randInt(0, array.length - 1);

    return array[index];
  }

  public static takeRandom<T>(array: T[]): Nullable<T> {
    if (array.length === 0) {
      return null;
    }

    const index = MathUtils.randInt(0, array.length - 1);
    const item = array[index];

    array.splice(index, 1);

    return item;
  }

  public static getByWeight<T>(array: T[], callback: (item: T) => number) {
    const target: {
      item: Nullable<T>,
      weight: number
    } = {
      item: null,
      weight: 0,
    };

    array.forEach((item) => {
      const weight = callback(item);
      if (weight > target.weight) {
        target.weight = weight;
        target.item = item;
      }
    });

    return target.item;
  }

  public static group<T, K>(array: T[], callback: (item: T) => Nullable<K>): Map<K, T[]> {
    const groups = new Map<K, T[]>();

    array.forEach((item) => {
      const key = callback(item);
      if (!key) {
        return;
      }

      let group = groups.get(key);

      if (!group) {
        group = [];
        groups.set(key, group);
      }

      group.push(item);
    });

    return groups;
  }

  public static shuffle<T>(array: T[]): void {
    for (let i = array.length - 1; i >= 0; i--) {
      const j = MathUtils.randInt(0, i);
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
}
