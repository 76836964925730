import type { InputKeyboardEvents } from './types';

import { EventStream } from '~/shared/core/event-stream';

export class InputKeyboard {
  public static readonly events: InputKeyboardEvents = {
    onKeyUp: new EventStream(),
    onKeyDown: new EventStream(),
  };

  public static addListeners() {
    this.listenContextMenu();
    this.listenKeyUp();
    this.listenKeyDown();
  }

  private static listenContextMenu() {
    document.addEventListener('contextmenu', (event) => {
      event.preventDefault();
    });
  }

  private static listenKeyUp() {
    document.addEventListener('keyup', (event) => {
      this.events.onKeyUp.invoke(event);
    });
  }

  private static listenKeyDown() {
    document.addEventListener('keydown', (event) => {
      if (event.repeat) {
        return;
      }

      this.events.onKeyDown.invoke(event);
    });
  }
}
