import { useContext } from 'react';

import { PlayerContext } from '../context';

export function usePlayer() {
  const player = useContext(PlayerContext);

  if (!player) {
    throw Error('Undefined player context');
  }

  return player;
}
