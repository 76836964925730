import { Building } from '../..';

import type { Battle } from '~/client/battle';
import type { BuildingSchema } from '~/shared/battle/entity/building/types';

import { ModelType } from '~/client/core/assets/types';

import './resources';

export class BuildingRadar extends Building {
  constructor(battle: Battle, schema: BuildingSchema) {
    super(battle, {
      model: ModelType.BuildingRadar,
    }, schema);
  }

  override onReady(): void {
    super.onReady();

    this.renderItem.animator.play('rotate', { timeScale: 0.1 });
  }
}
