import React from 'react';

import styles from './styles.module.scss';

type Props = {
  value: number
  maxValue: number
  color?: string
};

export const ProgressBar: React.FC<Props> = ({ value, maxValue, color }) => {
  const progress = (value / maxValue) * 100;

  return (
    <div className={styles.container}>
      <div className={styles.progress} style={{
        width: `${progress}%`,
        backgroundColor: color,
      }} />
    </div>
  );
};
