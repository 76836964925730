import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';

import { useBuilding } from '../../hooks/use-building';

import { ProgressBar } from '~/client/battle/entity/ui/components/progress-bar';

export const BuildProgress: React.FC = () => {
  const building = useBuilding();

  const buildProgress = useSchemaValue(building.schema, 'buildProgress');

  return buildProgress < 100 && (
    <ProgressBar
      value={buildProgress}
      maxValue={100}
    />
  );
};
