import React, { useCallback, useEffect, useRef } from 'react';

import type { CSSProperties } from 'react';
import type { InputTouchChannel } from '~/client/core/input/touch/channel';

import { AudioType } from '~/client/core/audio/types';
import { useAudio } from '~/client/core/audio/ui/hooks/use-audio';
import { Device } from '~/client/core/device';
import { InputMouse } from '~/client/core/input/mouse';
import { InputTouch } from '~/client/core/input/touch';
import { DeviceType } from '~/shared/core/device/types';

type Props = {
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void | boolean | Promise<void> | Promise<boolean>;
  disabled?: boolean;
  style?: CSSProperties;
  link?: string;
  withAudio?: boolean;
};

export const Interactive: React.FC<Props> = ({
  link,
  children,
  className,
  disabled,
  withAudio,
  style,
  onClick,
}) => {
  const audio = useAudio();

  const ref = useRef<HTMLDivElement>(null);

  const handleAction = useCallback(async () => {
    if (link) {
      const element = document.createElement('a');
      element.target = '_blank';
      element.href = link;
      element.click();
      element.remove();
    } else if (onClick) {
      const res = await onClick();
      if (res !== false && withAudio) {
        audio.play(AudioType.Click);
      }
    }
  }, [link, onClick]);

  const handleTouch = useCallback((touch: InputTouchChannel) => {
    if (
      ref.current &&
      touch.targets.includes(ref.current)
    ) {
      touch.events.onRelease.on(() => {
        if (!touch.shifted) {
          handleAction();
        }
      });
    }
  }, [handleAction]);

  const handleMouseClick = useCallback((event: MouseEvent) => {
    if (
      ref.current &&
      event.composedPath().includes(ref.current)
    ) {
      handleAction();
    }
  }, [handleAction]);

  useEffect(() => {
    if (disabled) {
      return;
    }

    if (Device.type === DeviceType.Mobile) {
      const eventTouch = InputTouch.events.onTouch.on(handleTouch);
      return () => {
        eventTouch.off();
      };
    } else {
      const eventMouseClick = InputMouse.events.onMouseClick.on(handleMouseClick);
      return () => {
        eventMouseClick.off();
      };
    }
  }, [disabled, handleTouch, handleMouseClick]);

  return (
    <div ref={ref} className={className} style={style}>
      {children}
    </div>
  );
};
