import React from 'react';

import { AlertLabel } from './components/alert-label';
import { BuildProgress } from './components/build-progress';
import { Indicators } from './components/indicators';
import { BuildingPanel } from './components/panel';
import { UpgradeHint } from './components/upgrade-hint';
import { BuildingContext } from './context';
import { DamageLabel } from '../../ui/components/damage-label';
import { EntityPanel } from '../../ui/components/entity-panel';
import { BuildingPanelVisible } from '../types';

import type { Building } from '..';

import { useEventValue } from '~/client/core/event-steam/ui/hooks/use-event-value';

type Props = {
  target: Building;
};

export const BuildingUI: React.FC<Props> = ({ target }) => {
  const panelVisible = useEventValue(target.events.onChangePanelVisible, target.panelVisible);

  return (
    <BuildingContext.Provider value={target}>
      <EntityPanel
        renderItem={target.renderItem}
        indicators={
          <>
            {panelVisible === BuildingPanelVisible.None && (
              <UpgradeHint />
            )}
            <DamageLabel live={target.schema.live} />
            <Indicators />
          </>
        }
        meta={<BuildingPanel visible={panelVisible} />}
        bars={<BuildProgress />}
      >
        {target.selfOwn && (
          <AlertLabel />
        )}
      </EntityPanel>
    </BuildingContext.Provider>
  );
};
