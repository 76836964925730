import React, { useEffect, useRef, useState } from 'react';

import type { Entity } from '../../..';

import styles from './styles.module.scss';

type Props = {
  children: React.ReactNode;
  entity: Entity;
  messageShow: string;
  messageHide?: string;
  onDisplay?: VoidFunction;
};

export const Indicator: React.FC<Props> = ({ entity, messageShow, messageHide, children, onDisplay }) => {
  const [active, setActive] = useState(false);

  const refTimeout = useRef<Nullable<Timer>>(null);

  const stopHiding = () => {
    if (refTimeout.current) {
      clearTimeout(refTimeout.current);
      refTimeout.current = null;
    }
  };

  const setHideTimeout = () => {
    refTimeout.current = setTimeout(() => {
      setActive(false);
      refTimeout.current = null;
    }, 1500);
  };

  useEffect(() => {
    if (entity.disposed) {
      return;
    }

    // @ts-ignore
    entity.messages.on(messageShow, () => {
      stopHiding();
      setActive(true);
      onDisplay?.();

      if (!messageHide) {
        setHideTimeout();
      }
    });

    if (messageHide) {
      // @ts-ignore
      entity.messages.on(messageHide, () => {
        setActive(false);
      });
    }

    return () => {
      stopHiding();
    };
  }, []);

  return active && <div className={styles.container}>{children}</div>;
};
