import cn from 'classnames';
import React from 'react';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Icon } from '~/client/core/ui/components/icon';
import { Interactive } from '~/client/core/ui/components/interactive';
import { Scrollbar } from '~/client/core/ui/components/scrollbar';
import { Text } from '~/client/core/ui/components/text';

import styles from './styles.module.scss';

import IconBack from './icons/back.svg';

type Props = {
  title: string;
  children: React.ReactNode;
  stretch?: boolean;
  onClose?: VoidFunction;
};

export const InventoryPanel: React.FC<Props> = ({
  title,
  children,
  stretch,
  onClose,
}) => {
  const language = useLanguage();

  return (
    <div className={cn(styles.container, {
      [styles.stretch]: stretch,
    })}>
      <div className={styles.header}>
        <Text size="xl" bold uppercase>
          {title}
        </Text>
        {onClose && (
          <Interactive className={styles.back} onClick={onClose}>
            <Icon type={IconBack} className={styles.icon} />
            {language('Back')}
          </Interactive>
        )}
      </div>
      <Scrollbar>
        <div className={styles.list}>{children}</div>
      </Scrollbar>
    </div>
  );
};
