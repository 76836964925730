export class Throttle {
  private targetTime: number;

  private scale: number = 1.0;

  private delay: number;

  constructor(delay: number) {
    this.delay = delay;
    this.targetTime = performance.now();
  }

  public call(callback: VoidFunction): void {
    const timeleft = this.targetTime - performance.now();
    if (timeleft > 0) {
      return;
    }

    callback();

    const delay = Math.round(this.delay * this.scale);
    this.targetTime = performance.now() + delay;
  }
}
