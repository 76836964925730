export type SystemMessageConfig = {
  type: SystemMessageType;
  title: string;
  sign: string;
  message?: string | string[];
};

export enum SystemMessageType {
  Info = 'Info',
  Error = 'Error',
}
