import type { LiveSchema } from '../../../live/types';
import type { UnitMessagePayload } from '../../types';
import type { NPCSchema } from '../types';
import type { Vector3Like } from 'three';

export interface MobSchema extends NPCSchema {
  readonly variant: MobVariant
  readonly live: LiveSchema
  readonly level: number
}

export enum MobVariant {
  // Basic
  Turtle = 'Turtle',
  Spider = 'Spider',
  Siren = 'Siren',
  // Extra
  Orc = 'Orc',
  Golem = 'Golem',
  Eye = 'Eye',
  EyeChild = 'EyeChild',
  // Ultra
  Crab = 'Crab',
  Stingray = 'Stingray',
  Demon = 'Demon',
  // Other
  Boss = 'Boss',
}

/**
 * Messages
 */

export enum MobMessage {
  Attack = 'Mob:Attack',
  ExtraAttack = 'Mob:ExtraAttack',
  Freeze = 'Mob:Freeze',
  Boost = 'Mob:Boost',
  Unboost = 'Mob:Unboost',
}

export type MobMessagePayload = UnitMessagePayload & {
  [MobMessage.Attack]: {
    targetPosition: Vector3Like;
  };
  [MobMessage.Freeze]: {
    duration: number;
  };
  [MobMessage.Boost]: void;
  [MobMessage.Unboost]: void;
};
