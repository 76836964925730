export const RELAY_SCENE_LIGHT = 0xcccccc;

export const RELAY_SCENE_BACKGROUND_COLOR = 0x2f3149;

/**
 * Space dust
 */

export const RELAY_SCENE_SPACE_DUST_SPREAD = 600;

export const RELAY_SCENE_SPACE_DUST_COUNT = 14000;

export const RELAY_SCENE_SPACE_DUST_OPACITY = 0.1;

export const RELAY_SCENE_SPACE_DUST_SIZE = 3;

export const RELAY_SCENE_SPACE_DUST_LAYERS = [2, -1, -2, -3];

