import React from 'react';

import { Key } from './key';
import { KeyAction } from './key/types';
import { Swipe } from './swipe';
import { SwipeDirection } from './swipe/types';
import { Text } from './text';

import { Device } from '~/client/core/device';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { TutorialStep } from '~/shared/battle/tutorial/types';
import { DeviceType } from '~/shared/core/device/types';

type Props = {
  step: TutorialStep;
};

export const TutorialContent: React.FC<Props> = ({ step }) => {
  const language = useLanguage();

  const text = step && language(`Hint${step}`);

  switch (step) {
    case TutorialStep.Attack:
      return Device.type === DeviceType.Desktop && (
        <Key action={KeyAction.Press} target='SPACE'>
          {text}
        </Key>
      );
    case TutorialStep.UpgradeGenerator:
    case TutorialStep.UpgradeTowerFire:
      return (
        <Text>
          {text}
        </Text>
      );
    case TutorialStep.UpgradePlayer:
      return Device.type === DeviceType.Mobile ? (
        <Swipe direction={SwipeDirection.Up}>
          {text}
        </Swipe>
      ) : (
        <Key action={KeyAction.Hold} target={Device.isMacOS ? 'OPTION' : 'ALT'}>
          {text}
        </Key>
      );
    case TutorialStep.BuildAmmunition:
    case TutorialStep.BuildTowerFire:
    case TutorialStep.BuildTowerFrost:
    case TutorialStep.BuildGenerator:
    case TutorialStep.BuildSecondGenerator:
    case TutorialStep.BuildRadar:
      return Device.type === DeviceType.Mobile ? (
        <Swipe direction={SwipeDirection.Left}>
          {text}
        </Swipe>
      ) : (
        <Key action={KeyAction.Hold} target='SHIFT'>
          {text}
        </Key>
      );
  }
};
