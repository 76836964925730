import React, { useCallback } from 'react';

import { useUserData } from '../../../hooks/use-user-data';

import type { Relay } from '~/client/relay';

import { Client } from '~/client/core/client';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Crystals } from '~/client/core/ui/components/amount/crystals';
import { Score } from '~/client/core/ui/components/amount/score';
import { Interactive } from '~/client/core/ui/components/interactive';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';
import { UserAuthRequest } from '~/shared/core/user/auth/types';

import styles from './styles.module.scss';

import IconLogout from './icons/logout.svg';

export const UserInfo: React.FC = () => {
  const room = useRoom<Relay>();
  const name = useUserData('name');
  const crystals = useUserData('crystals');
  const score = useUserData('score');

  const handleClickLogout = useCallback(() => {
    room.sendRequest(UserAuthRequest.Logout).then(() => {
      Client.removeAuthToken();
      room.remountInterface();
    });
  }, []);

  return (
    <div className={styles.container}>
      <Section direction="vertical" align="left" gap={8}>
        <Text size="m" bold nowrap>
          {name}
        </Text>
        <Section direction="horizontal" gap={16}>
          <Crystals value={crystals} />
          <Score value={score} />
        </Section>
      </Section>
      <Interactive onClick={handleClickLogout} className={styles.logout}>
        <IconLogout />
      </Interactive>
    </div>
  );
};
