import { SETTINGS_KEY_PREFIX } from './const';
import { SettingsSwitch } from './types';
import { Storage } from '../storage';

import type { SettingsEvents, SettingsType } from './types';

import { EventStream } from '~/shared/core/event-stream';

export class Settings {
  public static readonly events: SettingsEvents = {
    onUpdate: new EventStream(),
  };

  public static getEnum<T extends string>(type: SettingsType, as: Record<string, T>, defaultValue: T): T {
    const value = Storage.get<T>(`${SETTINGS_KEY_PREFIX}${type}`);
    if (!value) {
      return defaultValue;
    }

    return Object.values(as).includes(value)
      ? value
      : defaultValue;
  }

  public static setEnum(type: SettingsType, value: string): void {
    Storage.set(`${SETTINGS_KEY_PREFIX}${type}`, value);

    this.events.onUpdate.invoke(type);
  }

  public static getBoolean(type: SettingsType, defaultValue: boolean): boolean {
    const value = Storage.get<SettingsSwitch>(`${SETTINGS_KEY_PREFIX}${type}`);
    if (!value) {
      return defaultValue;
    }

    return value === SettingsSwitch.On;
  }

  public static setBoolean(type: SettingsType, value: boolean): void {
    const state = value ? SettingsSwitch.On : SettingsSwitch.Off;
    Storage.set(`${SETTINGS_KEY_PREFIX}${type}`, state);

    this.events.onUpdate.invoke(type);
  }

  public static getInteger(type: SettingsType, defaultValue: number): number {
    const value = Storage.get(`${SETTINGS_KEY_PREFIX}${type}`);
    if (!value) {
      return defaultValue;
    }

    return Number(value);
  }

  public static setInteger(type: SettingsType, value: number): void {
    Storage.set(`${SETTINGS_KEY_PREFIX}${type}`, value.toString());

    this.events.onUpdate.invoke(type);
  }
}
