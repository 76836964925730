import { Vector2, Vector3 } from 'three';

import type { Vector3Like, Vector2Like } from 'three';

export class VectorUtils {
  public static zeroVector3 = new Vector3();

  public static zeroVector2 = new Vector2();

  private static reusableVector3 = new Vector3();

  private static reusableVector2 = new Vector2();

  public static reuse(vector: Vector3Like): Vector3;
  public static reuse(vector: Vector2Like): Vector2;
  public static reuse(vector: Vector3Like | Vector2Like): Vector2 | Vector3 {
    if ('z' in vector) {
      return this.reusableVector3.copy(vector);
    } else {
      return this.reusableVector2.copy(vector);
    }
  }

  public static encode3d(vector: Vector3Like): string {
    return [vector.x, vector.y, vector.z].join(',');
  }

  public static encode2d(vector: Vector3Like): string {
    return [vector.x, vector.z].join(',');
  }

  public static decode3d(vector: string): Vector3Like {
    const [x, y, z] = vector.split(',');
    return {
      x: Number(x),
      y: Number(y),
      z: Number(z),
    };
  }

  public static decode2d(vector: string): Vector3Like {
    const [x, z] = vector.split(',');
    return {
      x: Number(x),
      y: 0,
      z: Number(z),
    };
  }
}

