import type { Crystal } from '..';

import { MaterialType } from '~/client/core/assets/materials/types';
import { Plane } from '~/client/core/render-item/shape/plane';

import './resources';

export class Indicator extends Plane {
  constructor(crystal: Crystal) {
    super(crystal.battle.scene, {
      position: { x: 0.0, y: 0.01, z: 0.0 },
      scale: { x: 0.4, y: 0.4, z: 0.4 },
      material: MaterialType.IndicatorCrystal,
    });

    crystal.renderItem.object.add(this.object);
  }
}
