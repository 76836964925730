import type { LiveSchema, LiveDamageType } from './live/types';
import type { Schema } from '@colyseus/schema';
import type { VectorSchema } from '~/shared/core/vector-schema';

export interface EntitySchema extends Schema {
  readonly id: string;
  readonly ownerId: string;
  readonly visibleForOpponent: boolean;
  readonly position: VectorSchema;
  readonly velocity: VectorSchema;
  readonly speed: number;
  readonly live: LiveSchema;
  readonly active: boolean;
  readonly size: VectorSchema;
}

export type EntityDamagePayload = {
  type: LiveDamageType;
  amount: number;
  initiatorId?: string;
};

/**
 * Messages
 */

export enum EntityMessage {
  Heal = 'Entity:Heal',
  Damage = 'Entity:Damage',
}

export type EntityMessagePayload = {
  [EntityMessage.Heal]: void;
  [EntityMessage.Damage]: EntityDamagePayload;
};
