import { MeshBasicMaterial } from 'three';

import { Assets } from '~/client/core/assets';
import { MaterialType } from '~/client/core/assets/materials/types';

import textureIndicator from './indicator.png';

Assets.addMaterial(MaterialType.IndicatorCrystal, new MeshBasicMaterial({
  map: Assets.createTexture(textureIndicator),
  transparent: true,
  opacity: 0.2,
}));
