import { useEffect, useRef, useState } from 'react';

import { Device } from '../../device';
import { InputMouse } from '../../input/mouse';
import { InputTouch } from '../../input/touch';

import type { InputTouchChannel } from '../../input/touch/channel';
import type { RefObject } from 'react';
import type { EventStreamSubscribe } from '~/shared/core/event-stream/types';

import { DeviceType } from '~/shared/core/device/types';

type Props = {
  active?: boolean;
  target: RefObject<HTMLDivElement>;
  control: RefObject<HTMLDivElement>;
  onStart?: VoidFunction;
  onDrag: (distance: number) => void
};

export function useDraggable({ active, target, control, onStart, onDrag }: Props) {
  const [dragging, setDragging] = useState(false);

  const refMouseMove = useRef<Nullable<EventStreamSubscribe>>(null);
  const refMouseRelease = useRef<Nullable<EventStreamSubscribe>>(null);

  const handleTouch = (touch: InputTouchChannel) => {
    if (!target.current || !touch.targets.includes(target.current)) {
      return;
    }

    onStart?.();
    setDragging(true);

    touch.takeUp();

    touch.events.onMove.on(() => {
      const distance = touch.beginPosition.y - touch.position.y;
      onDrag(distance);
    });

    touch.events.onRelease.on(() => {
      setDragging(false);
    });
  };

  const handleMouseClick = (event: MouseEvent) => {
    if (!control.current || !event.composedPath().includes(control.current)) {
      return;
    }

    onStart?.();
    setDragging(true);

    const beginPosition = InputMouse.position.y;

    refMouseMove.current = InputMouse.events.onMouseMove.on(() => {
      const distance = InputMouse.position.y - beginPosition;
      onDrag(distance);
    });

    refMouseRelease.current = InputMouse.events.onMouseRelease.on(() => {
      setDragging(false);
    });
  };

  useEffect(() => {
    if (!active) {
      return;
    }

    if (Device.type === DeviceType.Mobile) {
      const eventTouch = InputTouch.events.onTouch.on(handleTouch);
      return () => {
        eventTouch.off();
      };
    } else {
      const eventMouseClick = InputMouse.events.onMouseClick.on(handleMouseClick);
      return () => {
        eventMouseClick.off();
      };
    }
  }, [active]);

  useEffect(() => {
    if (!dragging || Device.type === DeviceType.Mobile) {
      return;
    }

    return () => {
      refMouseMove.current?.off();
      refMouseMove.current = null;
      refMouseRelease.current?.off();
      refMouseRelease.current = null;
    };
  }, [dragging]);

  return { dragging };
}
