import { BuildingTower } from '..';

import type { Battle } from '~/client/battle';
import type { BuildingTowerFireSchema } from '~/shared/battle/entity/building/variants/tower/fire/types';

import { ModelType } from '~/client/core/assets/types';

import './resources';

export class BuildingTowerFire extends BuildingTower {
  constructor(battle: Battle, schema: BuildingTowerFireSchema) {
    super(battle, {
      model: ModelType.BuildingTowerFire,
    }, schema);
  }
}
