import { UpgradeVariant } from './types';
import { UpgradeUtils } from './utils';

import type { Progression } from '~/shared/core/progression';

export const UPGRADE_MAX_LEVEL = 6;

export const UPGRADE_COST_GROWTH = 3.0;

export const UPGRADE_COSTS: Record<UpgradeVariant, Progression> = {
  [UpgradeVariant.MainMaxHealth]: UpgradeUtils.costProgression(60),
  [UpgradeVariant.MainSpeed]: UpgradeUtils.costProgression(60),
  [UpgradeVariant.MainDroid]: UpgradeUtils.costProgression(90),
  [UpgradeVariant.AttackRecovery]: UpgradeUtils.costProgression(60),
  [UpgradeVariant.AttackRadius]: UpgradeUtils.costProgression(60),
  [UpgradeVariant.AttackDamage]: UpgradeUtils.costProgression(80),
  [UpgradeVariant.BuildSpeed]: UpgradeUtils.costProgression(30),
  [UpgradeVariant.BuildRadius]: UpgradeUtils.costProgression(30),
};
