import { TerrainBiomeType } from '~/shared/battle/terrain/types';

export const MINIMAP_REPAINT_BIOMES = [
  TerrainBiomeType.Coast,
  TerrainBiomeType.Fields,
];

export const MINIMAP_UPDATE_RATE = 1000 / 10;

export const MINIMAP_FRUSTUM = 23;
