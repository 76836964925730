import type { TimeSource } from '../types';

export class Delay {
  private readonly time: TimeSource;

  private duration: number = 0;

  private targetTime: number = 0;

  constructor(time?: TimeSource) {
    this.time = time ?? {
      get: () => performance.now(),
    };
  }

  public start(): void {
    this.targetTime = this.time.get() + this.duration;
  }

  public isPast(): boolean {
    return this.getTimeleft() <= 0;
  }

  public setDuration(duration: number) {
    this.duration = duration;
  }

  public getProgress(): number {
    return 1 - (Math.max(0, this.getTimeleft()) / this.duration);
  }

  public getTimeleft(): number {
    return this.targetTime - this.time.get();
  }
}
