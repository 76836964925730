import { BuildingTower } from '..';

import type { Battle } from '~/client/battle';
import type { BuildingTowerFrostSchema } from '~/shared/battle/entity/building/variants/tower/frost/types';

import { ModelType } from '~/client/core/assets/types';

import './resources';

export class BuildingTowerFrost extends BuildingTower {
  constructor(battle: Battle, schema: BuildingTowerFrostSchema) {
    super(battle, {
      model: ModelType.BuildingTowerFrost,
    }, schema);
  }
}
