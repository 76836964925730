import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';

import { useSelfPlayerSchema } from '~/client/battle/ui/hooks/use-self-player-schema';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';

import styles from './styles.module.scss';

export const LevelPanel: React.FC = () => {
  const language = useLanguage();

  const player = useSelfPlayerSchema();

  const level = useSchemaValue(player, 'level');
  const experienceProgress = useSchemaValue(player, 'experienceProgress');
  const experience = useSchemaValue(player, 'experience');

  return (
    <div className={styles.container}>
      <div
        className={styles.level}
        style={{
          '--progress': `${experienceProgress}%`,
        }}
      >
        {level}
      </div>
      <div className={styles.experience}>
        <div className={styles.label}>{language('Experience')}</div>
        <div className={styles.value}>{experience}</div>
      </div>
    </div>
  );
};

declare module 'react' {
  interface CSSProperties {
    [key: `--${string}`]: string | number
  }
}
