import { Entity } from '..';

import type { ShotConfig } from './types';
import type { Battle } from '../..';
import type { AudioType } from '~/client/core/audio/types';
import type { ShotSchema } from '~/shared/battle/entity/shot/types';
import type { EntitySchema } from '~/shared/battle/entity/types';

export class Shot extends Entity {
  declare public schema: ShotSchema;

  constructor(battle: Battle, { renderItem, audioEffect }: ShotConfig, schema: EntitySchema) {
    super(battle, renderItem, schema);

    this.listenSchemaActive(audioEffect);
  }

  private listenSchemaActive(audioEffect: AudioType) {
    let prevActive = true;

    this.schema.listen('active', (active) => {
      if (active !== prevActive) {
        if (active) {
          this.playAudio(audioEffect);
        } else {
          this.stopAudio(audioEffect);
        }
      }

      prevActive = active;
    });
  }
}
