import { Assets } from '~/client/core/assets';
import { AudioMode, AudioType } from '~/client/core/audio/types';

import audioShot1 from './shot1.mp3';
import audioShot2 from './shot2.mp3';

Assets.addAudio(AudioType.ShotFrost, {
  path: [audioShot1, audioShot2],
  mode: AudioMode.Track3D,
  volume: 0.5,
  limit: 4,
});
