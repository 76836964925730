import cn from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

import IconBoost from './icons/boost.svg';

type Props = {
  opponent?: boolean;
};

export const Boost: React.FC<Props> = ({ opponent }) => (
  <IconBoost className={cn(styles.icon, {
    [styles.opponent]: opponent,
  })} />
);
