export const BINARY_EXTENSION_NAME = 'KHR_binary_glTF';

export const BINARY_EXTENSION_HEADER_MAGIC = 'glTF';

export const BINARY_EXTENSION_HEADER_LENGTH = 12;

export const BINARY_EXTENSION_CHUNK_TYPES = {
  JSON: 0x4e4f534a,
  BIN: 0x004e4942,
};
