import React from 'react';

import { SystemMessage } from '..';
import { Interface } from '../../..';

import type { SystemMessageConfig } from './types';

export class SystemMessageUtils {
  public static render(
    id: string,
    { type, sign, title, message }: SystemMessageConfig,
  ) {
    return Interface.inject(
      id,
      <SystemMessage type={type} sign={sign} title={title}>
        {(Array.isArray(message) ? message : [message]).map((line, i) => (
          <p key={i}>{line}</p>
        ))}
      </SystemMessage>,
    );
  }
}
