import React, { useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { OVERLAY_CLOSE_KEY, OVERLAYS } from './const';
import { OverlayState } from './state';

import { Device } from '~/client/core/device';
import { InputKeyboard } from '~/client/core/input/keyboard';
import { Interactive } from '~/client/core/ui/components/interactive';
import { DeviceType } from '~/shared/core/device/types';

import styles from './styles.module.scss';

import IconClose from './icons/close.svg';

export const Overlay: React.FC = () => {
  const [overlay, setOverlay] = useRecoilState(OverlayState);

  const OverlayComponent = overlay && OVERLAYS[overlay];

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === OVERLAY_CLOSE_KEY) {
      event.preventDefault();
      setOverlay(null);
    }
  };

  const handleClickClose = useCallback(() => {
    setOverlay(null);
  }, []);

  useEffect(() => {
    if (Device.type === DeviceType.Mobile || !overlay) {
      return;
    }

    const eventKeyDown = InputKeyboard.events.onKeyDown.on(handleKeyDown);
    return () => {
      eventKeyDown.off();
    };
  }, [Boolean(overlay)]);

  return OverlayComponent && (
    <div className={styles.screen}>
      <OverlayComponent />
      <Interactive onClick={handleClickClose} className={styles.close}>
        <IconClose />
      </Interactive>
    </div>
  );
};
