import { Droid } from '../..';

import type { Battle } from '~/client/battle';
import type { Shot } from '~/client/battle/entity/shot';
import type { DroidCombatSchema } from '~/shared/battle/entity/unit/npc/droid/variants/combat/types';

import { ShotFactory } from '~/client/battle/entity/shot/factory';
import { ModelType } from '~/client/core/assets/types';

import './resources';

export class DroidCombat extends Droid {
  declare public readonly schema: DroidCombatSchema;

  private readonly shot: Shot;

  constructor(battle: Battle, schema: DroidCombatSchema) {
    super(battle, {
      model: ModelType.DroidCombat,
    }, schema);

    this.shot = ShotFactory.create(this.battle, schema.weapon.shot);
  }

  override destroy(): void {
    this.shot.destroy();

    super.destroy();
  }

  override rotateToVelocity() {
    const targetId = this.schema.weapon.targetId;
    if (targetId) {
      const target = this.battle.getEntity(targetId);
      if (target) {
        this.rotateToPosition(target.renderItem.position);
      }
    } else {
      super.rotateToVelocity();
    }
  }
}
