import { Building } from '../..';

import type { BuildingSpawnerConfig } from './types';
import type { Battle } from '~/client/battle';
import type { BuildingSchema } from '~/shared/battle/entity/building/types';

export abstract class BuildingSpawner extends Building {
  constructor(battle: Battle, config: BuildingSpawnerConfig, schema: BuildingSchema) {
    super(battle, config, schema);
  }
}

