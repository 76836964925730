import { AudioListener } from 'three';

import { AudioTrack2D } from './track/track2d';
import { AudioTrack3D } from './track/track3d';
import { AudioMode } from './types';
import { Assets } from '../assets';
import { Logger } from '../logger';

import type { AudioTrack3DPlayData } from './track/track3d/types';
import type { AudioType } from './types';
import type { RenderItem } from '../render-item';

export class Audio {
  public enabled: boolean = true;

  public muffled: boolean = false;

  private readonly listener: AudioListener = new AudioListener();

  private readonly tracks3D: Map<AudioType, AudioTrack3D> = new Map();

  private readonly tracks2D: Map<AudioType, AudioTrack2D> = new Map();

  constructor() {
    Assets.getAudio().forEach((data, type) => {
      if (data.mode === AudioMode.Track3D) {
        const track = new AudioTrack3D(type, this.listener, {
          ...data,
          buffers: Array.isArray(data.buffer) ? data.buffer : [data.buffer],
        });
        this.tracks3D.set(type, track);
      } else {
        const track = new AudioTrack2D(type, this.listener, {
          ...data,
          buffer: Array.isArray(data.buffer) ? data.buffer[0] : data.buffer,
        });
        this.tracks2D.set(type, track);
      }
    });
  }

  public destroy() {
    this.removeTracks(this.tracks2D);
    this.removeTracks(this.tracks3D);
  }

  public muffle(state: boolean) {
    if (this.muffled === state) {
      return;
    }

    this.muffled = state;
    this.refresh();
  }

  public toggle(state: boolean) {
    if (this.enabled === state) {
      return;
    }

    this.enabled = state;
    this.refresh();
  }

  private refresh() {
    const tracks = [
      ...this.tracks2D.values(),
      ...this.tracks3D.values(),
    ];

    tracks.forEach((track) => {
      if (this.enabled && !this.muffled) {
        track.enable();
      } else {
        track.disable();
      }
    });
  }

  // TODO: Check posing
  public setTarget(target: RenderItem) {
    target.object.add(this.listener);
  }

  public play2D(type: AudioType) {
    const track = this.tracks2D.get(type);
    if (!track) {
      Logger.warn(`Unable to play unregistered 2D audio '${type}'`);
      return;
    }

    track.play();
  }

  public play3D(type: AudioType, data: AudioTrack3DPlayData) {
    const track = this.tracks3D.get(type);
    if (!track) {
      Logger.warn(`Unable to play unregistered 3D audio '${type}'`);
      return;
    }

    track.play(data);
  }

  public stop(type: AudioType) {
    const track = (
      this.tracks3D.get(type) ??
      this.tracks2D.get(type)
    );
    track?.stop();
  }

  private removeTracks(target: Map<AudioType, AudioTrack2D | AudioTrack3D>) {
    target.forEach((track, type) => {
      track.destroy();
      target.delete(type);
    });
  }
}
