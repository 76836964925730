import { MeshLambertMaterial } from 'three';

import { Assets } from '~/client/core/assets';
import { MaterialType } from '~/client/core/assets/materials/types';

import textureBuilding from './building.png';

Assets.addMaterial(MaterialType.Building, new MeshLambertMaterial({
  map: Assets.createTexture(textureBuilding),
  reflectivity: 0,
}));
