import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';

import { useMob } from '../../hooks/use-mob';

import { ProgressBar } from '~/client/battle/entity/ui/components/progress-bar';

export const ArmourBar: React.FC = () => {
  const mob = useMob();

  const armour = useSchemaValue(mob.schema.live, 'armour');
  const maxArmour = useSchemaValue(mob.schema.live, 'maxArmour');

  return (armour > 0) && (
    <ProgressBar
      value={armour}
      maxValue={maxArmour}
      color="#69cde3"
    />
  );
};
