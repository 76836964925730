import { Shot } from '../..';

import type { BulletConfig } from './types';
import type { Battle } from '~/client/battle';
import type { ShotSchema } from '~/shared/battle/entity/shot/types';

import { Sphere } from '~/client/core/render-item/shape/sphere';

export abstract class Bullet extends Shot {
  declare public readonly renderItem: Sphere;

  constructor(battle: Battle, { size = 1.0, audioEffect, ...config }: BulletConfig, schema: ShotSchema) {
    const renderItem = new Sphere(battle.scene, {
      ...config,
      radius: size * 0.1,
      position: schema.position,
    });

    super(battle, {
      renderItem,
      audioEffect,
    }, schema);
  }
}
