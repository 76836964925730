import React from 'react';

import { Controls } from './components/controls';
import { Logotype } from './components/logotype';
import { Menu } from './components/menu';
import { Overlay } from './components/overlays';
import { Page } from './components/pages';
import { PlayersOnline } from './components/players-online';
import { UserPanel } from './components/user-panel';
import { VersionTag } from './components/version-tag';

import { Section } from '~/client/core/ui/components/section';
import { useRelativeScreen } from '~/client/core/ui/hooks/use-relative-screen';

import styles from './styles.module.scss';

export const RelayScreen: React.FC = () => {
  const refScreen = useRelativeScreen();

  return (
    <div ref={refScreen} className={styles.screen}>
      <div className={styles.wrapper}>
        <div className={styles.sidebar}>
          <Section direction="vertical" gap={32}>
            <Logotype />
            <UserPanel />
            <Menu />
          </Section>
          <PlayersOnline />
        </div>
        <div className={styles.content}>
          <Page />
        </div>
      </div>
      <Controls />
      <Overlay />
      <div className={styles.shadow} />
      <VersionTag />
    </div>
  );
};
