import cn from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

type Props = {
  children: string;
  size: 's' | 'm' | 'l'
};

export const KeyboardKey: React.FC<Props> = ({ children, size }) => (
  <div className={cn(styles.size, styles[size])}>
    <div className={styles.wrapper}>
      <div className={styles.key}>{children}</div>
    </div>
  </div>
);

