import cn from 'classnames';
import React from 'react';

import { PARAMETER_ICON } from './const';

import type { ParameterType } from './types';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Icon } from '~/client/core/ui/components/icon';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';

import styles from './styles.module.scss';

type Props = {
  value: number;
  type: ParameterType;
  alert?: string;
};

export const Parameter: React.FC<Props> = ({ type, value, alert }) => {
  const language = useLanguage();

  return (
    <div className={styles.wrapper}>
      <Section direction="horizontal" align="center" gap={8}>
        <Icon type={PARAMETER_ICON[type]} className={styles.icon} />
        <div className={styles.amount}>{value}</div>
      </Section>
      <div className={cn(styles.placeholder, styles.hint)}>
        <Text size='xs' bold nowrap uppercase>{language(`Parameter${type}`)}</Text>
      </div>
      {alert && value <= 5 && (
        <div className={cn(styles.placeholder, styles.alert)}>
          <Text size='s' nowrap uppercase>{alert}</Text>
        </div>
      )}
    </div>
  );
};

