import type { EventStream } from '~/shared/core/event-stream';

export enum SettingsType {
  FpsLimit = 'FpsLimit',
  Resolution = 'Resolution',
  VisualEffects = 'VisualEffects',
  AudioEffects = 'AudioEffects',
  Fullscreen = 'Fullscreen',
  Language = 'Language',
}

export type SettingsEvents = {
  onUpdate: EventStream<SettingsType>
};

export enum SettingsSwitch {
  On = 'On',
  Off = 'Off'
}

