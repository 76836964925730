import { PlaneGeometry } from 'three';
import { mergeGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils';

import type { TileSchema } from '~/shared/battle/terrain/tile/types';

import { MeshSetChild } from '~/client/core/render-item/mesh-set/child';
import { TILE_FACES } from '~/shared/battle/terrain/tile/const';

import './resources';

export class Tile extends MeshSetChild {
  constructor(schema: TileSchema) {
    super({
      position: schema.position,
    });
  }

  public static makeGeometry(indexes: number[]) {
    const faces = indexes.map((index) => this.makeFace(index));
    return mergeGeometries(faces);
  }

  private static makeFace(index: number) {
    const { p, r } = TILE_FACES[index];
    const face = new PlaneGeometry();

    face.rotateX(r.x);
    face.rotateY(r.y);
    face.rotateZ(r.z);
    face.translate(p.x, p.y, p.z);

    return face;
  }

  public static getGroupKey(schema: TileSchema) {
    const indexes = schema.visibleIndexes;
    if (indexes.length === 0) {
      return null;
    }

    return `${schema.biome}|${schema.index}|${indexes.join('-')}`;
  }
}
