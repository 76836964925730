import React, { useState } from 'react';

import { Param } from '..';
import { Option } from '../option';

import type { DictionaryPhrase } from '~/client/core/language/types';
import type { SettingsType } from '~/client/core/settings/types';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Settings } from '~/client/core/settings';
import { Section } from '~/client/core/ui/components/section';

import styles from './styles.module.scss';

type Props<T extends DictionaryPhrase> = {
  type: SettingsType;
  defaultValue: T;
  options: T[];
  save?: boolean;
  onChange: (value: T) => void;
};

export const ParamEnum = <T extends DictionaryPhrase>({
  type,
  defaultValue,
  options,
  save = true,
  onChange,
}: Props<T>) => {
  const language = useLanguage();

  const [value, setValue] = useState(defaultValue);

  const handleChange = (newValue: T) => {
    setValue(newValue);
    onChange(newValue);

    if (save) {
      Settings.setEnum(type, newValue);
    }
  };

  return (
    <Param type={type}>
      <Section direction="horizontal" gap={1} className={styles.wrapper}>
        {options.map((option) => (
          <Option
            key={option}
            value={language(option)}
            active={value === option}
            onClick={() => handleChange(option)}
          />
        ))}
      </Section>
    </Param>
  );
};
