import type React from 'react';

export enum PageType {
  Play = 'Page:Play',
  Inventory = 'Page:Inventory',
  Leaderboard = 'Page:Leaderboard',
}

export type PageData = {
  component: React.FC;
  icon: SVGIcon;
  authorizedOnly?: boolean;
};
