import React from 'react';

import type { KeyAction } from './types';

import { KeyboardKey } from '~/client/core/input/keyboard/ui/components/key';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Section } from '~/client/core/ui/components/section';

import styles from './styles.module.scss';

type Props = {
  children: React.ReactNode;
  action: KeyAction;
  target: string;
};

export const Key: React.FC<Props> = ({ children, action, target }) => {
  const language = useLanguage();

  return (
    <Section direction='vertical' gap={6}>
      <Section direction='horizontal' align='center' gap={12}>
        <div className={styles.text}>{language(action)}</div>
        <KeyboardKey size='l'>{target}</KeyboardKey>
        <div className={styles.text}>{language('To')}</div>
      </Section>
      <div className={styles.text}>{children}</div>
    </Section>
  );
};
