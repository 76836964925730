import type { Schema } from '@colyseus/schema';

export interface WaveSchema extends Schema {
  readonly timeleft: number;
  readonly number: number;
  readonly going: boolean;
  readonly paused: boolean;
  readonly mobsLeft: number;
}

/**
 * Messages
 */

export enum WaveMessage {
  SkipTimeleft = 'Wave:SkipTimeleft',
  Start = 'Wave:Start',
  Complete = 'Wave:Complete',
}

export type WaveMessagePayload = {
  [WaveMessage.SkipTimeleft]: void;
  [WaveMessage.Start]: void;
  [WaveMessage.Complete]: void;
};
