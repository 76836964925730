import React from 'react';

import { Text } from '../../text';

import type { SettingsType } from '~/client/core/settings/types';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';

import styles from './styles.module.scss';

type Props = {
  type: SettingsType;
  children: React.ReactNode;
};

export const Param: React.FC<Props> = ({ type, children }) => {
  const language = useLanguage();

  return (
    <div className={styles.container}>
      <Text size="m" uppercase>
        {language(type)}
      </Text>
      {children}
    </div>
  );
};
