import { atom } from 'recoil';

import { Language } from '..';

import type { LanguageType } from '../types';

export const LanguageState = atom<LanguageType>({
  key: 'Language',
  default: Language.getType(),
});
