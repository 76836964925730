import { DroidVariant } from './types';

import type { DroidSharedConfig } from './types';

export const DROIDS_SHARED_CONFIG: Record<DroidVariant, DroidSharedConfig> = {
  [DroidVariant.Combat]: {
    cost: 0,
  },
  [DroidVariant.Mechanic]: {
    cost: 1000,
  },
  [DroidVariant.Medic]: {
    cost: 1000,
  },
};
