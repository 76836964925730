import { SkillVariant } from '../../unit/player/skill/types';
import { BUILDINGS_SHARED_CONFIG } from '../const';
import { BuildingVariant } from '../types';

import type { BattleSchema } from '../../../types';
import type { PlayerSchema } from '../../unit/player/types';
import type { BuildingSchema } from '../types';

export class BuildingUtils {
  public static getCost(variant: BuildingVariant, player: PlayerSchema): number {
    let cost = BUILDINGS_SHARED_CONFIG[variant].cost;
    if (variant === BuildingVariant.Wall) {
      const skill = player.skills.get(SkillVariant.DiscountWalls);
      if (skill) {
        cost *= skill.multiplier;
      }
    }
    return cost;
  }

  public static getUpgradeCost(building: BuildingSchema, player: PlayerSchema, level?: number): number {
    const cost = this.getCost(building.variant, player);
    const levelCost = cost / 2;
    return levelCost * (level ?? building.level);
  }

  public static getRepairCost(building: BuildingSchema, player: PlayerSchema): number {
    const damaged = 1 - (building.live.health / building.live.maxHealth);
    return Math.ceil(this.getTotalCost(building, player) * damaged);
  }

  public static getTotalCost(building: BuildingSchema, player: PlayerSchema): number {
    let cost = this.getCost(building.variant, player);
    for (let i = 0; i < building.level - 1; i++) {
      cost += this.getUpgradeCost(building, player, i + 1);
    }
    return cost;
  }

  public static isMaxLevel(building: BuildingSchema): boolean {
    return building.level >= building.maxLevel;
  }

  public static getLevelToUpgrade(building: BuildingSchema, battle: BattleSchema): number {
    const { minLevel, upgradeStep } = BUILDINGS_SHARED_CONFIG[building.variant];
    return minLevel[battle.mode] + (building.level * upgradeStep);
  }

  public static hasLevelToUpgrade(building: BuildingSchema, battle: BattleSchema, player: PlayerSchema): boolean {
    return player.level >= this.getLevelToUpgrade(building, battle);
  }
}
