import { useContext } from 'react';

import { MobContext } from '../context';

export function useMob() {
  const mob = useContext(MobContext);

  if (!mob) {
    throw Error('Undefined mob context');
  }

  return mob;
}
