import type { DICTIONARIES } from './const';

export enum LanguageType {
  EN = 'EN',
  FR = 'FR',
  PT = 'PT',
  RU = 'RU'
}

export type DictionaryPhrase = keyof typeof DICTIONARIES[LanguageType.EN];
