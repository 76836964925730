import { SHOT_INSTANCES } from './const';

import type { Battle } from '../../..';
import type { ShotSchema } from '~/shared/battle/entity/shot/types';

export class ShotFactory {
  public static create(battle: Battle, schema: ShotSchema) {
    const ShotInstance = SHOT_INSTANCES[schema.variant];

    return new ShotInstance(battle, schema);
  }
}
