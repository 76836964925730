import cn from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

type Props = {
  children: React.ReactNode;
  size: 'xs' | 's' | 'm' | 'l' | 'xl' | '2xl';
  bold?: boolean;
  uppercase?: boolean;
  nowrap?: boolean;
  view?: 'default' | 'primary' | 'secondary' | 'transparent'
  className?: string;
};

export const Text: React.FC<Props> = ({
  children,
  bold,
  uppercase,
  nowrap,
  size,
  className,
  view = 'default',
}) => (
  <div className={cn(className, styles.text, styles[`size-${size}`], styles[`view-${view}`], {
    [styles.bold]: bold,
    [styles.uppercase]: uppercase,
    [styles.nowrap]: nowrap,
  })}>
    {children}
  </div>
);
