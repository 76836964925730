import { Request } from '../request';
import { SystemMessageUtils } from '../ui/components/system-message/utils';
import { SystemMessageType } from '../ui/components/system-message/utils/types';

import type { LoggerClientPayload } from '~/shared/core/logger/types';

import { LOGGER_ROUTE_PATH } from '~/shared/core/logger/const';
import { LoggerUtils } from '~/shared/core/logger/utils';

export class Logger {
  public static addListeners() {
    window.addEventListener('error', (event: ErrorEvent) => {
      event.preventDefault();

      this.error('System error', event.error);
      this.renderError(event.message);
    });

    window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
      event.preventDefault();

      this.error('System error', event.reason);
      this.renderError(event.reason?.message);
    });
  }

  public static renderError(message?: string) {
    SystemMessageUtils.render('system-error', {
      type: SystemMessageType.Error,
      sign: '☠️',
      title: 'System Error',
      message,
    });
  }

  public static debug(message: string) {
    console.debug(message);
  }

  public static warn(message: string) {
    console.warn(message);

    this.send({
      type: 'warn',
      message,
      userAgent: navigator.userAgent,
    });
  }

  public static error(message: string, error?: Error) {
    const fullMessage = LoggerUtils.formatErrorMessage(message, error);
    console.error(fullMessage);

    this.send({
      type: 'error',
      message: fullMessage,
      stack: error?.stack,
      userAgent: navigator.userAgent,
    });
  }

  private static send(payload: LoggerClientPayload) {
    Request.post(LOGGER_ROUTE_PATH, payload)
      .catch(() => {});
  }
}
