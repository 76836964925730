import { Mob } from '../..';

import type { Battle } from '~/client/battle';
import type { MobSchema } from '~/shared/battle/entity/unit/npc/mob/types';

import { ModelType } from '~/client/core/assets/types';
import { AudioType } from '~/client/core/audio/types';

import './resources';

export class MobBoss extends Mob {
  constructor(battle: Battle, schema: MobSchema) {
    super(battle, {
      model: ModelType.MobBoss,
    }, schema);
  }

  override onDead(): void {
    super.onDead();

    this.playAudio(AudioType.MobBossRoar);
  }
}

