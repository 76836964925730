import { FOG_FADE, FOG_INTENSITY, FOG_MAX_LIGHTS } from '../../../const';

export const SHADER_FOG_FRAGMENT = `
  #define MAX_LIGHTS ${FOG_MAX_LIGHTS}

  varying vec2 vUv;
  
  uniform int lightsCount;
  uniform float maskRadius[MAX_LIGHTS];
  uniform vec2 maskPosition[MAX_LIGHTS];

  void main() {
    float totalAlpha = 0.0;
    for (int i = 0; i < lightsCount; i++) {
      totalAlpha += smoothstep(
        maskRadius[i] + ${FOG_FADE / 2}, 
        maskRadius[i] - ${FOG_FADE * 2}, 
        distance(vUv, maskPosition[i])
      );
    }
    gl_FragColor = vec4(0.0, 0.0, 0.0, ${FOG_INTENSITY} - totalAlpha);
  }
`;
