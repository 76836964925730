import { StageFinished } from './components/stages/finished';
import { StageStarted } from './components/stages/started';

import type React from 'react';

import { BattleStage } from '~/shared/battle/types';

export const BATTLE_STAGE_COMPONENT_MAP: Record<BattleStage, Nullable<React.FC>> = {
  [BattleStage.Preparing]: null,
  [BattleStage.Started]: StageStarted,
  [BattleStage.Finished]: StageFinished,
};
