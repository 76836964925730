import cn from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

type Props = {
  error: {
    active: boolean;
    message?: Nullable<string>;
  };
};

export const ErrorPlate: React.FC<Props> = ({ error }) => {
  if (!error.message) {
    return null;
  }

  return (
    <div className={cn(styles.error, {
      [styles.animate]: error.active,
    })}>
      {error.message}
    </div>
  );
};
