import { Assets } from '~/client/core/assets';
import { AudioMode, AudioType } from '~/client/core/audio/types';

import audioShot from './shot.mp3';

Assets.addAudio(AudioType.ShotSimple, {
  path: audioShot,
  mode: AudioMode.Track3D,
  volume: 0.75,
  limit: 2,
});
