import { BuildingVariant } from '../entity/building/types';
import { UPGRADE_MAX_LEVEL } from '../entity/unit/player/upgrades/const';
import { BattleMode } from '../types';

import { Progression } from '~/shared/core/progression';

export const BUILDER_RADIUS = new Progression({
  defaultValue: 3.0,
  maxValue: 5.0,
  maxLevel: UPGRADE_MAX_LEVEL,
});

export const BUILDER_RESTRICTION_LEVEL_GRADE: Record<BattleMode, number> = {
  [BattleMode.Online]: 3,
  [BattleMode.Single]: 4,
};

export const BUILDER_FLOW: Record<BattleMode, BuildingVariant[]> = {
  [BattleMode.Single]: [
    BuildingVariant.Generator,
    BuildingVariant.TowerFire,
  ],
  [BattleMode.Online]: [
    BuildingVariant.Generator,
    BuildingVariant.TowerFire,
    BuildingVariant.SpawnerSmall,
  ],
};
