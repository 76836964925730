import { SkillType, SkillVariant } from './types';

import type { SkillSharedConfig } from './types';

export const SKILL_DEFAULT_SLOTS = 3;

export const SKILL_MAX_SLOTS = 4;

export const SKILL_SLOT_COST = 3000;

export const SKILLS_SHARED_CONFIG: Record<SkillVariant, SkillSharedConfig> = {
  [SkillVariant.BoostSpeed]: {
    type: SkillType.Active,
    recovery: 15000,
    duration: 3000,
    default: true,
    cost: 0,
  },
  [SkillVariant.InspirationDroid]: {
    type: SkillType.Active,
    recovery: 20000,
    duration: 5000,
    default: true,
    cost: 0,
  },
  [SkillVariant.DiscountWalls]: {
    type: SkillType.Passive,
    default: true,
    cost: 0,
  },
  [SkillVariant.DamageReflection]: {
    type: SkillType.Passive,
    cost: 50,
  },
  [SkillVariant.BoostGenerators]: {
    type: SkillType.Passive,
    cost: 100,
  },
  [SkillVariant.Vampire]: {
    type: SkillType.Passive,
    cost: 200,
  },
  [SkillVariant.AttackRecovery]: {
    type: SkillType.Passive,
    cost: 200,
  },
  [SkillVariant.CallingMobs]: {
    type: SkillType.Active,
    recovery: 30000,
    cost: 300,
  },
  [SkillVariant.HighDamage]: {
    type: SkillType.Passive,
    cost: 300,
  },
  [SkillVariant.FrostWave]: {
    type: SkillType.Active,
    recovery: 30000,
    cost: 350,
  },
  [SkillVariant.InspirationTowers]: {
    type: SkillType.Active,
    recovery: 40000,
    duration: 5000,
    cost: 350,
  },
  [SkillVariant.InspirationMobs]: {
    type: SkillType.Active,
    recovery: 40000,
    duration: 5000,
    cost: 500,
  },
  [SkillVariant.Sacrifice]: {
    type: SkillType.Active,
    recovery: 30000,
    cost: 500,
  },
};
