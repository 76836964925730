import { MeshLambertMaterial } from 'three';

import { Assets } from '~/client/core/assets';
import { MaterialType } from '~/client/core/assets/materials/types';

import textureDecoration from './decoration.png';

Assets.addMaterial(MaterialType.Decoration, new MeshLambertMaterial({
  map: Assets.createTexture(textureDecoration),
  reflectivity: 0,
}));
