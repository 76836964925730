import { PlayerMessage } from './types';

/**
 * Misc
 */

export const PLAYER_VISIBLE_DISTANCE = 6;

export const PLAYER_FORCE_MESSAGES = [
  PlayerMessage.Upgrade,
  PlayerMessage.ChangeMovingVector,
];
