import { Assets } from '~/client/core/assets';
import { AudioMode, AudioType } from '~/client/core/audio/types';

import audioAttack from './attack.mp3';
import audioHit1 from './hit1.mp3';
import audioHit2 from './hit2.mp3';
import audioLevelUp from './level-up.mp3';
import audioStep from './step.mp3';
import audioUpgrade from './upgrade.mp3';
import audioUseSkill from './use-skill.mp3';

Assets.addAudio(AudioType.PlayerAttack, {
  path: audioAttack,
  mode: AudioMode.Track3D,
  volume: 1.0,
  limit: 2,
});

Assets.addAudio(AudioType.PlayerHit, {
  path: [audioHit1, audioHit2],
  mode: AudioMode.Track3D,
  volume: 0.6,
  limit: 1,
});

Assets.addAudio(AudioType.PlayerStep, {
  path: audioStep,
  mode: AudioMode.Track3D,
  volume: 0.5,
  limit: 2,
});

Assets.addAudio(AudioType.PlayerLevelUp, {
  path: audioLevelUp,
  mode: AudioMode.Track2D,
  volume: 1.0,
});

Assets.addAudio(AudioType.PlayerUseSkill, {
  path: audioUseSkill,
  mode: AudioMode.Track2D,
  volume: 1.0,
});

Assets.addAudio(AudioType.PlayerUpgrade, {
  path: audioUpgrade,
  mode: AudioMode.Track2D,
  volume: 1.0,
});
