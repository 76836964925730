export enum UpgradeVariant {
  AttackDamage = 'AttackDamage',
  AttackRecovery = 'AttackRecovery',
  AttackRadius = 'AttackRadius',
  MainMaxHealth = 'MainMaxHealth',
  MainSpeed = 'MainSpeed',
  MainDroid = 'MainDroid',
  BuildSpeed = 'BuildSpeed',
  BuildRadius = 'BuildRadius',
}

export enum UpgradeCategory {
  Main = 'Main',
  Attack = 'Attack',
  Build = 'Build',
}
