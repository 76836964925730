import { PlayerDirection, PlayerMovementKey } from './types';

export const PLAYER_ATTACK_KEY = 'Space';

export const PLAYER_STEP_DELAY = 500;

export const PLAYER_MOVEMENT_DIRECTIONS: Record<PlayerMovementKey, PlayerDirection> = {
  [PlayerMovementKey.KeyW]: PlayerDirection.Up,
  [PlayerMovementKey.ArrowUp]: PlayerDirection.Up,
  [PlayerMovementKey.KeyS]: PlayerDirection.Down,
  [PlayerMovementKey.ArrowDown]: PlayerDirection.Down,
  [PlayerMovementKey.KeyA]: PlayerDirection.Left,
  [PlayerMovementKey.ArrowLeft]: PlayerDirection.Left,
  [PlayerMovementKey.KeyD]: PlayerDirection.Right,
  [PlayerMovementKey.ArrowRight]: PlayerDirection.Right,
};
