import type { BuildingMessagePayload } from '../../types';

/**
 * Messages
 */

export enum BuildingTrapMessages {
  Attack = 'BuildingTrap:Attack',
}

export type BuildingTrapMessagePayload = BuildingMessagePayload & {
  [BuildingTrapMessages.Attack]: void;
};
