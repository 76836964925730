export enum MaterialType {
  Personal = 'Personal',
  Self = 'Self',
  Opponent = 'Opponent',

  IndicatorSelf = 'IndicatorSelf',
  IndicatorOpponent = 'IndicatorOpponent',

  MarkerSelf = 'MarkerSelf',
  MarkerOpponent = 'MarkerOpponent',

  Building = 'Building',
  BuildingInvalid = 'BuildingInvalid',

  Unit = 'Unit',
  UnitTransparent = 'UnitTransparent',
  UnitFrost = 'UnitFrost',

  Fog = 'Fog',

  Decoration = 'Decoration',

  Crystal = 'Crystal',
  IndicatorCrystal = 'IndicatorCrystal',

  Dust0 = 'Dust0',
  Dust1 = 'Dust1',
  Dust2 = 'Dust2',
  Dust3 = 'Dust3',

  AttackAreaDefault = 'AttackAreaDefault',
  AttackAreaFrost = 'AttackAreaFrost',

  ActionArea = 'ActionArea',
  BuildArea = 'BuildArea',

  LaserLine = 'LaserLine',
  RepairLine = 'RepairLine',

  BulletSimple = 'BulletSimple',
  BulletFire = 'BulletFire',
  BulletFrost = 'BulletFrost',

  Electro = 'Electro',

  Liquid0 = 'Liquid0',
  Liquid1 = 'Liquid1',
  Liquid2 = 'Liquid2',
  Liquid3 = 'Liquid3',
  Coast0 = 'Coast0',
  Coast1 = 'Coast1',
  Coast2 = 'Coast2',
  Fields0 = 'Fields0',
  Fields1 = 'Fields1',
  Fields2 = 'Fields2',
  Mounts0 = 'Mounts0',
  Mounts1 = 'Mounts1',
  Mounts2 = 'Mounts2',
}
