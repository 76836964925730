import cn from 'classnames';
import React, { useCallback } from 'react';

import { useBuilding } from '../../../../hooks/use-building';

import type { Battle } from '~/client/battle';
import type { BuildingMessage } from '~/shared/battle/entity/building/types';

import { Cost } from '~/client/battle/ui/components/cost';
import { useSelfPlayerSchema } from '~/client/battle/ui/hooks/use-self-player-schema';
import { Device } from '~/client/core/device';
import { KeyboardKey } from '~/client/core/input/keyboard/ui/components/key';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Icon } from '~/client/core/ui/components/icon';
import { Interactive } from '~/client/core/ui/components/interactive';
import { Section } from '~/client/core/ui/components/section';
import { BuildingUtils } from '~/shared/battle/entity/building/utils';
import { DeviceType } from '~/shared/core/device/types';

import styles from './styles.module.scss';

import IconLock from './icons/lock.svg';

type Props = {
  icon: SVGIcon;
  message: BuildingMessage;
  hotkey?: string;
  cost?: number;
  minLevel?: number;
  disabled?: boolean;
  blink?: boolean;
};

export const Control: React.FC<Props> = ({
  icon, message, hotkey, cost, disabled, minLevel, blink,
}) => {
  const battle = useRoom<Battle>();
  const building = useBuilding();
  const player = useSelfPlayerSchema();

  const hasLevel = (
    !minLevel ||
    BuildingUtils.hasLevelToUpgrade(building.schema, battle.state, player)
  );

  const handleClick = useCallback(() => {
    if (!building.disposed) {
      building.doAction(message);
    }
  }, []);

  return (
    <Interactive
      onClick={handleClick}
      disabled={disabled || !hasLevel}
      className={cn(styles.container, {
        [styles.disabled]: disabled || !hasLevel,
        [styles.blink]: blink,
      })}
    >
      {disabled ? (
        <Icon type={icon} className={styles.icon} />
      ) : (
        <Section direction='vertical' align='center' gap={12}>
          {hasLevel ? (
            <>
              <Icon type={icon} className={styles.icon} />
              {!!cost && (
                <div className={styles.addon}>
                  <Cost amount={cost} />
                </div>
              )}
            </>
          ) : (
            <>
              <IconLock />
              <div className={styles.minLevel}>
                <b>{minLevel}</b> LVL
              </div>
            </>
          )}
        </Section>
      )}
      {Device.type === DeviceType.Desktop && hotkey && (
        <div className={styles.hotkey}>
          <KeyboardKey size='s'>{hotkey.replace('Key', '')}</KeyboardKey>
        </div>
      )}
    </Interactive>
  );
};
