import { BoxGeometry } from 'three';

import { Shape } from '..';

import type { CubeConfig } from './types';
import type { Scene } from '../../../scene';

// TODO: Reduce faces
const _CubeGeometry = new BoxGeometry();

export class Cube extends Shape {
  constructor(scene: Scene, config: CubeConfig) {
    super(scene, {
      ...config,
      geometry: _CubeGeometry,
    });
  }
}
