import { useRef } from 'react';

export function useBatch(callback: VoidFunction) {
  const refBatch = useRef(false);

  return () => {
    if (refBatch.current) {
      return;
    }

    refBatch.current = true;
    setTimeout(() => {
      refBatch.current = false;
      callback();
    });
  };
}
