import { Mob } from '../..';

import type { Battle } from '~/client/battle';
import type { MobSchema } from '~/shared/battle/entity/unit/npc/mob/types';

import { ModelType } from '~/client/core/assets/types';

import IconHeal from './icons/heal.svg';

import './resources';

export class MobDemon extends Mob {
  constructor(battle: Battle, schema: MobSchema) {
    super(battle, {
      model: ModelType.MobDemon,
    }, schema);

    this.listenSchemaHealth();
  }

  private listenSchemaHealth() {
    let prevHealth = this.schema.live.health;

    this.schema.live.listen('health', (health) => {
      if (health > prevHealth) {
        this.setStateIcon(IconHeal);
        this.timeouts.add(() => {
          this.removeStateIcon();
        }, 500);
      }

      prevHealth = health;
    });
  }
}

