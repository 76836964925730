import type { BattleMode } from '../../types';
import type { EntityMessagePayload, EntitySchema } from '../types';

export interface BuildingSchema extends EntitySchema {
  readonly variant: BuildingVariant;
  readonly radius: Nullable<number>;
  readonly level: number;
  readonly buildProgress: number;
  readonly maxLevel: number;
}

export enum BuildingVariant {
  Base = 'Base',
  Wall = 'Wall',
  Generator = 'Generator',
  SpawnerSmall = 'SpawnerSmall',
  SpawnerMedium = 'SpawnerMedium',
  SpawnerLarge = 'SpawnerLarge',
  TowerFire = 'TowerFire',
  TowerFrost = 'TowerFrost',
  TowerLaser = 'TowerLaser',
  TowerElectro = 'TowerElectro',
  Ammunition = 'Ammunition',
  Trap = 'Trap',
  Radar = 'Radar',
}

export enum BuildingCategory {
  Resources = 'Resources',
  Spawners = 'Spawners',
  Towers = 'Towers',
  Defense = 'Defense',
}

export type BuildingSharedConfig = {
  category?: BuildingCategory;
  cost: number;
  restricted?: boolean;
  mode?: BattleMode;
  limit?: number;
  upgradeStep: number;
  minLevel: Record<BattleMode, number>;
};

/**
 * Messages
 */

export enum BuildingMessage {
  DoRepair = 'Building:DoRepair',
  DoUpgrade = 'Building:DoUpgrade',
  DoBreak = 'Building:DoBreak',
  Upgrade = 'Building:Upgrade',
  Boost = 'Building:Boost',
  Unboost = 'Building:Unboost',
}

export type BuildingMessagePayload = EntityMessagePayload & {
  [BuildingMessage.DoRepair]: void;
  [BuildingMessage.DoUpgrade]: void;
  [BuildingMessage.DoBreak]: void;
  [BuildingMessage.Upgrade]: void;
  [BuildingMessage.Boost]: void;
  [BuildingMessage.Unboost]: void;
};
