import type { InputTouchChannel } from './channel';
import type { Vector2 } from 'three';
import type { EventStream } from '~/shared/core/event-stream';

export enum InputTouchSwipeDirection {
  Left = 'Left',
  Right = 'Right',
  Up = 'Up',
  Down = 'Down',
}

export type TouchSwipeEvent = {
  position: Vector2
  direction: InputTouchSwipeDirection
};

export type InputTouchEvents = {
  onTouch: EventStream<InputTouchChannel>
  onTouchWorld: EventStream<InputTouchChannel>
  onTouchSwipe: EventStream<TouchSwipeEvent>
};

