import { AttackArea } from './attack-area';
import { UNIT_ROTATE_LERP } from './const';
import { Indicator } from './indicator';
import { Entity } from '..';
import { SceneLayer } from '../../scene/types';

import type { UnitConfig } from './types';
import type { Battle } from '../..';
import type { Vector3Like } from 'three';
import type { Messages } from '~/client/core/messages';
import type { AttackAreaParams } from '~/shared/battle/entity/unit/attack-area/types';
import type { UnitMessagePayload, UnitSchema } from '~/shared/battle/entity/unit/types';

import { MaterialType } from '~/client/core/assets/materials/types';
import { Logger } from '~/client/core/logger';
import { Model } from '~/client/core/render-item/model';
import { UnitMessage } from '~/shared/battle/entity/unit/types';
import { VectorUtils } from '~/shared/core/vector-utils';

import './resources';

export abstract class Unit extends Entity {
  declare public readonly schema: UnitSchema;

  declare protected readonly messages: Messages<UnitMessagePayload>;

  declare public readonly renderItem: Model;

  private indicator: Nullable<Indicator> = null;

  private attackArea: Nullable<AttackArea> = null;

  constructor(battle: Battle, config: UnitConfig, schema: UnitSchema) {
    const renderItem = new Model(battle.scene, {
      ...config,
      material: MaterialType.Unit,
    });
    renderItem.eachMeshes((mesh) => {
      mesh.layers.set(SceneLayer.Unit);
    });

    super(battle, renderItem, schema);

    this.handleMessages();
  }

  override destroy(): void {
    this.removeIndicator();
    this.removeAttackArea();

    super.destroy();
  }

  override onSceneUpdate(): void {
    super.onSceneUpdate();

    this.rotateToVelocity();
  }

  private createAttackArea() {
    if (this.attackArea) {
      Logger.warn('Unit attack area is already created');
      return;
    }

    this.attackArea = new AttackArea(this);
  }

  private removeAttackArea() {
    if (!this.attackArea) {
      return;
    }

    this.attackArea.destroy();
    this.attackArea = null;
  }

  protected createIndicator() {
    if (this.indicator) {
      Logger.warn('Unit indicator is already created');
      return;
    }

    this.indicator = new Indicator(this);
  }

  protected removeIndicator() {
    if (!this.indicator) {
      return;
    }

    this.indicator.destroy();
    this.indicator = null;
  }

  protected rotate(angle: number) {
    this.renderItem.rotate(angle, UNIT_ROTATE_LERP);
  }

  protected rotateToVelocity() {
    const velocity = VectorUtils.reuse(this.velocity).normalize();
    if (velocity.length() > 0) {
      const angle = Math.atan2(velocity.x, velocity.z);
      this.rotate(angle);
    }
  }

  protected rotateToPosition(position: Vector3Like) {
    const vector = VectorUtils.reuse(position).sub(this.renderItem.position);
    const angle = Math.atan2(vector.x, vector.z);
    this.rotate(angle);
  }

  protected handleMessages() {
    this.messages.on(UnitMessage.DisplayAttackArea, (payload) => {
      this.onDisplayAttackArea(payload);
    });
  }

  protected onDisplayAttackArea(params: AttackAreaParams) {
    if (!this.attackArea) {
      this.createAttackArea();
    }

    this.attackArea?.display(params);
  }
}

