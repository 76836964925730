import type { TimeSource } from '../types';

export class Timeouts {
  private readonly time: TimeSource;

  private readonly callbacks: Map<VoidFunction, number> = new Map();

  constructor(time?: TimeSource) {
    this.time = time ?? {
      get: () => performance.now(),
    };
  }

  public add(callback: VoidFunction, duration: number) {
    const targetTime = this.time.get() + duration;
    this.callbacks.set(callback, targetTime);
  }

  public remove(callback: VoidFunction) {
    this.callbacks.delete(callback);
  }

  public clear() {
    this.callbacks.clear();
  }

  public update() {
    this.callbacks.forEach((targetTime, callback) => {
      if (this.time.get() >= targetTime) {
        callback();
        this.callbacks.delete(callback);
      }
    });
  }
}
