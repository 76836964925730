import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';

import { useBuilding } from '../../../hooks/use-building';

import { Amount } from '~/client/core/ui/components/amount';

import styles from './styles.module.scss';

import IconHealth from './icons/health.svg';

export const HealthAmount: React.FC = () => {
  const building = useBuilding();

  const health = useSchemaValue(building.schema.live, 'health');

  return (
    <div className={styles.container}>
      <Amount icon={IconHealth} size='s' value={health} color='#ff6c6a' />
    </div>
  );
};
