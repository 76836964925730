import { BuildingSpawner } from '..';

import type { Battle } from '~/client/battle';
import type { BuildingSchema } from '~/shared/battle/entity/building/types';

import { ModelType } from '~/client/core/assets/types';

import './resources';

export class BuildingSpawnerLarge extends BuildingSpawner {
  constructor(battle: Battle, schema: BuildingSchema) {
    super(battle, {
      model: ModelType.BuildingSpawnerLarge,
    }, schema);
  }
}
