import { OrthographicCamera, Vector3, WebGLRenderer } from 'three';

import { MINIMAP_FRUSTUM, MINIMAP_REPAINT_BIOMES, MINIMAP_UPDATE_RATE } from './const';
import { SceneLayer } from '../scene/types';
import { TILE_COLORS } from '../terrain/tile/const';

import type { MinimapEvents } from './types';
import type { Battle } from '..';
import type { MeshBasicMaterial } from 'three';
import type { MaterialType } from '~/client/core/assets/materials/types';

import { Assets } from '~/client/core/assets';
import { TERRAIN_SIZE } from '~/shared/battle/terrain/const';
import { EventStream } from '~/shared/core/event-stream';

export class Minimap {
  private camera: OrthographicCamera;

  private renderer: Nullable<WebGLRenderer> = null;

  private updateTick: number = 0;

  private battle: Battle;

  public readonly events: MinimapEvents = {
    onUpdate: new EventStream(),
  };

  private readonly biomeMaterials: {
    material: MeshBasicMaterial
    color: number
    originColor: number
  }[] = [];

  constructor(battle: Battle) {
    this.battle = battle;

    this.camera = new OrthographicCamera(
      -MINIMAP_FRUSTUM,
      MINIMAP_FRUSTUM,
      MINIMAP_FRUSTUM,
      -MINIMAP_FRUSTUM,
      1, 1000,
    );

    this.camera.layers.enable(SceneLayer.Ground);
    this.camera.layers.enable(SceneLayer.Marker);
    this.camera.layers.enable(SceneLayer.Fog);

    this.camera.position.set(TERRAIN_SIZE.x / 2, 50, TERRAIN_SIZE.z / 2);
    this.camera.lookAt(new Vector3(TERRAIN_SIZE.x / 2, 0, TERRAIN_SIZE.z / 2));

    this.createBiomeMaterials();

    this.onSceneRender = this.onSceneRender.bind(this);
  }

  public enable(canvas: HTMLCanvasElement) {
    this.renderer = new WebGLRenderer({
      canvas,
      antialias: false,
    });
    this.renderer.setSize(200, 200);

    this.render();
    this.battle.scene.events.onRender.on(this.onSceneRender);
  }

  public disable() {
    if (this.renderer) {
      this.renderer.dispose();
      this.renderer = null;
    }

    this.battle.scene.events.onRender.off(this.onSceneRender);
  }

  private onSceneRender() {
    this.update();
  }

  private update() {
    this.updateTick += this.battle.scene.deltaTime;
    if (this.updateTick >= MINIMAP_UPDATE_RATE) {
      this.events.onUpdate.invoke();
      this.render();
      this.updateTick %= MINIMAP_UPDATE_RATE;
    }
  }

  private render() {
    this.biomeMaterials.forEach(({ material, color }) => {
      material.color.setHex(color);
    });

    this.renderer?.render(this.battle.scene, this.camera);

    this.biomeMaterials.forEach(({ material, originColor }) => {
      material.color.setHex(originColor);
    });
  }

  private createBiomeMaterials() {
    MINIMAP_REPAINT_BIOMES.forEach((biome) => {
      TILE_COLORS[biome].forEach((color, i) => {
        this.biomeMaterials.push({
          material: Assets.getMaterial(`${biome}${i}` as MaterialType),
          color: TILE_COLORS[biome][0],
          originColor: color,
        });
      });
    });
  }
}
