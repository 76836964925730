import type React from 'react';

import { UpgradeCategory } from '~/shared/battle/entity/unit/player/upgrades/types';

import IconAttack from './icons/attack.svg';
import IconBuild from './icons/build.svg';
import IconMain from './icons/main.svg';

export const CATEGORY_ICON: Record<UpgradeCategory, React.FC> = {
  [UpgradeCategory.Main]: IconMain,
  [UpgradeCategory.Attack]: IconAttack,
  [UpgradeCategory.Build]: IconBuild,
};
