import type { EventStreamSubscribe } from './types';

export class EventStream<
  Arguments = void,
  Handler extends (args: Arguments) => void = (args: Arguments) => void
> {
  public readonly handlers: Set<Handler> = new Set<Handler>();

  public on(handler: Handler): EventStreamSubscribe {
    this.handlers.add(handler);

    return {
      off: () => {
        this.off(handler);
      },
    };
  }

  public off(handler: Handler): void {
    this.handlers.delete(handler);
  }

  public invoke(args: Arguments): void {
    this.handlers.forEach((handler) => {
      handler(args);
    });
  }

  public clear(): void {
    this.handlers.clear();
  }
}
