import { Client as OriginClient } from 'colyseus.js';

import { Device } from '../device';
import { Storage } from '../storage';

import type { Room } from '../room';
import type { Schema } from '@colyseus/schema';
import type { Room as OriginRoom } from 'colyseus.js';
import type { UserConfig } from '~/shared/core/user/types';

import { HOST } from '~/client/const';
import { RoomType } from '~/shared/core/room/types';

export class Client {
  private static client: OriginClient;

  private static room: Nullable<OriginRoom> = null;

  private static definedRooms: Map<RoomType, typeof Room<any>> = new Map();

  public static get sessionId() {
    if (!this.room) {
      throw Error('Undefined current room');
    }

    return this.room.sessionId;
  }

  public static connect() {
    const host = __MODE === 'production'
      ? `wss://${HOST}/`
      : `${location.protocol === 'https:' ? 'wss:' : 'ws:'}//${location.host}/`;

    this.client = new OriginClient(host);
  }

  public static defineRoom<T extends Schema>(type: RoomType, room: typeof Room<T>) {
    this.definedRooms.set(type, room);
  }

  private static getDefinedRoom(type: RoomType) {
    const RoomInstance = this.definedRooms.get(type);
    if (!RoomInstance) {
      throw Error(`Undefined room type ${type}`);
    }

    return RoomInstance;
  }

  private static async leaveCurrentRoom() {
    Storage.remove('Screen');

    if (!this.room || !this.room.connection.isOpen) {
      return;
    }

    const PrevRoomInstance = this.getDefinedRoom(this.room.name as RoomType);
    if (PrevRoomInstance.allowReconnection) {
      this.removeReconnectionToken();
    }

    await this.room.leave(true);
  }

  private static setRoom(origin: OriginRoom) {
    const RoomInstance = this.getDefinedRoom(origin.name as RoomType);
    if (RoomInstance.allowReconnection) {
      this.setReconnectionToken(origin.reconnectionToken);
    }

    this.room = origin;

    return new RoomInstance(origin);
  }

  public static async findAndJoinRoom() {
    const lastRoomid = this.getLastRoomId();
    if (lastRoomid) {
      this.removeLastRoomId();

      const joined = await this.joinRoomById(lastRoomid);
      if (joined) {
        return;
      }
    }

    const token = this.getReconnectionToken();
    if (token) {
      const joined = await this.restoreRoom(token);
      if (joined) {
        return;
      }
    }

    await this.joinRoomByType(RoomType.Relay);
  }

  private static async restoreRoom(token: string) {
    try {
      this.setLoading();
      const room = await this.client.reconnect(token);
      this.setRoom(room);

      return true;
    } catch {
      return false;
    }
  }

  public static async joinRoomByType(type: RoomType) {
    this.setLoading();
    await this.leaveCurrentRoom();
    const room = await this.client.joinOrCreate(type, this.getJoinPayload());
    this.setRoom(room);
  }

  public static async joinRoomById(id: string) {
    try {
      this.setLoading();
      await this.leaveCurrentRoom();
      const room = await this.client.joinById(id, this.getJoinPayload());
      this.setRoom(room);

      return true;
    } catch {
      return false;
    }
  }

  private static getJoinPayload(): UserConfig {
    return {
      device: Device.type,
      token: this.getAuthToken(),
      newbie: this.isNewbie(),
    };
  }

  private static getAuthToken() {
    return Storage.get('AuthToken');
  }

  public static setAuthToken(token: string) {
    Storage.set('AuthToken', token);
  }

  public static removeAuthToken() {
    Storage.remove('AuthToken');
  }

  private static getReconnectionToken() {
    return Storage.get('ReconnectionToken');
  }

  private static setReconnectionToken(token: string) {
    Storage.set('ReconnectionToken', token);
  }

  private static removeReconnectionToken() {
    Storage.remove('ReconnectionToken');
  }

  private static getLastRoomId() {
    return Storage.get('LastRoomId');
  }

  public static setLastRoomId(token: string) {
    Storage.set('LastRoomId', token);
  }

  public static removeLastRoomId() {
    Storage.remove('LastRoomId');
  }

  public static isNewbie() {
    return Storage.get('Newbie') !== 'No';
  }

  public static unmarkNewbie() {
    Storage.set('Newbie', 'No');
  }

  public static hideLoading() {
    const screen = document.getElementById('game-loading');
    if (!screen) {
      return;
    }

    screen.classList.add('hidding');
    setTimeout(() => screen.classList.add('hidden'), 1000);
  }

  public static setLoading(state: string = 'Loading') {
    const screen = document.getElementById('game-loading');
    if (!screen) {
      return;
    }

    screen.classList.remove('hidding', 'hidden');

    const status = screen.querySelector('.status');
    if (status) {
      status.innerHTML = state;
    }
  }
}
