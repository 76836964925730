import { Building } from '../..';

import type { Battle } from '~/client/battle';
import type { BuildingSchema } from '~/shared/battle/entity/building/types';

import { ModelType } from '~/client/core/assets/types';

import './resources';

export class BuildingGenerator extends Building {
  constructor(battle: Battle, schema: BuildingSchema) {
    super(battle, {
      model: ModelType.BuildingGenerator,
    }, schema);
  }

  override onReady(): void {
    super.onReady();

    this.renderItem.animator.play('spin1', { timeScale: 0.5 });
    this.renderItem.animator.play('spin2', { timeScale: 0.5 });
  }
}
