import { OverlayAuth } from './auth';
import { OverlaySettings } from './settings';
import { OverlayType } from './types';

export const OVERLAY_CLOSE_KEY = 'Escape';

export const OVERLAYS: Record<OverlayType, React.FC> = {
  [OverlayType.Settings]: OverlaySettings,
  [OverlayType.Auth]: OverlayAuth,
};
