import { Vector3 } from 'three';

import type { Vector3Like } from 'three';

export class UnitUtils {
  public static getLookUpPosition(position: Vector3Like, velocity: Vector3Like, size: Vector3Like) {
    const radius = size.z / 2;
    return new Vector3()
      .copy(velocity)
      .normalize()
      .multiplyScalar(radius)
      .add(position);
  }
}
