import React, { useState } from 'react';

import { Icon } from '../icon';

import styles from './styles.module.scss';

type Props = {
  label: string;
  icon: SVGIcon;
  type?: string;
  maxLength?: number;
  onChange: (value: string) => void;
  validate?: (value: string) => boolean;
};

export const Input: React.FC<Props> = ({ label, icon, validate, onChange, maxLength, type = 'text' }) => {
  const [value, setValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (!validate || validate(newValue)) {
      setValue(newValue);
      onChange(newValue);
    }
  };

  return (
    <div className={styles.input}>
      <input
        type={type}
        placeholder={label}
        onChange={handleChange}
        value={value}
        maxLength={maxLength}
      />
      <Icon type={icon} className={styles.icon} />
    </div>
  );
};
