import type { Building } from '..';

import { MaterialType } from '~/client/core/assets/materials/types';
import { Circle } from '~/client/core/render-item/shape/circle';

import './resources';

export class ActionArea extends Circle {
  constructor(building: Building, radius: number) {
    super(building.battle.scene, {
      material: MaterialType.ActionArea,
      radius,
      visible: false,
    });

    building.renderItem.object.add(this.object);
  }
}
