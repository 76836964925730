import { ErrorCode } from 'colyseus.js';

import { ROOM_RECONNECTION_INTERVAL } from './const';
import { Logger } from '../logger';
import { Request } from '../request';
import { Interface } from '../ui';
import { SystemMessageUtils } from '../ui/components/system-message/utils';
import { SystemMessageType } from '../ui/components/system-message/utils/types';

import type { RequestPromise } from './types';
import type { Scene } from '../scene';
import type { Schema } from '@colyseus/schema';
import type { Room as OriginRoom } from 'colyseus.js';

import { Client } from '~/client/core/client';
import { ROOM_PING_ROUTE_PATH } from '~/shared/core/room/const';
import { ServerShutdownCode } from '~/shared/core/server/types';
import { Utils } from '~/shared/core/utils';

export class Room<T extends Schema = Schema> {
  public static allowReconnection: boolean = false;

  public readonly origin: OriginRoom<T>;

  public get state() { return this.origin.state; }

  public get id() { return this.origin.roomId; }

  public get sessionId() { return this.origin.sessionId; }

  public scene: Scene;

  private requests: Map<string, RequestPromise> = new Map();

  private shutdown: boolean = false;

  constructor(room: OriginRoom<T>) {
    this.origin = room;

    this.origin.onMessage('*', (type: string, { requestId, error, response }: any = {}) => {
      const promise = requestId && this.requests.get(requestId);
      if (!promise) {
        return;
      }

      this.requests.delete(requestId);

      if (error) {
        promise.reject(new Error(error));
      } else {
        promise.resolve(response);
      }
    });

    this.origin.onError((code, message) => {
      const shutdownCodes = Object.values(ServerShutdownCode);
      if (shutdownCodes.includes(code)) {
        this.shutdown = true;
      } else if (code === ErrorCode.APPLICATION_ERROR) {
        Logger.renderError(message);
      }
    });

    this.origin.onLeave(async (code) => {
      this.destroy();

      const consented = code === 4000;
      const connected = await this.checkConnection(!consented);
      if (!connected) {
        Client.setLastRoomId(this.origin.roomId);
        this.tryReconnect();
      }
    });
  }

  protected destroy(): void {
    Interface.unmount();
  }

  private async checkConnection(join: boolean = true) {
    try {
      await Request.get(ROOM_PING_ROUTE_PATH);
      if (this.shutdown) {
        location.reload();
      } else if (join) {
        Client.connect();
        await Client.findAndJoinRoom();
      }
      return true;
    } catch {
      return false;
    }
  }

  private async tryReconnect() {
    const error = this.shutdown
      ? SystemMessageUtils.render('restarting', {
        type: SystemMessageType.Info,
        sign: '🔄',
        title: 'Restarting',
        message: [
          'Game server is restarting',
          'Please, wait...',
        ],
      })
      : SystemMessageUtils.render('connection-lost', {
        type: SystemMessageType.Error,
        sign: '📡',
        title: 'Connection Lost',
        message: 'Trying to reconnect...',
      });

    const interval = setInterval(async () => {
      const connected = await this.checkConnection();
      if (connected) {
        clearInterval(interval);
        error.remove();
      }
    }, ROOM_RECONNECTION_INTERVAL);
  }

  public sendRequest<T = any>(type: string, payload?: any): Promise<T> {
    if (!this.origin.connection.isOpen) {
      // if (__MODE === 'development') {
      //   Logger.warn(`Unable to send request '${type}' from disconnected room`);
      // }
      // @ts-ignore
      return Promise.resolve();
    }

    const requestId = Utils.uuid();
    this.origin.send(type, { payload, requestId });

    return new Promise((resolve, reject) => {
      this.requests.set(requestId, { resolve, reject });
    });
  }

  public onStateChange(callback: VoidFunction) {
    const listener = this.origin.onStateChange(callback);

    return {
      unsubscribe: () => {
        listener.clear();
      },
    };
  }
}
