import { Vector3, BufferGeometry } from 'three';
// @ts-ignore
import { MeshLine } from 'three.meshline';

import { Shape } from '..';

import type { LineConfig } from './types';
import type { Scene } from '../../../scene';
import type { Vector3Like } from 'three';

import { VectorUtils } from '~/shared/core/vector-utils';

export class Line extends Shape {
  public readonly positionEnd: Vector3 = new Vector3();

  private readonly geometry: Nullable<BufferGeometry> = null;

  private readonly line: Nullable<MeshLine> = null;

  constructor(scene: Scene, {
    position,
    positionEnd,
    material,
    ...config
  }: LineConfig) {
    const buffer = new BufferGeometry()
      .setFromPoints([]);

    const line = new MeshLine();
    line.setGeometry(buffer);

    super(scene, {
      ...config,
      material,
      position,
      geometry: line,
    });

    this.line = line;
    this.geometry = buffer;

    this.positionEnd.copy(positionEnd);

    this.updateGeometry();
  }

  override destroy(): void {
    this.geometry?.dispose();
    this.line.dispose();

    super.destroy();
  }

  override setPosition(position: Vector3Like) {
    super.setPosition(position);
    this.updateGeometry();
  }

  public setPositionEnd(position: Vector3Like) {
    this.positionEnd.copy(position);
    this.updateGeometry();
  }

  private updateGeometry() {
    if (!this.geometry || !this.line) {
      return;
    }

    this.geometry.setFromPoints([
      VectorUtils.zeroVector3,
      this.positionEnd.sub(this.object.position),
    ]);
    this.line.setGeometry(this.geometry);
  }
}
