import React from 'react';

import type { RenderItem } from '~/client/core/render-item';

import { Translator } from '~/client/battle/ui/components/translator';
import { Section } from '~/client/core/ui/components/section';

import styles from './styles.module.scss';

type Props = {
  renderItem: RenderItem;
  indicators?: React.ReactNode;
  meta?: React.ReactNode;
  bars?: React.ReactNode;
  children?: React.ReactNode;
};

export const EntityPanel: React.FC<Props> = ({
  renderItem,
  indicators,
  meta,
  bars,
  children,
}) => (
  <Translator
    renderItem={renderItem}
    body={children}
    head={
      <Section direction='vertical' align='center' gap={4}>
        {indicators && <div className={styles.indicators}>{indicators}</div>}
        {meta && <div className={styles.meta}>{meta}</div>}
        {bars && <div className={styles.bars}>{bars}</div>}
      </Section>
    }
  />
);
