import { Assets } from '~/client/core/assets';
import { AudioMode, AudioType } from '~/client/core/audio/types';

import audioPickup from './pickup.mp3';

Assets.addAudio(AudioType.Pickup, {
  path: audioPickup,
  mode: AudioMode.Track2D,
  volume: 0.8,
});
