import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';

import { usePlayer } from '../../hooks/use-player';

import styles from './styles.module.scss';

export const LevelLabel: React.FC = () => {
  const player = usePlayer();

  const level = useSchemaValue(player.schema, 'level');

  return (
    <div className={styles.value}>{level}</div>
  );
};
