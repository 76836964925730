import React from 'react';

import pkg from '../../../../../../package.json';

import styles from './styles.module.scss';

export const VersionTag: React.FC = () => {
  const [, tag] = pkg.version.split('-');

  return tag && <span className={styles.tag}>{tag}</span>;
};
