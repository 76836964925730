import { useContext } from 'react';

import { DroidContext } from '../context';

export function useDroid() {
  const droid = useContext(DroidContext);

  if (!droid) {
    throw Error('Undefined droid context');
  }

  return droid;
}
