import React from 'react';

import { LoginButton } from './login-button';
import { UserInfo } from './user-info';
import { useUserData } from '../../hooks/use-user-data';
import { ButtonAds } from '../button-ads';

import { Section } from '~/client/core/ui/components/section';

export const UserPanel: React.FC = () => {
  const authorized = useUserData('authorized');

  return authorized ? (
    <Section direction='vertical' gap={8}>
      <ButtonAds />
      <UserInfo />
    </Section>
  ) : <LoginButton />;
};
