import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';
import { useRecoilValue } from 'recoil';

import type { Battle } from '~/client/battle';

import { CurrentTutorialStepState } from '~/client/battle/tutorial/ui/state';
import { Card } from '~/client/battle/ui/components/card';
import { Cost } from '~/client/battle/ui/components/cost';
import { useSelfPlayerSchema } from '~/client/battle/ui/hooks/use-self-player-schema';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';
import { BUILDER_FLOW } from '~/shared/battle/builder/const';
import { BUILDINGS_SHARED_CONFIG } from '~/shared/battle/entity/building/const';
import { BuildingVariant } from '~/shared/battle/entity/building/types';
import { SkillVariant } from '~/shared/battle/entity/unit/player/skill/types';

import styles from './styles.module.scss';

import IconLock from './icons/lock.svg';

type Props = {
  variant: BuildingVariant;
  onSelect: VoidFunction;
};

export const Variant: React.FC<Props> = ({ variant, onSelect }) => {
  const { builder, scene, state } = useRoom<Battle>();
  const player = useSelfPlayerSchema();
  const language = useLanguage();

  const resources = useSchemaValue(player, 'resources');
  const builderFlowIndex = useSchemaValue(player, 'builderFlowIndex');

  let cost = BUILDINGS_SHARED_CONFIG[variant].cost;

  if (variant === BuildingVariant.Wall) {
    const skill = player.skills.get(SkillVariant.DiscountWalls);
    if (skill) {
      cost *= skill.multiplier;
    }
  }

  const targetVariant = BUILDER_FLOW[state.mode][builderFlowIndex];

  const currentTutorialStep = useRecoilValue(CurrentTutorialStepState);
  const blink = (
    currentTutorialStep?.indexOf('Build') === 0 &&
    currentTutorialStep?.includes(variant)
  );

  const limitExceeded = builder.isLimitExceeded(variant, player);
  const restrictionLevel = builder.getRestrictionLevel(variant, player);
  const restricted = builder.isRestricted(variant, player);

  const avaialble = !limitExceeded && !restricted;
  const hasResources = resources >= cost;
  const locked = (
    !avaialble ||
    Boolean(targetVariant && targetVariant !== variant) || (
      currentTutorialStep?.indexOf('Build') === 0 &&
      !currentTutorialStep?.includes(variant)
    )
  );

  const handleClickVariant = () => {
    builder.setVariant(variant);
    onSelect();
  };

  return (
    <Card
      onClick={handleClickVariant}
      disabled={locked || !hasResources}
      locked={locked}
      alarm={!locked && !hasResources}
      blink={blink}
    >
      <Card.Graphic>
        {avaialble ? (
          <img src={scene.snaps.get(variant)} />
        ) : (
          <>
            <IconLock />
            {limitExceeded ? (
              <Text size='xs'>MAX</Text>
            ) : (
              <Section direction='horizontal' align='center' gap={4}>
                <Text size='xs' bold>{restrictionLevel}</Text>
                <Text size='xs'>LVL</Text>
              </Section>
            )}
          </>
        )}
      </Card.Graphic>
      <Card.Content>
        <Card.Title>{language(`${variant}Name`)}</Card.Title>
        <div className={styles.cost}>
          <Cost amount={cost} check={!locked} />
        </div>
      </Card.Content>
    </Card>
  );
};
