export class Storage {
  private static readonly values: Map<string, string> = new Map();

  public static get<T extends string>(key: string): Nullable<T> {
    try {
      return localStorage.getItem(key) as T;
    } catch {
      return this.values.get(key) as T ?? null;
    }
  }

  public static set(key: string, value: string) {
    this.values.set(key, value);
    try {
      localStorage.setItem(key, value);
    } catch {
      //
    }
  }

  public static remove(key: string) {
    this.values.delete(key);
    try {
      localStorage.removeItem(key);
    } catch {
      //
    }
  }
}
