import { Assets } from '~/client/core/assets';
import { AudioMode, AudioType } from '~/client/core/audio/types';

import audioAttack1 from './attack1.mp3';
import audioAttack2 from './attack2.mp3';
import audioDead from './dead.mp3';
import audioExtraAttack from './extra-attack.mp3';
import audioFrost1 from './frost1.mp3';
import audioFrost2 from './frost2.mp3';

import './hits';

Assets.addAudio(AudioType.MobFrost, {
  path: [audioFrost1, audioFrost2],
  mode: AudioMode.Track3D,
  volume: 0.25,
  limit: 6,
});

Assets.addAudio(AudioType.MobDead, {
  path: audioDead,
  mode: AudioMode.Track3D,
  volume: 0.2,
  limit: 4,
});

Assets.addAudio(AudioType.MobAttack, {
  path: [audioAttack1, audioAttack2],
  mode: AudioMode.Track3D,
  volume: 0.5,
  limit: 3,
});

Assets.addAudio(AudioType.MobExtraAttack, {
  path: audioExtraAttack,
  mode: AudioMode.Track3D,
  volume: 0.4,
  limit: 1,
});
