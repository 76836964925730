import { Assets } from '~/client/core/assets';
import { ModelType } from '~/client/core/assets/types';

import bin from './base.bin';
import gltf from './base.gltf';

Assets.addModel(ModelType.BuildingBase, {
  gltf,
  bin: {
    origin: 'base.bin',
    path: bin,
  },
});
