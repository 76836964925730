import { atom, selector } from 'recoil';

import type { TutorialStep } from '~/shared/battle/tutorial/types';

export const ActiveTutorialStepsState = atom<Set<TutorialStep>>({
  key: 'ActiveTutorialSteps',
  default: new Set(),
});

export const CurrentTutorialStepState = selector<Nullable<TutorialStep>>({
  key: 'CurrentTutorialStep',
  get: ({ get }) => {
    const steps = get(ActiveTutorialStepsState);
    return steps.values().next().value ?? null;
  },
});
