import React, { useMemo } from 'react';

import { CATEGORY_ICON } from './const';
import { Variant } from './variant';

import type { UpgradeCategory } from '~/shared/battle/entity/unit/player/upgrades/types';

import { CardsList } from '~/client/battle/ui/components/cards-list';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { UpgradeVariant } from '~/shared/battle/entity/unit/player/upgrades/types';

type Props = {
  category: UpgradeCategory;
};

export const Category: React.FC<Props> = ({ category }) => {
  const language = useLanguage();

  const upgrades = useMemo(() => (
    Object.values(UpgradeVariant).filter((variant) => variant.indexOf(category) === 0)
  ), [category]);

  return (
    <CardsList icon={CATEGORY_ICON[category]} label={language(category)}>
      {upgrades.map((variant) => (
        <Variant key={variant} variant={variant} />
      ))}
    </CardsList>
  );
};
