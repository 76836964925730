import { MeshBasicMaterial, Color } from 'three';

import { Assets } from '~/client/core/assets';
import { MaterialType } from '~/client/core/assets/materials/types';

Assets.addMaterial(MaterialType.Electro, new MeshBasicMaterial({
  color: new Color(0x0061ff),
  reflectivity: 0,
  transparent: true,
  opacity: 0.05,
  depthTest: false,
  depthWrite: false,
}));
