import React from 'react';

import { InventoryBuySlot } from './buy-slot';
import { InventorySlot } from './slot';
import { InventoryPanel } from '../panel';

import type { InventoryItemType } from '~/shared/core/inventory/item/types';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';

type Props = {
  type: InventoryItemType;
  maxSlots: number;
  availableSlots: number;
};

export const InventoryCategory: React.FC<Props> = ({ type, maxSlots, availableSlots }) => {
  const language = useLanguage();

  const lockedSlots = maxSlots - availableSlots;

  return (
    <InventoryPanel title={language(`Category${type}`)}>
      {Array(availableSlots).fill(null).map((_, slot) => (
        <InventorySlot key={slot} type={type} slot={slot} />
      ))}
      {Array(lockedSlots).fill(null).map((_, slot) => (
        <InventoryBuySlot key={slot} />
      ))}
    </InventoryPanel>
  );
};
