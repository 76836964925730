import cn from 'classnames';
import React from 'react';

import { Icon } from '~/client/core/ui/components/icon';
import { Interactive } from '~/client/core/ui/components/interactive';

import styles from './styles.module.scss';

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  icon?: SVGIcon;
  size?: 'small' | 'large';
  view?: 'default' | 'accent' | 'negative';
  onClick: VoidFunction;
};

export const Button: React.FC<Props> = ({
  children,
  onClick,
  disabled,
  icon,
  view = 'default',
  size = 'small',
}) => (
  <Interactive
    disabled={disabled}
    onClick={onClick}
    className={cn(styles.button, styles[size], styles[view], {
      [styles.disabled]: disabled,
    })}
  >
    <Icon type={icon} className={styles.icon} />
    {children}
  </Interactive>
);

