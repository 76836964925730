import { Assets } from '~/client/core/assets';
import { AudioType, AudioMode } from '~/client/core/audio/types';

import audioBuild from './build.mp3';

Assets.addAudio(AudioType.Build, {
  path: audioBuild,
  mode: AudioMode.Track2D,
  volume: 1.0,
});
