import { MOB_INSTANCES } from './const';

import type { Battle } from '~/client/battle';
import type { MobSchema } from '~/shared/battle/entity/unit/npc/mob/types';

export class MobFactory {
  public static create(battle: Battle, schema: MobSchema) {
    const MobInstance = MOB_INSTANCES[schema.variant];

    return new MobInstance(battle, schema);
  }
}
