import React from 'react';

import styles from './styles.module.scss';

export type GraphicProps = {
  children: React.ReactNode;
};

export const Graphic: React.FC<GraphicProps> = ({ children }) => (
  <div className={styles.graphic}>{children}</div>
);
