import { Unit } from '..';

import type { NPCConfig } from './types';
import type { Battle } from '../../..';
import type { NPCSchema } from '~/shared/battle/entity/unit/npc/types';

export abstract class NPC extends Unit {
  declare public readonly schema: NPCSchema;

  constructor(battle: Battle, config: NPCConfig, schema: NPCSchema) {
    super(battle, config, schema);

    this.enableInterpolation();
  }
}
