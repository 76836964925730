import type { Schema } from '@colyseus/schema';

export interface LiveSchema extends Schema {
  readonly health: number
  readonly maxHealth: number
  readonly armour: number
  readonly maxArmour: number
}

export enum LiveDamageType {
  Fire = 'Fire',
  Other = 'Other',
  Punch = 'Punch',
  Bite = 'Bite',
  Shoot = 'Shoot',
}
