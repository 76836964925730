import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';

import { usePlayer } from '../../hooks/use-player';

import { Indicator } from '~/client/battle/entity/ui/components/indicator';
import { AudioType } from '~/client/core/audio/types';
import { useAudio } from '~/client/core/audio/ui/hooks/use-audio';
import { EntityMessage } from '~/shared/battle/entity/types';
import { PlayerMessage } from '~/shared/battle/entity/unit/player/types';

import styles from './styles.module.scss';

import IconHeal from './icons/heal.svg';
import IconNoConnect from './icons/no-connect.svg';

export const Indicators: React.FC = () => {
  const player = usePlayer();
  const audio = useAudio();

  const connected = useSchemaValue(player.schema, 'connected');

  const onLevelUp = () => {
    if (player.selfOwn) {
      audio.play(AudioType.PlayerLevelUp);
    }
  };

  const onHeal = () => {
    if (player.selfOwn) {
      // audio.play(AudioType.Repair);
    }
  };

  return (
    <>
      {!connected && (
        <IconNoConnect className={styles.noConnect} />
      )}
      <Indicator
        entity={player}
        messageShow={PlayerMessage.LevelUp}
        onDisplay={onLevelUp}
      >
        LEVEL UP
      </Indicator>
      <Indicator
        entity={player}
        messageShow={EntityMessage.Heal}
        onDisplay={onHeal}
      >
        <IconHeal />
      </Indicator>
    </>
  );
};
