import React from 'react';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Input } from '~/client/core/ui/components/input';

import IconUsername from './icons/username.svg';

type Props = {
  onChange: (value: string) => void;
};

export const InputUsername: React.FC<Props> = ({ onChange }) => {
  const language = useLanguage();

  return (
    <Input
      label={language('Username')}
      onChange={onChange}
      icon={IconUsername}
      validate={(value) => /^[a-zA-Z0-9_-]{0,16}$/.test(value)}
      maxLength={16}
    />
  );
};
