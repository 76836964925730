import React from 'react';
import { useRecoilValue } from 'recoil';

import { PAGES } from './const';
import { PageState } from './state';

export const Page: React.FC = () => {
  const page = useRecoilValue(PageState);

  const PageComponent = PAGES[page].component;

  return <PageComponent />;
};
