import { DROID_INSTANCES } from './const';

import type { Battle } from '~/client/battle';
import type { DroidSchema } from '~/shared/battle/entity/unit/npc/droid/types';

export class DroidFactory {
  public static create(battle: Battle, schema: DroidSchema) {
    const DroidInstance = DROID_INSTANCES[schema.variant];

    return new DroidInstance(battle, schema);
  }
}
