import { PlaneGeometry, Vector3 } from 'three';

import { Shape } from '..';

import type { PlaneConfig } from './types';
import type { Scene } from '../../../scene';

const _PlaneGeometry = new PlaneGeometry();

export class Plane extends Shape {
  constructor(scene: Scene, config: PlaneConfig) {
    super(scene, {
      ...config,
      geometry: _PlaneGeometry,
      rotation: new Vector3(-0.5 * Math.PI, 0, 0),
    });
  }
}
