// TODO: Move to core
export enum SceneLayer {
  Liquid = 1,
  Ground = 2,
  Mounts = 3,
  Unit = 4,
  Building = 5,
  Marker = 6,
  Fog = 7,
  Misc = 8,
}
