import { ParameterType } from './types';

import IconAmmo from './icons/ammo.svg';
import IconHealth from './icons/health.svg';
import IconMobs from './icons/mobs.svg';
import IconResources from './icons/resources.svg';

export const PARAMETER_ICON: Record<ParameterType, React.FC> = {
  [ParameterType.Health]: IconHealth,
  [ParameterType.Resources]: IconResources,
  [ParameterType.Mobs]: IconMobs,
  [ParameterType.Ammo]: IconAmmo,
};
