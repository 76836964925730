export type LeaderboardRecord = {
  place: number;
  name: string;
  waves: number;
  score: number;
};

export type LeaderboardData = {
  records: LeaderboardRecord[];
  total: number;
  currentPlace: number;
};

export enum LeaderboardRequest {
  GetTable = 'Leaderboard:GetTable',
}
