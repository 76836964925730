import { Vector3 } from 'three';

import { BUILDER_RESTRICTION_LEVEL_GRADE, BUILDER_RADIUS } from './const';
import { BUILDINGS_SHARED_CONFIG } from '../entity/building/const';
import { BuildingVariant } from '../entity/building/types';
import { SkillVariant } from '../entity/unit/player/skill/types';
import { UpgradeVariant } from '../entity/unit/player/upgrades/types';

import type { PlayerSchema } from '../entity/unit/player/types';
import type { BattleSchema } from '../types';
import type { Vector3Like } from 'three';

import { VectorUtils } from '~/shared/core/vector-utils';

export class BuilderShared {
  public readonly battleSchema: BattleSchema;

  constructor(battle: BattleSchema) {
    this.battleSchema = battle;
  }

  public isLimitExceeded(variant: BuildingVariant, player: PlayerSchema): boolean {
    const { limit } = BUILDINGS_SHARED_CONFIG[variant];
    return limit
      ? this.getBuildingsCount(variant, player) >= limit
      : false;
  }

  public getRestrictionLevel(variant: BuildingVariant, player: PlayerSchema): number {
    const { minLevel, restricted } = BUILDINGS_SHARED_CONFIG[variant];

    if (restricted) {
      const count = this.getBuildingsCount(variant, player);
      return count * BUILDER_RESTRICTION_LEVEL_GRADE[this.battleSchema.mode];
    }

    return minLevel[this.battleSchema.mode];
  }

  public isRestricted(variant: BuildingVariant, player: PlayerSchema) {
    return player.level < this.getRestrictionLevel(variant, player);
  }

  public hasResourcesToBuild(variant: BuildingVariant, player: PlayerSchema): boolean {
    let cost = BUILDINGS_SHARED_CONFIG[variant].cost;

    if (variant === BuildingVariant.Wall) {
      const skill = player.skills.get(SkillVariant.DiscountWalls);
      if (skill) {
        cost *= skill.multiplier;
      }
    }

    return cost <= player.resources;
  }

  public isPositionInsideRadius(position: Vector3Like, player: PlayerSchema) {
    const level = player.upgrades.get(UpgradeVariant.BuildRadius) ?? 1;
    const distance = VectorUtils.reuse(position).distanceTo(player.position);
    return distance <= BUILDER_RADIUS.get(level);
  }

  public getBuildingsCount(variant: BuildingVariant, player: PlayerSchema): number {
    return Array.from(this.battleSchema.buildings.values()).filter((building) => (
      building.variant === variant &&
      building.ownerId === player.id
    )).length;
  }

  public isPositionTouchUnit(position: Vector3Like): boolean {
    const tilePosition = new Vector3().copy(position).round().setY(1);
    const units = [
      ...Array.from(this.battleSchema.mobs.values()),
      ...Array.from(this.battleSchema.players.values()),
    ];

    return units.some((unit) => (
      tilePosition.equals(
        VectorUtils.reuse(unit.position).round().setY(1),
      )
    ));
  }
}
