import { AudioLoader } from 'three';

import type { AudioConfig, AudioSourceConfig, AudioType } from '../../audio/types';
import type { LoadingManager } from 'three';

export class AssetsAudios {
  private static audios: Map<AudioType, AudioConfig> = new Map();

  private static list: Map<AudioType, AudioSourceConfig> = new Map();

  public static add(type: AudioType, config: AudioSourceConfig) {
    if (this.list.has(type)) {
      throw Error(`Audio '${type}' is already registered`);
    }

    this.list.set(type, config);
  }

  public static get() {
    return this.audios;
  }

  public static load(manager: LoadingManager) {
    const loaderAudio = new AudioLoader(manager);
    const tasks: Promise<void>[] = [];

    this.list.forEach(({ path, ...data }, type) => {
      const entries = typeof path === 'string' ? [path] : path;
      entries.forEach((entry) => {
        tasks.push(
          loaderAudio.loadAsync(entry).then((buffer: AudioBuffer) => {
            const audio = this.audios.get(type);
            if (audio) {
              audio.buffer.push(buffer);
            } else {
              this.audios.set(type, {
                buffer: [buffer],
                ...data,
              });
            }
          }),
        );
      });
    });

    return tasks;
  }
}
