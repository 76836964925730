import { BUILDING_TRAP_ATTACK_ANIMATION_DURATION } from './const';
import { Building } from '../..';

import type { Battle } from '~/client/battle';
import type { Messages } from '~/client/core/messages';
import type { BuildingSchema } from '~/shared/battle/entity/building/types';
import type { BuildingTrapMessagePayload } from '~/shared/battle/entity/building/variants/trap/types';

import { ModelType } from '~/client/core/assets/types';
import { BuildingTrapMessages } from '~/shared/battle/entity/building/variants/trap/types';
import { Delay } from '~/shared/core/time/delay';

import './resources';

export class BuildingTrap extends Building {
  declare protected readonly messages: Messages<BuildingTrapMessagePayload>;

  private readonly attackActions: string[] = [];

  private readonly attackAnimationDelay: Delay = new Delay();

  constructor(battle: Battle, schema: BuildingSchema) {
    super(battle, {
      model: ModelType.BuildingTrap,
    }, schema);

    this.attackAnimationDelay.setDuration(BUILDING_TRAP_ATTACK_ANIMATION_DURATION);

    Array.from(this.renderItem.animator.actions.keys()).forEach((action) => {
      if (action.indexOf('attack_') === 0) {
        this.attackActions.push(action);
      }
    });

    this.handleMessages();
  }

  private onAttack() {
    if (!this.attackAnimationDelay.isPast()) {
      return;
    }

    for (const action of this.attackActions) {
      this.renderItem.animator.play(action, {
        repeat: false,
      });
    }

    this.attackAnimationDelay.start();
  }

  private handleMessages(): void {
    this.messages.on(BuildingTrapMessages.Attack, () => {
      this.onAttack();
    });
  }
}
