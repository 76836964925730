import { Assets } from '~/client/core/assets';
import { ModelType } from '~/client/core/assets/types';

import binTree1 from './tree1.bin';
import gltfTree1 from './tree1.gltf';
import binTree2 from './tree2.bin';
import gltfTree2 from './tree2.gltf';

Assets.addModel(ModelType.Tree1, {
  gltf: gltfTree1,
  bin: {
    origin: 'tree1.bin',
    path: binTree1,
  },
});

Assets.addModel(ModelType.Tree2, {
  gltf: gltfTree2,
  bin: {
    origin: 'tree2.bin',
    path: binTree2,
  },
});
