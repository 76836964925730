import React from 'react';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Input } from '~/client/core/ui/components/input';

import IconPassword from './icons/password.svg';

type Props = {
  onChange: (value: string) => void;
};

export const InputPassword: React.FC<Props> = ({ onChange }) => {
  const language = useLanguage();

  return (
    <Input
      label={language('Password')}
      onChange={onChange}
      icon={IconPassword}
      type="password"
      maxLength={32}
    />
  );
};
