import cn from 'classnames';
import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { INVENTORY_ITEM_ICON } from '../../const';
import { useInventorySlot } from '../../hooks/use-inventory-slot';
import { InventorySelectionState } from '../../state';

import type { InventoryItemType } from '~/shared/core/inventory/item/types';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Icon } from '~/client/core/ui/components/icon';
import { Interactive } from '~/client/core/ui/components/interactive';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';

import styles from './styles.module.scss';

type Props = {
  type: InventoryItemType;
  slot: number;
};

export const InventorySlot: React.FC<Props> = ({ type, slot }) => {
  const language = useLanguage();
  const item = useInventorySlot(type, slot);

  const [, setInventorySelection] = useRecoilState(InventorySelectionState);

  const name = item && language(`${item}Name`);
  const description = item && language(`${item}Description`);

  const handleClickSlot = useCallback(() => {
    setInventorySelection({ type, slot });
  }, []);

  return (
    <Interactive className={styles.wrapper} onClick={handleClickSlot}>
      <div className={cn(styles.frame, {
        [styles.empty]: !item,
      })}>
        {item ? (
          <Icon type={INVENTORY_ITEM_ICON[item]} className={styles.icon} />
        ) : (
          <div className={styles.dot} />
        )}
      </div>
      <Section direction="vertical" gap={8}>
        {item ? (
          <>
            <Text view="primary" size="l" bold uppercase>
              {name}
            </Text>
            <Text view="secondary" size="s">
              {description}
            </Text>
          </>
        ) : (
          <Text view="transparent" size="l" bold uppercase>
            {language('EmptySlot')}
          </Text>
        )}
      </Section>
    </Interactive>
  );
};
