import { PlaneGeometry, Mesh } from 'three';

import { FOG_PLANE_SIZE } from './const';
import { SceneLayer } from '../../scene/types';

import type { Light } from './light';
import type { Battle } from '../..';
import type { ShaderMaterial } from 'three';

import { Assets } from '~/client/core/assets';
import { MaterialType } from '~/client/core/assets/materials/types';
import { TERRAIN_SIZE } from '~/shared/battle/terrain/const';

import './resources';

const _PlaneGeometry = new PlaneGeometry(FOG_PLANE_SIZE.x, FOG_PLANE_SIZE.y);

export class Fog {
  private readonly battle: Battle;

  private readonly lights: Set<Light> = new Set();

  private readonly fog: Mesh;

  constructor(battle: Battle) {
    this.battle = battle;

    const material = Assets.getMaterial<ShaderMaterial>(MaterialType.Fog);
    material.onBeforeRender = () => {
      this.update(material);
    };

    this.fog = new Mesh(_PlaneGeometry, material);
    this.fog.position.set(TERRAIN_SIZE.x / 2, 1.2, TERRAIN_SIZE.z / 2);
    this.fog.rotateX(-0.5 * Math.PI);
    this.fog.layers.set(SceneLayer.Fog);

    this.battle.scene.add(this.fog);
  }

  public destroy(): void {
    this.battle.scene.remove(this.fog);
  }

  private update(material: ShaderMaterial) {
    Array.from(this.lights.values()).forEach((light, i) => {
      material.uniforms.maskRadius.value[i] = light.radius;
      material.uniforms.maskPosition.value[i] = light.getPosition();
    });

    material.uniforms.lightsCount.value = this.lights.size;
  }

  public addLight(light: Light) {
    this.lights.add(light);
  }

  public removeLight(light: Light) {
    this.lights.delete(light);
  }
}
