import type { Schema } from '@colyseus/schema';

export enum SkillVariant {
  BoostSpeed = 'BoostSpeed',
  InspirationDroid = 'InspirationDroid',
  InspirationMobs = 'InspirationMobs',
  InspirationTowers = 'InspirationTowers',
  Vampire = 'Vampire',
  DamageReflection = 'DamageReflection',
  Sacrifice = 'Sacrifice',
  DiscountWalls = 'DiscountWalls',
  BoostGenerators = 'BoostGenerators',
  CallingMobs = 'CallingMobs',
  AttackRecovery = 'AttackRecovery',
  HighDamage = 'HighDamage',
  FrostWave = 'FrostWave',
}

export enum SkillType {
  Active = 'Active',
  Passive = 'Passive',
}

export interface SkillSchema extends Schema {
  readonly using: boolean;
  readonly type: SkillType;
  readonly recovery: number;
  readonly recoveryLeft: number;
  readonly duration: number;
  readonly multiplier: number;
}

export type SkillSharedConfig = {
  type: SkillType;
  recovery?: number;
  duration?: number;
  default?: boolean;
  cost: number;
};
