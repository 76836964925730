import React, { useCallback, useState } from 'react';

import { Button } from '../button';
import { ErrorPlate } from '../error-plate';
import { InputPassword } from '../inputs/password';
import { InputUsername } from '../inputs/username';

import type { Relay } from '~/client/relay';

import { Client } from '~/client/core/client';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Section } from '~/client/core/ui/components/section';
import { useError } from '~/client/core/ui/hooks/use-error';
import { UserAuthRequest } from '~/shared/core/user/auth/types';

export const AuthLogIn: React.FC = () => {
  const room = useRoom<Relay>();
  const error = useError();
  const language = useLanguage();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = useCallback(() => {
    room.sendRequest<string>(UserAuthRequest.Login, { username, password })
      .then((token) => {
        Client.setAuthToken(token);
        room.remountInterface();
      })
      .catch(({ message }) => {
        error.show(language(message));
      });
  }, [username, password]);

  return (
    <form onSubmit={handleSubmit}>
      <Section direction="vertical" gap={48}>
        <Section direction="vertical" gap={8}>
          <ErrorPlate error={error} />
          <InputUsername onChange={setUsername} />
          <InputPassword onChange={setPassword} />
        </Section>
        <Section direction="horizontal" gap={16}>
          <Button
            label={language('ToLogIn')}
            onClick={handleSubmit}
            disabled={!username || !password}
          />
        </Section>
      </Section>
    </form>
  );
};
