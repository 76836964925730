import cn from 'classnames';
import React, { useState } from 'react';

import { AuthLogIn } from './login';
import { AuthSingUp } from './signup';
import { AuthTab } from './types';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Interactive } from '~/client/core/ui/components/interactive';
import { Section } from '~/client/core/ui/components/section';

import styles from './styles.module.scss';

export const OverlayAuth: React.FC = () => {
  const language = useLanguage();

  const [tab, setTab] = useState(AuthTab.LogIn);

  const tabContent = tab === AuthTab.LogIn
    ? <AuthLogIn />
    : <AuthSingUp />;

  return (
    <Section direction='vertical' gap={48} className={styles.container}>
      <Section direction='horizontal' gap={24}>
        {Object.values(AuthTab).map((type) => (
          <Interactive
            key={type}
            onClick={() => setTab(type)}
            className={cn(styles.tab, {
              [styles.active]: tab === type,
            })}
          >
            {language(type)}
          </Interactive>
        ))}
      </Section>
      {tabContent}
    </Section>
  );
};
