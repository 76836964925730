import { Assets } from '~/client/core/assets';
import { ModelType } from '~/client/core/assets/types';

import bin from './wall.bin';
import gltf from './wall.gltf';

Assets.addModel(ModelType.BuildingWall, {
  gltf,
  bin: {
    origin: 'wall.bin',
    path: bin,
  },
});
