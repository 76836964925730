import { Mob } from '../..';

import type { Battle } from '~/client/battle';
import type { MobSchema } from '~/shared/battle/entity/unit/npc/mob/types';

import { ModelType } from '~/client/core/assets/types';

import './resources';

export class MobSpider extends Mob {
  constructor(battle: Battle, schema: MobSchema) {
    super(battle, {
      model: ModelType.MobSpider,
    }, schema);
  }
}
