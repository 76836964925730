import { v4 as uuidv4 } from 'uuid';

export class Utils {
  public static uuid(): string {
    return uuidv4();
  }

  public static matchDomain(domain: string, origin: string): boolean {
    return new RegExp(`://(\\S+\\.)?${domain.replace(/\./, '\\.')}$`).test(origin);
  }

  public static decorateNumber(value: number) {
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }
}
