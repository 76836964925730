import React from 'react';

import type { SkillSchema, SkillVariant } from '~/shared/battle/entity/unit/player/skill/types';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { SkillType } from '~/shared/battle/entity/unit/player/skill/types';

import styles from './styles.module.scss';

type Props = {
  variant: SkillVariant;
  skill: SkillSchema;
};

export const SkillInfo: React.FC<Props> = ({ variant, skill }) => {
  const language = useLanguage();

  return (
    <div className={styles.container}>
      <div className={styles.name}>
        {language(`${variant}Name`)}
      </div>
      <div className={styles.description}>
        {language(`${variant}Description`)}
      </div>
      <div className={styles.addon}>
        {skill.type === SkillType.Active
          ? language('SkillDuration', [skill.duration / 1000])
          : language('PassiveSkill')
        }
      </div>
    </div>
  );
};

