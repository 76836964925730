import { DroidUI } from './ui';
import { NPC } from '..';

import type { DroidConfig } from './types';
import type { Battle } from '~/client/battle';
import type { DroidSchema } from '~/shared/battle/entity/unit/npc/droid/types';

import { MaterialType } from '~/client/core/assets/materials/types';

export abstract class Droid extends NPC {
  declare public readonly schema: DroidSchema;

  constructor(battle: Battle, config: DroidConfig, schema: DroidSchema) {
    super(battle, config, schema);

    this.setUI(DroidUI);

    this.renderItem.animator.play('fly', {
      timeScale: 0.25,
    });

    const material = this.selfOwn ? MaterialType.Self : MaterialType.Opponent;
    this.renderItem.changeMaterial('Cube_2', material);
  }
}
