import React from 'react';

import { StateIcon } from './state-icon';
import { useMob } from '../../hooks/use-mob';

import { Boost } from '~/client/battle/entity/ui/components/boost';
import { Indicator } from '~/client/battle/entity/ui/components/indicator';
import { MobMessage } from '~/shared/battle/entity/unit/npc/mob/types';

export const Indicators: React.FC = () => {
  const mob = useMob();

  return (
    <>
      <Indicator
        entity={mob}
        messageShow={MobMessage.Boost}
        messageHide={MobMessage.Unboost}
      >
        <Boost opponent={!mob.selfOwn} />
      </Indicator>
      <StateIcon />
    </>
  );
};
