import type { EventStream } from '~/shared/core/event-stream';

export type SceneEvents = {
  onUpdate: EventStream
  onRender: EventStream
};

export type SceneConfig = {
  light: number
  backgroundColor: number
};

export enum SceneResolution {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High'
}
