import { Shot } from '../..';

import type { Battle } from '~/client/battle';
import type { ElectroSchema } from '~/shared/battle/entity/shot/electro/types';

import { MaterialType } from '~/client/core/assets/materials/types';
import { AudioType } from '~/client/core/audio/types';
import { Circle } from '~/client/core/render-item/shape/circle';

import './resources';

export class Electro extends Shot {
  declare public readonly schema: ElectroSchema;

  declare public readonly renderItem: Circle;

  constructor(battle: Battle, schema: ElectroSchema) {
    const renderItem = new Circle(battle.scene, {
      material: MaterialType.Electro,
      position: schema.position,
      radius: schema.radius,
    });

    super(battle, {
      renderItem,
      audioEffect: AudioType.ShotElectro,
    }, schema);

    this.listenSchemaRadius();
  }

  private listenSchemaRadius() {
    this.schema.listen('radius', (radius) => {
      this.renderItem.setRadius(radius);
    });
  }
}
