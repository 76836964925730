import React from 'react';

import { Icon } from '~/client/core/ui/components/icon';
import { Section } from '~/client/core/ui/components/section';

import styles from './styles.module.scss';

type Props = {
  icon: SVGIcon;
  label: string;
  children: React.ReactNode
};

export const CardsList: React.FC<Props> = ({ icon, label, children }) => (
  <Section direction="vertical" align="center" gap={32}>
    <Section direction="horizontal" align="center" gap={16}>
      <Icon type={icon} />
      <div className={styles.label}>{label}</div>
    </Section>
    <Section direction="vertical" gap={6}>
      {children}
    </Section>
  </Section>
);

