import type { InterpolationBufferRecord } from './types';
import type { Vector3Like } from 'three';

// https://www.gabrielgambetta.com/client-side-prediction-server-reconciliation.html
export class Interpolation {
  private readonly buffer: InterpolationBufferRecord[] = [];

  public addState(position: Vector3Like) {
    this.buffer.push({
      timestamp: performance.now(),
      position,
    });
  }

  private getState() {
    return {
      from: this.buffer[0],
      to: this.buffer[1],
    };
  }

  public useState(timestamp: number) {
    let state = this.getState();
    while (state.to && state.to.timestamp <= timestamp) {
      this.buffer.shift();
      state = this.getState();
    }

    if (state.from && state.to) {
      const t = (
        (timestamp - state.from.timestamp)
        / (state.to.timestamp - state.from.timestamp)
      );

      return {
        x: state.from.position.x + (state.to.position.x - state.from.position.x) * t,
        y: state.from.position.y + (state.to.position.y - state.from.position.y) * t,
        z: state.from.position.z + (state.to.position.z - state.from.position.z) * t,
      };
    }
  }
}
