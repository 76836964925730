import cn from 'classnames';
import React from 'react';

import type { SystemMessageType } from './utils/types';

import styles from './styles.module.scss';

type Props = {
  sign: string;
  type: SystemMessageType;
  title: string;
  children: React.ReactNode;
};

export const SystemMessage: React.FC<Props> = ({
  sign,
  type,
  title,
  children,
}) => (
  <div className={cn(styles.overlay, styles[type.toLowerCase()])}>
    <div className={styles.container}>
      <h1 className={styles.sign}>{sign}</h1>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.message}>{children}</div>
    </div>
  </div>
);
