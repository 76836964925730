import cn from 'classnames';
import React from 'react';

import type { LeaderboardRecord } from '~/shared/relay/leaderboard/types';

import { Score } from '~/client/core/ui/components/amount/score';
import { Waves } from '~/client/core/ui/components/amount/waves';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';

import styles from './styles.module.scss';

type Props = LeaderboardRecord;

export const Leader: React.FC<Props> = ({ place, name, score, waves }) => (
  <div className={cn(styles.wrapper, styles[`place${place}`])}>
    <div className={styles.place}>{place}</div>
    <div className={styles.container}>
      <div className={styles.gradient} />
      <div className={styles.info}>
        <Text size="l" bold>
          {name}
        </Text>
        <Section direction="vertical" gap={8} className={styles.values}>
          <Score value={score} size="m" />
          <Waves value={waves} size="m" postfix />
        </Section>
      </div>
    </div>
  </div>
);

