import React from 'react';

import { Interactive } from '~/client/core/ui/components/interactive';

import styles from './styles.module.scss';

type Props = {
  label: string;
  onClick: VoidFunction;
  disabled?: boolean;
};

export const Button: React.FC<Props> = ({ label, onClick, disabled }) => (
  <Interactive
    onClick={onClick}
    disabled={disabled}
    className={styles.button}
  >
    {label}
  </Interactive>
);
