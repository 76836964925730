import { Assets } from '~/client/core/assets';
import { ModelType } from '~/client/core/assets/types';

import bin from './ammunition.bin';
import gltf from './ammunition.gltf';

Assets.addModel(ModelType.BuildingAmmunition, {
  gltf,
  bin: {
    origin: 'ammunition.bin',
    path: bin,
  },
});
