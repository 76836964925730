import React from 'react';

import { DIFFICULT_ICON } from './const';
import { Kind } from '../kind';

import type { Relay } from '~/client/relay';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Section } from '~/client/core/ui/components/section';
import { BattleDifficult } from '~/shared/battle/types';
import { RelayRequest } from '~/shared/relay/types';

export const SelectDifficult: React.FC = () => {
  const room = useRoom<Relay>();
  const language = useLanguage();

  const modes = Object.keys(BattleDifficult) as BattleDifficult[];

  const handleClick = (difficult: BattleDifficult) => {
    room.sendRequest(RelayRequest.PlaySingle, { difficult });
  };

  return (
    <Section direction="vertical" gap={10}>
      {modes.map((difficult) => (
        <Kind
          key={difficult}
          label={language(`Difficult${difficult}`)}
          description={language(`Difficult${difficult}Description`)}
          icon={DIFFICULT_ICON[difficult]}
          onClick={() => handleClick(difficult)}
        />
      ))}
    </Section>
  );
};
