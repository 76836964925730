import { useSchemaValue } from 'colyseus-schema-hooks';

import type { Relay } from '../..';
import type { NonFunctionPropNames } from '@colyseus/schema/lib/types/HelperTypes';
import type { UserSchema } from '~/shared/core/user/types';

import { Client } from '~/client/core/client';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';

export function useUserData<K extends NonFunctionPropNames<UserSchema>>(prop: K) {
  const room = useRoom<Relay>();

  const user = room.state.users.get(Client.sessionId);
  if (!user) {
    throw Error('Undefined current user');
  }

  return useSchemaValue(user, prop);
}
