import { DroidCombat } from '../variants/combat';
import { DroidMechanic } from '../variants/mechanic';
import { DroidMedic } from '../variants/medic';

import type { DroidInstance } from './types';

import { DroidVariant } from '~/shared/battle/entity/unit/npc/droid/types';

export const DROID_INSTANCES: Record<DroidVariant, DroidInstance> = {
  // @ts-ignore
  [DroidVariant.Combat]: DroidCombat,
  // @ts-ignore
  [DroidVariant.Mechanic]: DroidMechanic,
  // @ts-ignore
  [DroidVariant.Medic]: DroidMedic,
};
