import { useSchemaValue } from 'colyseus-schema-hooks';
import React, { useEffect, useMemo, useState } from 'react';

import { Label } from './label';
import { usePlayer } from '../../hooks/use-player';

import type { SkillVariant } from '~/shared/battle/entity/unit/player/skill/types';

import { Section } from '~/client/core/ui/components/section';

export const SkillLabels: React.FC = () => {
  const player = usePlayer();

  const skills = useSchemaValue(player.schema, 'skills');
  const [usingSkills, setUsingSkills] = useState(new Map<SkillVariant, boolean>());

  const list = useMemo(() => (
    Array.from(skills.entries()).sort(([, a], [, b]) => (
      b.type.localeCompare(a.type)
    )).map(([variant]) => variant)
  ), [skills]);

  useEffect(() => {
    const listeners: VoidFunction[] = [];
    skills.forEach((skill, variant) => {
      const unlisten = skill.listen('using', (using) => {
        setUsingSkills((current) => {
          const newState = new Map(current);
          newState.set(variant, using);
          return newState;
        });
      });
      listeners.push(unlisten);
    });

    return () => {
      listeners.forEach((unlisten) => {
        unlisten();
      });
    };
  }, []);

  return (
    <Section direction="horizontal" gap={4}>
      {list.map((variant) => (
        <Label
          key={variant}
          variant={variant}
          using={usingSkills.get(variant)}
        />
      ))}
    </Section>
  );
};
