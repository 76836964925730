import React from 'react';

import { Indicators } from './components/indicators';
import { LevelLabel } from './components/level-label';
import { SkillLabels } from './components/skill-labels';
import { StaminaBar } from './components/stamina-bar';
import { PlayerContext } from './context';
import { EntityPanel } from '../../../ui/components/entity-panel';
import { HealthBar } from '../../../ui/components/health-bar';

import type { Player } from '..';

import { Client } from '~/client/core/client';
import { Section } from '~/client/core/ui/components/section';

import styles from './styles.module.scss';

type Props = {
  target: Player;
};

export const PlayerUI: React.FC<Props> = ({ target }) => (
  <PlayerContext.Provider value={target}>
    <EntityPanel
      renderItem={target.renderItem}
      indicators={<Indicators />}
      meta={
        <Section direction="vertical" align="center" gap={8}>
          {target.schema.id !== Client.sessionId && (
            <SkillLabels />
          )}
          <Section direction="horizontal" align="center" gap={4}>
            <LevelLabel />
            {target.schema.user?.name && (
              <div>{target.schema.user.name}</div>
            )}
          </Section>
        </Section>
      }
      bars={
        <div className={styles.bars}>
          <HealthBar unit={target} />
          <StaminaBar />
        </div>
      }
    >
    </EntityPanel>
  </PlayerContext.Provider>
);

