import { useEffect } from 'react';

import type { EventStream } from '~/shared/core/event-stream';

export function useEvent<T>(
  event: EventStream<T>,
  callback: (payload: T) => void,
  deps: any[],
) {
  useEffect(() => {
    const subscribe = event.on(callback);

    return () => {
      subscribe.off();
    };
  }, deps);
}
