import type { DroidVariant } from '~/shared/battle/entity/unit/npc/droid/types';
import type { SkillVariant } from '~/shared/battle/entity/unit/player/skill/types';

export enum InventoryItemType {
  Skill = 'Skill',
  Droid = 'Droid',
}

export type InventoryItemVariant = {
  [InventoryItemType.Skill]: SkillVariant;
  [InventoryItemType.Droid]: DroidVariant;
};
