import { SphereGeometry } from 'three';

import { Shape } from '..';

import type { SphereConfig } from './types';
import type { Scene } from '../../../scene';

const _SphereGeometry = new SphereGeometry(1.0, 6, 4);

export class Sphere extends Shape {
  constructor(scene: Scene, {
    radius = 1.0,
    ...config
  }: SphereConfig) {
    super(scene, {
      ...config,
      geometry: _SphereGeometry,
    });

    if (radius !== 1.0) {
      this.setRadius(radius);
    }
  }

  public setRadius(radius: number) {
    this.setScale({ x: radius, y: radius, z: radius });
  }
}
