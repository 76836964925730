import cn from 'classnames';
import { useSchemaValue } from 'colyseus-schema-hooks';
import React, { useEffect, useState } from 'react';

import { useOpponentPlayerSchema } from '../../../hooks/use-opponent-player-schema';
import { useScreen } from '../../../hooks/use-screen';
import { useSelfPlayerSchema } from '../../../hooks/use-self-player-schema';
import { Button } from '../../button';

import type { Battle } from '~/client/battle';

import { Client } from '~/client/core/client';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { SDK } from '~/client/core/sdk';
import { SDKAdType } from '~/client/core/sdk/types';
import { Crystals } from '~/client/core/ui/components/amount/crystals';
import { Score } from '~/client/core/ui/components/amount/score';
import { Section } from '~/client/core/ui/components/section';
import { BattleMode } from '~/shared/battle/types';
import { RoomType } from '~/shared/core/room/types';

import styles from './styles.module.scss';

import IconAds from './icons/ads.svg';
import IconLoad from './icons/load.svg';

export const StageFinished: React.FC = () => {
  const battle = useRoom<Battle>();
  const language = useLanguage();
  const { closeScreen } = useScreen();

  const player = useSelfPlayerSchema();
  const score = useSchemaValue(player, 'score');
  const crystals = useSchemaValue(player, 'crystals');

  const opponentPlayer = useOpponentPlayerSchema();
  const opponentConnected = useSchemaValue(opponentPlayer, 'connected');

  const [waitingOpponent, setWaitingOpponent] = useState(false);

  const winner = battle.state.winnerId === player.id;

  let title = language('YouWin');
  if (!winner) {
    if (battle.state.mode === BattleMode.Single) {
      if (player.live.health === 0) {
        title = language('YouDied');
      } else {
        title = language('BaseDestroyed');
      }
    } else {
      title = language('YouLose');
    }
  }

  const canLoadSave = (
    battle.state.mode === BattleMode.Single
  );
  const canPlayAgain = (
    battle.state.mode === BattleMode.Single ||
    (opponentConnected && !waitingOpponent)
  );

  const handleClickPlayAgain = () => {
    battle.restart(false);
    if (battle.state.mode === BattleMode.Online) {
      setWaitingOpponent(true);
    } else {
      Client.setLoading();
    }
  };

  const handleClickLoadSave = async () => {
    await SDK.showAd(SDKAdType.Rewarded);
    battle.restart(true);
    Client.setLoading();
  };

  const handleClickMenu = () => {
    Client.joinRoomByType(RoomType.Relay);
  };

  useEffect(() => {
    closeScreen();
  }, []);

  return (
    <div className={cn(styles.screen, {
      [styles.winner]: winner,
    })}>
      <Section direction="vertical" align="center" gap={10}>
        <div className={styles.title}>{title}</div>
        <Section direction="vertical" align="center" gap={48}>
          <Section direction="horizontal" gap={8}>
            <div className={styles.reward}>
              <Crystals value={crystals} size='l' animated />
            </div>
            <div className={styles.reward}>
              <Score value={score} size='l' animated />
            </div>
          </Section>
          <Section direction="vertical" align='center' gap={4}>
            {battle.state.mode === BattleMode.Online && (
              opponentConnected ? (
                waitingOpponent && (
                  <div className={styles.status}>
                    {language('WaitingOpponent')}
                  </div>
                )
              ) : (
                <div className={styles.status}>
                  {language('OpponentDisconnected')}
                </div>
              )
            )}
            <Section direction="vertical" align="center" gap={16} className={styles.buttons}>
              {canLoadSave && (
                <Button
                  onClick={handleClickLoadSave}
                  size="large"
                  view="accent"
                  icon={SDK.enabled ? IconAds : IconLoad}
                >
                  {language('LoadSave')}
                </Button>
              )}
              <Section direction="vertical" gap={4}>
                {canPlayAgain && (
                  <Button
                    onClick={handleClickPlayAgain}
                    view={canLoadSave ? 'default' : 'accent'}
                  >
                    {language('PlayAgain')}
                  </Button>
                )}
                <Button onClick={handleClickMenu}>
                  {language('MainMenu')}
                </Button>
              </Section>
            </Section>
          </Section>
        </Section>
      </Section>
    </div>
  );
};
