import cn from 'classnames';
import React, { useCallback } from 'react';

import { ScreenType } from '../../../../types';

import { useScreen } from '~/client/battle/ui/hooks/use-screen';
import { Interactive } from '~/client/core/ui/components/interactive';

import styles from './styles.module.scss';

import IconMenu from './icons/menu.svg';

export const MenuButton: React.FC = () => {
  const { screen, openScreen, closeScreen } = useScreen();

  const handleClick = useCallback(() => {
    if (screen === ScreenType.Menu) {
      closeScreen();
    } else {
      openScreen(ScreenType.Menu);
    }
  }, [screen]);

  return (
    <Interactive onClick={handleClick} className={cn(styles.button, {
      [styles.active]: screen === ScreenType.Menu,
    })}>
      <IconMenu className={styles.icon} />
    </Interactive>
  );
};
