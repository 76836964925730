import { useRecoilState } from 'recoil';

import { ScreenState } from '../state';

import type { Battle } from '../..';
import type { ScreenType } from '../types';

import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Storage } from '~/client/core/storage';

export function useScreen() {
  const battle = useRoom<Battle>();

  const [screen, setScreen] = useRecoilState(ScreenState);

  const openScreen = (type: ScreenType) => {
    Storage.set('Screen', type);
    setScreen(type);

    battle.togglePause(true);
  };

  const closeScreen = () => {
    Storage.remove('Screen');
    setScreen(null);

    battle.togglePause(false);
  };

  return {
    screen,
    closeScreen,
    openScreen,
  };
}
