import React from 'react';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Section } from '~/client/core/ui/components/section';
import { SettingsPanel } from '~/client/core/ui/components/settings-panel';

import styles from './styles.module.scss';

export const OverlaySettings: React.FC = () => {
  const language = useLanguage();

  return (
    <Section direction='vertical' align='center' gap={64}>
      <div className={styles.title}>
        {language('Settings')}
      </div>
      <SettingsPanel />
    </Section>
  );
};
