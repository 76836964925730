import { CORPSE_DESTROY_TIMEOUT } from './const';

import type { CorpseConfig } from './types';
import type { Battle } from '~/client/battle';

import { MaterialType } from '~/client/core/assets/materials/types';
import { Model } from '~/client/core/render-item/model';

export class Corpse extends Model {
  private readonly battle: Battle;

  constructor(battle: Battle, config: CorpseConfig) {
    super(battle.scene, {
      ...config,
      material: MaterialType.Unit,
    });

    this.battle = battle;

    this.animator.play('die', {
      repeat: false,
      clamp: true,
    });

    this.battle.timeouts.add(() => {
      this.destroy();
    }, CORPSE_DESTROY_TIMEOUT);
  }
}
