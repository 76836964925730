import cn from 'classnames';
import React, { useCallback } from 'react';

import { Text } from '../text';

import { Interactive } from '~/client/core/ui/components/interactive';

import styles from './styles.module.scss';

type Props = {
  checked?: boolean;
  children: React.ReactNode;
  onChange: (value: boolean) => void;
};

export const Checkbox: React.FC<Props> = ({
  children,
  onChange,
  checked = false,
}) => {
  const handleClick = useCallback(() => {
    onChange(!checked);
  }, [checked]);

  return (
    <div className={styles.checkbox}>
      <Interactive
        onClick={handleClick}
        className={cn(styles.marker, {
          [styles.checked]: checked,
        })}
      />
      <Text size='s'>{children}</Text>
    </div>
  );
};
