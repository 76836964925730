import React, { useEffect } from 'react';

import { InventoryItem } from './item';
import { INVENTORY_CATEGORY_ITEMS } from '../const';
import { useInventoryItems } from '../hooks/use-inventory-items';
import { useInventorySelection } from '../hooks/use-inventory-selection';
import { InventoryPanel } from '../panel';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';

import styles from './styles.module.scss';

export const InventorySelection: React.FC = () => {
  const language = useLanguage();
  const { type, closeSelection } = useInventorySelection();

  const currentItems = useInventoryItems();

  const items = INVENTORY_CATEGORY_ITEMS[type].map((item) => ({
    item,
    have: currentItems.has(item),
  }));

  const activeItems = items.filter(({ have }) => have);
  const otherItems = items.filter(({ have }) => !have);

  useEffect(() => {
    return () => {
      closeSelection();
    };
  }, []);

  return (
    <InventoryPanel
      title={language(`Category${type}`)}
      onClose={closeSelection}
      stretch
    >
      {activeItems.map(({ item }) => (
        <InventoryItem key={item} item={item as any} have />
      ))}
      {otherItems.length > 0 && (
        <>
          <div className={styles.line} />
          {otherItems.map(({ item }) => (
            <InventoryItem key={item} item={item as any} />
          ))}
        </>
      )}
    </InventoryPanel>
  );
};
