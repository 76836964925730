import type { EntityEvents } from '../types';
import type { ModelType } from '~/client/core/assets/types';
import type { DictionaryPhrase } from '~/client/core/language/types';
import type { EventStream } from '~/shared/core/event-stream';

export type BuildingVariantConfig = {
  model: ModelType;
};

export enum BuildingPanelVisible {
  None = 'None',
  Compact = 'Compact',
  Full = 'Full',
}

export type BuildingEvents = EntityEvents & {
  onChangeAlert: EventStream<Set<DictionaryPhrase>>;
  onChangePanelVisible: EventStream<BuildingPanelVisible>;
};
