import React, { useCallback, useState } from 'react';

import { Kind } from './kind';
import { PlayRestriction } from './restriction';
import { SearchingOpponent } from './searching-opponent';
import { SelectDifficult } from './select-difficult';
import { PlayKind } from './types';
import { WithFriend } from './with-friend';
import { useUserData } from '../../../hooks/use-user-data';

import type { Relay } from '~/client/relay';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Interactive } from '~/client/core/ui/components/interactive';
import { Section } from '~/client/core/ui/components/section';
import { USER_ONLINE_PLAY_SCORE_RESTRICTION } from '~/shared/core/user/const';
import { RelayRequest } from '~/shared/relay/types';

import styles from './styles.module.scss';

import IconOnlinePrivate from './icons/online-private.svg';
import IconOnline from './icons/online.svg';
import IconSingle from './icons/single.svg';

export const PagePlay: React.FC = () => {
  const room = useRoom<Relay>();
  const language = useLanguage();

  const authorized = useUserData('authorized');
  const score = useUserData('score');

  const [kind, setKind] = useState<Nullable<PlayKind>>(null);

  const onlinePlayLocked = (
    authorized &&
    score < USER_ONLINE_PLAY_SCORE_RESTRICTION
  );

  const handleClickBack = useCallback(() => {
    setKind(null);
  }, []);

  const handleClickPlaySingle = () => {
    setKind(PlayKind.SelectDifficult);
  };

  const handleClickPlayOnline = () => {
    room.sendRequest(RelayRequest.StartSearchingOpponent).then(() => {
      setKind(PlayKind.Online);
    });
  };

  const handleClickPlayOnlinePrivate = () => {
    setKind(PlayKind.OnlinePrivate);
  };

  return (
    <Section direction="vertical" align="center" gap={40}>
      {(() => {
        switch (kind) {
          case PlayKind.SelectDifficult: return <SelectDifficult />;
          case PlayKind.Online: return <SearchingOpponent />;
          case PlayKind.OnlinePrivate: return <WithFriend />;
          default: {
            return (
              <Section direction="vertical" gap={10}>
                <Kind
                  label={language('PlaySingle')}
                  description={language('PlaySingleDescription')}
                  icon={IconSingle}
                  onClick={handleClickPlaySingle}
                  accent
                />
                {onlinePlayLocked ? (
                  <PlayRestriction />
                ) : (
                  <>
                    <Kind
                      label={language('PlayOnline')}
                      description={language('PlayOnlineDescription')}
                      icon={IconOnline}
                      onClick={handleClickPlayOnline}
                      authorizedOnly
                    />
                    <Kind
                      label={language('PlayOnlinePrivate')}
                      description={language('PlayOnlinePrivateDescription')}
                      icon={IconOnlinePrivate}
                      onClick={handleClickPlayOnlinePrivate}
                      authorizedOnly
                    />
                  </>
                )}
              </Section>
            );
          }
        }
      })()}
      {kind && (
        <Interactive onClick={handleClickBack} className={styles.back}>
          {language('Back')}
        </Interactive>
      )}
    </Section>
  );
};
