export enum SDKAdType {
  Rewarded = 'Rewarded',
  Commercial = 'Commercial'
}

declare global {
  interface Window {
    PokiSDK?: any
  }
}
