import { Assets } from '~/client/core/assets';
import { ModelType } from '~/client/core/assets/types';

import bin from './tower-fire.bin';
import gltf from './tower-fire.gltf';

Assets.addModel(ModelType.BuildingTowerFire, {
  gltf,
  bin: {
    origin: 'tower-fire.bin',
    path: bin,
  },
});
