import { HOST } from '~/client/const';

export class Request {
  public static async get<T>(path: string) {
    return fetch(this.getHost() + path).then((res) => res.json() as T);
  }

  public static async post(path: string, data: any) {
    return fetch(this.getHost() + path, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
  }

  public static getResponseError(error?: Error) {
    const message = error?.message;
    if (!message) {
      return 'Unknown error';
    }

    if (message === 'Failed to fetch') {
      return 'Network error';
    }

    return message.replace(/^.*\d+:\s/, '');
  }

  // TODO: Move to class property and define __MODE for tests
  private static getHost() {
    return __MODE === 'production' ? `https://${HOST}` : location.origin;
  }
}
