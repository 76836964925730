import type { CrystalSchema } from './crystal/types';
import type { DecorationSchema } from './decoration/types';
import type { TileSchema } from './tile/types';
import type { MapSchema, Schema } from '@colyseus/schema';

export enum TerrainBiomeType {
  Liquid = 'Liquid',
  Coast = 'Coast',
  Fields = 'Fields',
  Mounts = 'Mounts',
}

export interface TerrainSchema extends Schema {
  readonly tiles: MapSchema<TileSchema>;
  readonly decorations: MapSchema<DecorationSchema>;
  readonly crystals: MapSchema<CrystalSchema>;
  readonly matrix: MapSchema<string>;
}
