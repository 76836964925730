import { Assets } from '~/client/core/assets';
import { ModelType } from '~/client/core/assets/types';

import bin from './siren.bin';
import gltf from './siren.gltf';

Assets.addModel(ModelType.MobSiren, {
  gltf,
  bin: {
    origin: 'siren.bin',
    path: bin,
  },
});
