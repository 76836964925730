import { Mob } from '../..';

import type { Battle } from '~/client/battle';
import type { MobStingraySchema } from '~/shared/battle/entity/unit/npc/mob/variants/stingray/type';

import { MaterialType } from '~/client/core/assets/materials/types';
import { ModelType } from '~/client/core/assets/types';

import IconUnvisible from './icons/unvisible.svg';

import './resources';

export class MobStingray extends Mob {
  declare public schema: MobStingraySchema;

  constructor(battle: Battle, schema: MobStingraySchema) {
    super(battle, {
      model: ModelType.MobStingray,
    }, schema);
  }

  override onSceneUpdate(): void {
    super.onSceneUpdate();

    if (!this.selfOwn) {
      this.updateUnvisibleState();
    }
  }

  private isUnvisible() {
    return !this.selfOwn && this.schema.unvisible;
  }

  private updateUnvisibleState() {
    if (this.isUnvisible()) {
      this.setBaseMaterial(MaterialType.UnitTransparent);
      this.setStateIcon(IconUnvisible);
    } else {
      this.setBaseMaterial(MaterialType.Unit);
      this.removeStateIcon();
    }
  }
}

