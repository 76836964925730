export const DEVICE_TARGET_SCREEN_SIZE = {
  width: 1920,
  height: 1080,
};

export const DEVICE_SCREEN_SCALE = {
  min: 0.5,
  max: 1.0,
};

