import { MeshLambertMaterial } from 'three';

import { Assets } from '~/client/core/assets';
import { MaterialType } from '~/client/core/assets/materials/types';

import textureCrystal from './crystal.png';

Assets.addMaterial(MaterialType.Crystal, new MeshLambertMaterial({
  map: Assets.createTexture(textureCrystal),
  reflectivity: 0,
}));
