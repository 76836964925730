import { Droid } from '../..';

import type { Battle } from '~/client/battle';
import type { DroidMedicSchema } from '~/shared/battle/entity/unit/npc/droid/variants/medic/types';

import { ModelType } from '~/client/core/assets/types';

import './resources';

export class DroidMedic extends Droid {
  declare public readonly schema: DroidMedicSchema;

  constructor(battle: Battle, schema: DroidMedicSchema) {
    super(battle, {
      model: ModelType.DroidMedic,
    }, schema);
  }
}

