type ProgressionConfigManual = {
  defaultValue: number;
  round?: number;
  method?: ProgressionMethod;
  growth: number;
  maxValue?: never;
  maxLevel?: number;
};

type ProgressionConfigAuto = {
  defaultValue: number;
  round?: number;
  method?: ProgressionMethod;
  growth?: never;
  maxValue: number;
  maxLevel: number;
};

export type ProgressionConfig = ProgressionConfigManual | ProgressionConfigAuto;

export enum ProgressionMethod {
  Linear = 'Linear',
  Quadratic = 'Quadratic',
}
