import { SDK_DOMAINS, SDK_SCRIPT } from './const';
import { SDKAdType } from './types';
import { Logger } from '../logger';

import { Utils } from '~/shared/core/utils';

export class SDK {
  public static activeAd: boolean = false;

  public static enabled: boolean = false;

  private static playing: boolean = false;

  public static showAd(type: SDKAdType) {
    return new Promise<boolean>((resolve) => {
      this.activeAd = true;

      const complete = (state: boolean) => {
        this.activeAd = false;
        resolve(state);
      };

      try {
        if (this.enabled) {
          if (type === SDKAdType.Rewarded) {
            window.PokiSDK.rewardedBreak().then((state: boolean) => {
              complete(state);
            });
          } else {
            window.PokiSDK.commercialBreak().then(() => {
              complete(false);
            });
          }
        } else {
          if (type === SDKAdType.Rewarded) {
            Logger.debug('SDK | Rewarded ad has been showed');
            complete(true);
          } else {
            Logger.debug('SDK | Commercial ad has been showed');
            complete(false);
          }
        }
      } catch (error) {
        // Logger.error('Failed to show ads', error as TypeError);
        complete(false);
      }
    });
  }

  public static completeLoading() {
    try {
      if (this.enabled) {
        window.PokiSDK.gameLoadingFinished();
      } else {
        Logger.debug('SDK | Game loading has been finished');
      }
    } catch (error) {
      // Logger.error('Failed to complete loading', error as TypeError);
    }
  }

  public static isPlaying() {
    return this.playing;
  }

  public static togglePlaying(state: boolean) {
    if (this.playing === state) {
      return;
    }

    this.playing = state;

    try {
      if (this.enabled) {
        if (state) {
          window.PokiSDK.gameplayStart();
        } else {
          window.PokiSDK.gameplayStop();
        }
      } else {
        if (state) {
          Logger.debug('SDK | Game has been started');
        } else {
          Logger.debug('SDK | Game has been stopped');
        }
      }
    } catch (error) {
      // Logger.error('Failed to toggle play state', error as TypeError);
    }
  }

  public static async load() {
    const useSDK = SDK_DOMAINS.some((domain) => (
      Utils.matchDomain(domain, location.origin)
    ));
    if (!useSDK) {
      return Promise.resolve();
    }

    Logger.debug('SDK | Starting to load script');

    return new Promise((resolve, reject) => {
      const script = document.createElement('script');

      script.setAttribute('src', SDK_SCRIPT);
      script.addEventListener('load', resolve);
      script.addEventListener('error', reject);

      document.body.appendChild(script);
    })
      .then(() => {
        window.PokiSDK.init();
        this.enabled = true;

        Logger.debug('SDK | Script has been loaded');
      })
      .catch((error) => {
        Logger.error('SDK | Failed to load script', error as Error);
      });
  }
}
