import cn from 'classnames';
import React, { useCallback } from 'react';

import { Interactive } from '~/client/core/ui/components/interactive';

import styles from './styles.module.scss';

type Props = {
  value: string | number;
  active?: boolean;
  onClick: VoidFunction;
};

export const Option: React.FC<Props> = ({
  value,
  active,
  onClick,
}) => {
  const handleClick = useCallback(onClick, []);

  return (
    <Interactive
      onClick={handleClick}
      className={cn(styles.value, {
        [styles.active]: active,
      })}
    >
      {value}
    </Interactive>
  );
};
