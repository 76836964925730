import EN from './dictionaries/en';
import FR from './dictionaries/fr';
import PT from './dictionaries/pt';
import RU from './dictionaries/ru';
import { LanguageType } from './types';

export const DICTIONARIES = {
  [LanguageType.EN]: EN,
  [LanguageType.FR]: FR,
  [LanguageType.PT]: PT,
  [LanguageType.RU]: RU,
};
