import { useState } from 'react';

import { useEvent } from './use-event';

import type { EventStream } from '~/shared/core/event-stream';

export function useEventValue<T>(
  event: EventStream<T>,
  defaultValue: T,
) {
  const [value, setValue] = useState(defaultValue);

  useEvent(event, setValue, []);

  return value;
}
