import { useEffect, useRef } from 'react';

export function useNextEffect(callback: VoidFunction, depends: any[]) {
  const refSkip = useRef(true);

  useEffect(() => {
    if (refSkip.current) {
      refSkip.current = false;
      return;
    }

    callback();
  }, depends);
}
