import { Mesh } from 'three';

import { RenderItem } from '..';

import type { ShapeConfig } from './types';
import type { Scene } from '~/client/core/scene';

import { SceneLayer } from '~/client/battle/scene/types';
import { Assets } from '~/client/core/assets';

export abstract class Shape extends RenderItem {
  declare public object: Mesh;

  public readonly scene: Scene;

  constructor(scene: Scene, {
    geometry,
    material,
    ...config
  }: ShapeConfig) {
    const object = new Mesh(geometry, Assets.getMaterial(material));
    super(scene, { ...config, object });

    this.object.layers.set(SceneLayer.Misc);
  }
}
