import type React from 'react';

import { UpgradeVariant } from '~/shared/battle/entity/unit/player/upgrades/types';

import IconDamage from './icons/damage.svg';
import IconDelay from './icons/delay.svg';
import IconDroid from './icons/droid.svg';
import IconHealth from './icons/health.svg';
import IconRadius from './icons/radius.svg';
import IconRecovery from './icons/recovery.svg';
import IconSpeed from './icons/speed.svg';

export const UPGRADE_ICON: Record<UpgradeVariant, React.FC> = {
  [UpgradeVariant.MainMaxHealth]: IconHealth,
  [UpgradeVariant.MainSpeed]: IconSpeed,
  [UpgradeVariant.MainDroid]: IconDroid,
  [UpgradeVariant.AttackRecovery]: IconRecovery,
  [UpgradeVariant.AttackRadius]: IconRadius,
  [UpgradeVariant.AttackDamage]: IconDamage,
  [UpgradeVariant.BuildSpeed]: IconDelay,
  [UpgradeVariant.BuildRadius]: IconRadius,
};
