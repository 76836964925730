import { ProgressionMethod, type ProgressionConfig } from './type';

export class Progression {
  public readonly defaultValue: number;

  private readonly method: ProgressionMethod;

  private readonly growth: number;

  private readonly growthValue: number;

  private readonly maxLevel: Nullable<number> = null;

  private readonly round: Nullable<number> = null;

  constructor({
    defaultValue,
    growth,
    maxLevel,
    maxValue,
    method = ProgressionMethod.Linear,
    round,
  }: ProgressionConfig) {
    this.defaultValue = defaultValue;
    this.method = method;

    if (maxLevel) {
      this.maxLevel = maxLevel;
    }

    if (round) {
      this.round = round;
    }

    if (growth) {
      this.growth = growth;
      this.growthValue = defaultValue * growth;
    } else if (maxLevel && maxValue) {
      this.growthValue = (maxValue - defaultValue) / (maxLevel - 1);
    } else {
      throw Error('Invalid progression config');
    }
  }

  public get(level: number): number {
    if (
      Number.isNaN(level) ||
      level === Infinity
    ) {
      throw Error('Invalid progression level value');
    }

    if (this.maxLevel && level > this.maxLevel) {
      level = this.maxLevel;
    }

    let value = 0;
    switch (this.method) {
      case ProgressionMethod.Linear: {
        value = this.defaultValue + (this.growthValue * (level - 1));
        break;
      }
      case ProgressionMethod.Quadratic: {
        value = this.defaultValue * (this.growth + 1) ** ((level - 1) ** 0.75);
        break;
      }
    }

    return this.round
      ? Math.round(value / this.round) * this.round
      : value;
  }
}
