import cn from 'classnames';
import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { useUserData } from '../../../hooks/use-user-data';
import { OverlayState } from '../../overlays/state';
import { OverlayType } from '../../overlays/types';
import { PAGES } from '../../pages/const';
import { PageState } from '../../pages/state';

import type { PageType } from '../../pages/types';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Icon } from '~/client/core/ui/components/icon';
import { Interactive } from '~/client/core/ui/components/interactive';

import styles from './styles.module.scss';

type Props = {
  type: PageType;
};

export const MenuItem: React.FC<Props> = ({ type }) => {
  const authorized = useUserData('authorized');
  const language = useLanguage();

  const [page, setPage] = useRecoilState(PageState);
  const [, setOverlay] = useRecoilState(OverlayState);

  const { authorizedOnly, icon } = PAGES[type];

  const handleClick = useCallback(() => {
    if (!authorizedOnly || authorized) {
      setPage(type);
    } else {
      setOverlay(OverlayType.Auth);
    }
  }, [authorized]);

  return (
    <Interactive
      onClick={handleClick}
      className={cn(styles.container, {
        [styles.active]: page === type,
      })}
    >
      <Icon type={icon} className={styles.icon} />
      <div className={styles.label}>{language(type)}</div>
    </Interactive>
  );
};
