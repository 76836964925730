import React from 'react';

import { useUserData } from '../../hooks/use-user-data';

import type { Relay } from '~/client/relay';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { SDK } from '~/client/core/sdk';
import { SDKAdType } from '~/client/core/sdk/types';
import { Crystals } from '~/client/core/ui/components/amount/crystals';
import { Icon } from '~/client/core/ui/components/icon';
import { Interactive } from '~/client/core/ui/components/interactive';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';
import { RELAY_ADS_REWARD } from '~/shared/relay/const';
import { RelayRequest } from '~/shared/relay/types';

import styles from './styles.module.scss';

import IconAds from './icons/ads.svg';

export const ButtonAds: React.FC = () => {
  if (!SDK.enabled) {
    return null;
  }

  const relay = useRoom<Relay>();
  const adsAvailable = useUserData('adsAvailable');
  const language = useLanguage();

  if (!adsAvailable) {
    return null;
  }

  const handleClick = () => {
    SDK.showAd(SDKAdType.Rewarded).then(() => {
      relay.sendRequest(RelayRequest.ApplyAdsReward);
    });
  };

  return (
    <Interactive
      onClick={handleClick}
      className={styles.container}
    >
      <div className={styles.frame}>
        <Icon type={IconAds} className={styles.icon} />
      </div>
      <Section direction='vertical' gap={4}>
        <Text size='xs' uppercase>
          {language('GetCrystals')}
        </Text>
        <Crystals value={RELAY_ADS_REWARD} prefix='+' />
      </Section>
    </Interactive>
  );
};
