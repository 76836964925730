export enum ModelType {
  DroidCombat = 'DroidCombat',
  DroidMedic = 'DroidMedic',
  DroidMechanic = 'DroidMechanic',

  Player = 'Player',

  BuildingWall = 'BuildingWall',
  BuildingGenerator = 'BuildingGenerator',
  BuildingTowerFrost = 'BuildingTowerFrost',
  BuildingTowerFire = 'BuildingTowerFire',
  BuildingTowerElectro = 'BuildingTowerElectro',
  BuildingTowerLaser = 'BuildingTowerLaser',
  BuildingRadar = 'BuildingRadar',
  BuildingAmmunition = 'BuildingAmmunition',
  BuildingSpawnerSmall = 'BuildingSpawnerSmall',
  BuildingSpawnerMedium = 'BuildingSpawnerMedium',
  BuildingSpawnerLarge = 'BuildingSpawnerLarge',
  BuildingTrap = 'BuildingTrap',
  BuildingBase = 'BuildingBase',

  MobTurtle = 'MobTurtle',
  MobSpider = 'MobSpider',
  MobCrab = 'MobCrab',
  MobDemon = 'MobDemon',
  MobOrc = 'MobOrc',
  MobGolem = 'MobGolem',
  MobEye = 'MobEye',
  MobEyeChild = 'MobEyeChild',
  MobStingray = 'MobStingray',
  MobBoss = 'MobBoss',
  MobSiren = 'MobSiren',

  Tree1 = 'Tree1',
  Tree2 = 'Tree2',
  Mushroom1 = 'Mushroom1',
  Mushroom2 = 'Mushroom2',
  Rock1 = 'Rock1',
  Rock2 = 'Rock2',
  Rock3 = 'Rock3',
  Rock4 = 'Rock4',

  Crystal = 'Crystal',

  _Dummy = '_Dummy',
}

export type ModelBinaryData = {
  gltf: string;
  bin: {
    origin: string;
    path: string;
  };
};
