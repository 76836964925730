import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';

import type { Battle } from '~/client/battle';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Text } from '~/client/core/ui/components/text';

import styles from './styles.module.scss';

export const PauseLabel: React.FC = () => {
  const battle = useRoom<Battle>();
  const language = useLanguage();

  const paused = useSchemaValue(battle.state, 'paused');

  if (!paused) {
    return null;
  }

  return (
    <Text size='2xl' bold uppercase className={styles.label}>
      {language('Paused')}
    </Text>
  );
};
