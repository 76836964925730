export enum UserAuthError {
  InvalidCredentials = 'InvalidCredentials',
  InvalidToken = 'InvalidToken',
  InvalidUsername = 'InvalidUsername',
  InvalidPassword = 'InvalidPassword',
  ExistUsername = 'ExistUsername',
  AlreadyAuthorized = 'AlreadyAuthorized',
}

export enum UserAuthRequest {
  Register = 'UserAuth:Register',
  Login = 'UserAuth:Login',
  Logout = 'UserAuth:Logout'
}
