import { BulletFire } from '../variants/bullet/fire';
import { BulletFrost } from '../variants/bullet/frost';
import { BulletSimple } from '../variants/bullet/simple';
import { Electro } from '../variants/electro';
import { Laser } from '../variants/laser';

import type { ShotInstance } from './types';

import { ShotVariant } from '~/shared/battle/entity/shot/types';

export const SHOT_INSTANCES: Record<ShotVariant, ShotInstance> = {
  // @ts-ignore
  [ShotVariant.BulletSimple]: BulletSimple,
  // @ts-ignore
  [ShotVariant.BulletFire]: BulletFire,
  // @ts-ignore
  [ShotVariant.BulletFrost]: BulletFrost,
  // @ts-ignore
  [ShotVariant.Laser]: Laser,
  // @ts-ignore
  [ShotVariant.Electro]: Electro,
};
