import { Object3D } from 'three';

import { MESH_SET_CHILD_HIDDEN_SCALE } from './const';

import type { MeshSetChildConfig } from './types';
import type { MeshSet } from '..';

export abstract class MeshSetChild {
  protected object: Object3D = new Object3D();

  private mesh: Nullable<MeshSet> = null;

  private index: number = -1;

  constructor({ position, scale, rotation }: MeshSetChildConfig) {
    this.object.position.copy(position);
    if (scale) {
      this.object.scale.set(scale, scale, scale);
    }
    if (rotation) {
      this.object.rotateY(rotation);
    }
  }

  public put(mesh: MeshSet, index: number) {
    this.mesh = mesh;
    this.index = index;

    this.updateMatrix();
  }

  public hide() {
    if (!this.mesh) {
      throw Error('Unable to hide invalid mesh child');
    }

    const scale = MESH_SET_CHILD_HIDDEN_SCALE;
    this.object.scale.set(scale, scale, scale);

    this.updateMatrix();
    this.mesh.instanceMatrix.needsUpdate = true;
  }

  private updateMatrix() {
    if (!this.mesh) {
      throw Error('Unable to update matrix for invalid mesh child');
    }

    this.object.updateMatrix();
    this.mesh.setMatrixAt(this.index, this.object.matrix);
  }
}

