import cn from 'classnames';
import React, { useCallback } from 'react';

import { INVENTORY_ITEM_ICON } from '../../const';
import { useInventorySelection } from '../../hooks/use-inventory-selection';

import type { Relay } from '~/client/relay';
import type { DroidVariant } from '~/shared/battle/entity/unit/npc/droid/types';
import type { SkillVariant } from '~/shared/battle/entity/unit/player/skill/types';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Crystals } from '~/client/core/ui/components/amount/crystals';
import { Icon } from '~/client/core/ui/components/icon';
import { Interactive } from '~/client/core/ui/components/interactive';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';
import { useUserData } from '~/client/relay/ui/hooks/use-user-data';
import { InventoryRequest } from '~/shared/core/inventory/types';
import { InventoryUtils } from '~/shared/core/inventory/utils';

import styles from './styles.module.scss';

import IconLock from './icons/lock.svg';

type Props = {
  item: DroidVariant | SkillVariant;
  have?: boolean
};

export const InventoryItem: React.FC<Props> = ({ item, have }) => {
  const room = useRoom<Relay>();
  const language = useLanguage();

  const { slot, closeSelection } = useInventorySelection();

  const inventory = useUserData('inventory');
  const crystals = useUserData('crystals');

  const itemCurrentSlot = InventoryUtils.getItemSlot(inventory, item);
  const active = itemCurrentSlot === slot;
  const cost = InventoryUtils.getItemCost(item);
  const allow = crystals >= cost;

  const handleClick = useCallback(() => {
    if (have) {
      if (active) {
        closeSelection();
        return;
      }

      room.sendRequest(InventoryRequest.UpdateSlot, { item, slot })
        .then(closeSelection);
    } else {
      if (!allow){
        return false;
      }

      room.sendRequest(InventoryRequest.BuyItem, { item });
    }
  }, [have, active, allow]);

  return (
    <Interactive className={cn(styles.wrapper, {
      [styles.have]: have,
      [styles.active]: active,
      [styles.allow]: allow,
    })} onClick={handleClick}>
      <div className={styles.meta}>
        <div className={styles.frame}>
          <Icon type={INVENTORY_ITEM_ICON[item]} className={styles.icon} />
        </div>
        {!have && (
          <div className={styles.cost}>
            <Crystals value={cost} />
          </div>
        )}
      </div>
      <Section direction='vertical' gap={8}>
        <Section direction='horizontal' align='center' gap={8}>
          {!have && (
            <IconLock className={styles.lock} />
          )}
          <Text view='primary' size='l' bold uppercase>
            {language(`${item}Name`)}
          </Text>
          {have && itemCurrentSlot !== null && (
            <div className={styles.selected}>
              <Text size='xs' uppercase>
                {language('Selected')}
              </Text>
            </div>
          )}
        </Section>
        <Text view='secondary' size='s'>
          {language(`${item}Description`)}
        </Text>
      </Section>
    </Interactive>
  );
};
