import cn from 'classnames';
import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';

import { useSelfPlayerSchema } from '../../hooks/use-self-player-schema';

import { Icon } from '~/client/core/ui/components/icon';

import styles from './styles.module.scss';

import IconResources from './icons/resources.svg';

type Props = {
  amount: number;
  check?: boolean
};

export const Cost: React.FC<Props> = ({ amount, check = true }) => {
  const player = useSelfPlayerSchema();
  const resources = useSchemaValue(player, 'resources');
  const enough = !check || resources >= amount;

  return (
    <div className={cn(styles.wrapper, {
      [styles.less]: !enough,
    })}>
      <Icon type={IconResources} className={styles.icon} />
      <div className={styles.amount}>{amount}</div>
    </div>
  );
};
