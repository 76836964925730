import React, { useEffect, useState } from 'react';

import { Leader } from './leader';
import { Record } from './record';
import { useUserData } from '../../../hooks/use-user-data';

import type { Relay } from '../../../..';
import type { LeaderboardData, LeaderboardRecord } from '~/shared/relay/leaderboard/types';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Scrollbar } from '~/client/core/ui/components/scrollbar';
import { LeaderboardRequest } from '~/shared/relay/leaderboard/types';

import styles from './styles.module.scss';

export const PageLeaderboard: React.FC = () => {
  const room = useRoom<Relay>();
  const authorized = useUserData('authorized');
  const language = useLanguage();

  const [currentPlace, setCurrentPlace] = useState(0);
  const [total, setTotal] = useState(0);
  const [leaders, setLeaders] = useState<LeaderboardRecord[]>([]);
  const [others, setOthers] = useState<LeaderboardRecord[]>([]);

  useEffect(() => {
    room
      .sendRequest<LeaderboardData>(LeaderboardRequest.GetTable)
      .then((result) => {
        setCurrentPlace(result.currentPlace);
        setTotal(result.total);
        setLeaders(result.records.splice(0, 3));
        setOthers(result.records);
      });
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.leaders}>
        {leaders.map((leader) => (
          <Leader key={leader.place} {...leader} />
        ))}
      </div>
      {authorized && (
        <div className={styles.current}>
          {language('YouRanked')}
          <div className={styles.value}>{currentPlace}</div>
          {language('PlaceOf')}
          <div className={styles.value}>{total}</div>
        </div>
      )}
      <Scrollbar>
        <div className={styles.table}>
          <table>
            {others.map((other) => (
              <Record key={other.place} {...other} />
            ))}
          </table>
        </div>
      </Scrollbar>
    </div>
  );
};
