import { DICTIONARIES } from './const';
import { LanguageType } from './types';
import { Settings } from '../settings';
import { SettingsType } from '../settings/types';

export class Language {
  public static getType() {
    const systemType = this.getSystemType();
    return Settings.getEnum<LanguageType>(SettingsType.Language, LanguageType, systemType);
  }

  public static getSystemType() {
    try {
      const type = (
        Intl.DateTimeFormat().resolvedOptions().locale ||
        navigator.language.split('-')[0]
      )?.toUpperCase();

      if (!type) {
        throw Error('Unable to get system language');
      }

      if (!(type in DICTIONARIES)) {
        throw Error('System language isn`t supported');
      }

      return type as LanguageType;
    } catch {
      return LanguageType.EN;
    }
  }

  public static setDocumentTag(type: LanguageType) {
    document.documentElement.setAttribute('lang', type.toLowerCase());
  }
}

