import cn from 'classnames';
import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';

import { Controls } from './controls';
import { HealthAmount } from './health-amount';
import { HealthBar } from './health-bar';
import { LevelBar } from './level-bar';
import { BuildingPanelVisible } from '../../../types';
import { useBuilding } from '../../hooks/use-building';

import type { Battle } from '~/client/battle';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';
import { BuildingVariant } from '~/shared/battle/entity/building/types';

import styles from './styles.module.scss';

type Props = {
  visible: BuildingPanelVisible;
};

export const BuildingPanel: React.FC<Props> = ({ visible }) => {
  const battle = useRoom<Battle>();
  const building = useBuilding();
  const language = useLanguage();

  const waveNumber = useSchemaValue(battle.state.wave, 'number');
  const hintVisible = battle.state.tutorial?.steps && waveNumber < 2;

  return (
    <div className={styles.translator}>
      {visible === BuildingPanelVisible.None ? (
        building.schema.variant === BuildingVariant.Base && (
          <>
            {hintVisible && (
              <div className={styles.hint}>
                <Text size='xs' bold>{language('DefendBase')}</Text>
              </div>
            )}
            <HealthAmount />
          </>
        )
      ) : (
        <div className={cn(styles.wrapper, {
          [styles.full]: visible === BuildingPanelVisible.Full,
        })}>
          <div className={cn(styles.container, {
            [styles.selfOwn]: building.selfOwn,
            [styles.arrow]: visible === BuildingPanelVisible.Compact,
          })} >
            <div className={styles.name}>
              {language(`${building.schema.variant}Name`)}
            </div>
            <Section direction="vertical" gap={6}>
              <HealthBar />
              <LevelBar />
            </Section>
          </div>
          {visible === BuildingPanelVisible.Full && (
            <Controls />
          )}
        </div>
      )}
    </div>
  );
};
