import { Assets } from '~/client/core/assets';
import { AudioMode, AudioType } from '~/client/core/audio/types';

import audioHitFire1 from './fire1.mp3';
import audioHitFire2 from './fire2.mp3';
import audioHitPunch1 from './punch1.mp3';
import audioHitPunch2 from './punch2.mp3';

Assets.addAudio(AudioType.MobHit, {
  path: [audioHitPunch1, audioHitPunch2],
  mode: AudioMode.Track3D,
  volume: 0.1,
  limit: 4,
});

Assets.addAudio(AudioType.MobHitPunch, {
  path: [audioHitPunch1, audioHitPunch2],
  mode: AudioMode.Track3D,
  volume: 0.2,
  limit: 6,
});

Assets.addAudio(AudioType.MobHitFire, {
  path: [audioHitFire1, audioHitFire2],
  mode: AudioMode.Track3D,
  volume: 0.25,
  limit: 4,
});

