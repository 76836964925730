import React, { useMemo } from 'react';

type Props = {
  type?: Nullable<SVGIcon>;
  className?: string;
  color?: string;
};

export const Icon: React.FC<Props> = ({ type, className, color }) => {
  const IconComponent = type;

  const style = useMemo(() => (
    color ? { fill: color } : undefined
  ), [color]);

  return IconComponent && (
    <IconComponent className={className} style={style} />
  );
};
