import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { ControlItem } from './item';
import { OverlayState } from '../overlays/state';
import { OverlayType } from '../overlays/types';

import { Section } from '~/client/core/ui/components/section';

import styles from './styles.module.scss';

import IconDiscord from './icons/discord.svg';
import IconSettings from './icons/settings.svg';

export const Controls: React.FC = () => {
  const [overlay, setOverlay] = useRecoilState(OverlayState);

  const handleClickSettings = useCallback(() => {
    const active = overlay === OverlayType.Settings;
    setOverlay(active ? null : OverlayType.Settings);
  }, [overlay]);

  return (
    <Section direction='vertical' gap={16} className={styles.wrapper}>
      <ControlItem icon={IconDiscord} link="https://discord.gg/cnFAdMsRxn" />
      <ControlItem icon={IconSettings} onClick={handleClickSettings} />
    </Section>
  );
};
