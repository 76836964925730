import { BuildingTower } from '..';

import type { Battle } from '~/client/battle';
import type { BuildingTowerLaserSchema } from '~/shared/battle/entity/building/variants/tower/laser/types';

import { ModelType } from '~/client/core/assets/types';

import './resources';

export class BuildingTowerLaser extends BuildingTower {
  constructor(battle: Battle, schema: BuildingTowerLaserSchema) {
    super(battle, {
      model: ModelType.BuildingTowerLaser,
    }, schema);
  }
}
