import type { EntitySchema } from '../types';

export interface ShotSchema extends EntitySchema {
  readonly variant: ShotVariant
}

export enum ShotVariant {
  BulletSimple = 'BulletSimple',
  BulletFire = 'BulletFire',
  BulletFrost = 'BulletFrost',
  Laser = 'Laser',
  Electro = 'Electro',
}
