import { BuildingAmmunition } from '../variants/ammunition';
import { BuildingBase } from '../variants/base';
import { BuildingGenerator } from '../variants/generator';
import { BuildingRadar } from '../variants/radar';
import { BuildingSpawnerLarge } from '../variants/spawner/large';
import { BuildingSpawnerMedium } from '../variants/spawner/medium';
import { BuildingSpawnerSmall } from '../variants/spawner/small';
import { BuildingTowerElectro } from '../variants/tower/electro';
import { BuildingTowerFire } from '../variants/tower/fire';
import { BuildingTowerFrost } from '../variants/tower/frost';
import { BuildingTowerLaser } from '../variants/tower/laser';
import { BuildingTrap } from '../variants/trap';
import { BuildingWall } from '../variants/wall';

import type { BuildingInstance } from './types';

import { BuildingVariant } from '~/shared/battle/entity/building/types';

export const BUILDING_INSTANCES: Record<BuildingVariant, BuildingInstance> = {
  [BuildingVariant.Base]: BuildingBase,
  [BuildingVariant.Wall]: BuildingWall,
  [BuildingVariant.TowerFrost]: BuildingTowerFrost,
  [BuildingVariant.TowerFire]: BuildingTowerFire,
  [BuildingVariant.TowerElectro]: BuildingTowerElectro,
  [BuildingVariant.TowerLaser]: BuildingTowerLaser,
  [BuildingVariant.Generator]: BuildingGenerator,
  [BuildingVariant.Ammunition]: BuildingAmmunition,
  [BuildingVariant.Radar]: BuildingRadar,
  [BuildingVariant.SpawnerSmall]: BuildingSpawnerSmall,
  [BuildingVariant.SpawnerMedium]: BuildingSpawnerMedium,
  [BuildingVariant.SpawnerLarge]: BuildingSpawnerLarge,
  [BuildingVariant.Trap]: BuildingTrap,
};
