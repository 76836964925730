import React from 'react';

import { MenuItem } from './item';
import { PAGES } from '../pages/const';

import type { PageType } from '../pages/types';

import { Section } from '~/client/core/ui/components/section';

export const Menu: React.FC = () => (
  <Section direction="vertical" gap={8}>
    {Object.keys(PAGES).map((page) => (
      <MenuItem key={page} type={page as PageType} />
    ))}
  </Section>
);
