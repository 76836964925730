import { Logger } from '../logger';

import type { MessagesBuffer } from './buffer';
import type { Room } from 'colyseus.js';

export class Messages<P> {
  private readonly origin: Room;

  private readonly buffer: MessagesBuffer;

  private channel: Nullable<string> = null;

  constructor(origin: Room, buffer: MessagesBuffer) {
    this.origin = origin;
    this.buffer = buffer;
  }

  public setChannel(channel: string) {
    this.channel = channel;
  }

  public on<T extends keyof P>(message: T, callback: (payload: P[T]) => void) {
    if (this.channel) {
      this.buffer.onMessage(this.origin, this.channel, message.toString(), callback);
    } else {
      this.origin.onMessage(message.toString(), callback);
    }
  }

  public send<T extends keyof P>(message: T, payload: P[T]) {
    if (!this.origin.connection.isOpen) {
      // if (__MODE === 'development') {
      //   Logger.warn(`Unable to send message '${message.toString()}' from disconnected room`);
      // }
      return;
    }

    this.origin.send(message.toString(), {
      ...payload,
      _channel: this.channel,
    });
  }

  public clear() {
    if (!this.channel) {
      Logger.warn('Unable to clear global messages');
      return;
    }

    this.buffer.clear(this.channel);
  }
}
