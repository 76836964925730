import type { Schema, MapSchema } from '@colyseus/schema';

export interface TutorialSchema extends Schema {
  readonly steps: MapSchema<TutorialStepState, TutorialStep>
}

export enum TutorialStep {
  Attack = 'Attack',
  UpgradePlayer = 'UpgradePlayer',
  UpgradeGenerator = 'UpgradeGenerator',
  UpgradeTowerFire = 'UpgradeTowerFire',
  BuildGenerator = 'BuildGenerator',
  BuildSecondGenerator = 'BuildSecondGenerator',
  BuildTowerFire = 'BuildTowerFire',
  BuildTowerFrost = 'BuildTowerFrost',
  BuildAmmunition = 'BuildAmmunition',
  BuildRadar = 'BuildRadar',
}

export enum TutorialStepState {
  Active = 'Active',
  Completed = 'Completed',
  Idle = 'Idle',
}
