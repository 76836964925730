import { Vector2 } from 'three';

import { FOG_PLANE_SIZE } from '../const';

import type { LightConfig } from './types';
import type { Battle } from '../../..';
import type { RenderItem } from '~/client/core/render-item';

import { TERRAIN_SIZE } from '~/shared/battle/terrain/const';

export class Light {
  private readonly battle: Battle;

  public radius: number;

  private target: RenderItem;

  constructor(battle: Battle, { target, radius }: LightConfig) {
    this.battle = battle;
    this.target = target;

    this.setRadius(radius);

    this.battle.terrain.fog.addLight(this);
  }

  public destroy(): void {
    this.battle.terrain.fog.removeLight(this);
  }

  public setRadius(radius: number) {
    const size = (FOG_PLANE_SIZE.x + FOG_PLANE_SIZE.y) / 2;
    this.radius = radius / size;
  }

  public getPosition() {
    return new Vector2(
      (this.target.position.x / FOG_PLANE_SIZE.x) + ((FOG_PLANE_SIZE.x - TERRAIN_SIZE.x) / FOG_PLANE_SIZE.x) / 2,
      1.0 - ((this.target.position.z / FOG_PLANE_SIZE.y) + ((FOG_PLANE_SIZE.y - TERRAIN_SIZE.z) / FOG_PLANE_SIZE.y) / 2),
    );
  }
}
