export enum RelayRequest {
  ApplyAdsReward = 'ApplyAdsReward',
  StartSearchingOpponent = 'StartSearchingOpponent',
  StopSearchingOpponent = 'StopSearchingOpponent',
  PlaySingle = 'PlaySingle',
  GenerateFriendId = 'GenerateFriendId',
  ClearFriendId = 'ClearFriendId',
  JoinFriendId = 'JoinFriendId',
}

export enum RelayMessage {
  JoinBattle = 'Relay:JoinBattle',
}
