import React from 'react';

import { CATEGORY_ICON } from './const';
import { Variant } from './variant';

import type { Battle } from '~/client/battle';
import type { BuildingCategory } from '~/shared/battle/entity/building/types';

import { CardsList } from '~/client/battle/ui/components/cards-list';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { BUILDINGS_SHARED_CONFIG } from '~/shared/battle/entity/building/const';
import { BuildingVariant } from '~/shared/battle/entity/building/types';

type Props = {
  category: BuildingCategory;
  onSelect: VoidFunction;
};

export const Category: React.FC<Props> = ({ category, onSelect }) => {
  const battle = useRoom<Battle>();
  const language = useLanguage();

  const variants = Object.values(BuildingVariant).filter((variant) => (
    BUILDINGS_SHARED_CONFIG[variant].category === category &&
    battle.builder.isBuildingEnabled(variant)
  ));

  if (variants.length === 0) {
    return null;
  }

  return (
    <CardsList icon={CATEGORY_ICON[category]} label={language(category)}>
      {variants.map((variant) => (
        <Variant key={variant} variant={variant} onSelect={onSelect} />
      ))}
    </CardsList>
  );
};
