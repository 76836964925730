import { UPGRADE_COST_GROWTH, UPGRADE_MAX_LEVEL } from '../const';

import { Progression } from '~/shared/core/progression';
import { ProgressionMethod } from '~/shared/core/progression/type';

export class UpgradeUtils {
  public static costProgression(cost: number) {
    return new Progression({
      defaultValue: cost,
      growth: UPGRADE_COST_GROWTH,
      method: ProgressionMethod.Quadratic,
      maxLevel: UPGRADE_MAX_LEVEL,
      round: 10,
    });
  }
}
